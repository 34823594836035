import { MarketplaceApplication } from 'src/constants/dataTypes/appsTypes';
import { ApiTags, appAPI, ApiMethods } from '.';
import { RootState } from 'src/store/reduxTypes';
import {
  updateExtensionSettingsAction,
  updateModuleSettings,
} from 'src/store/settings/actions';
import { IconType } from 'src/legacy/components/Icons';
import { omit } from 'lodash';
import { v4 } from 'uuid';
import {
  ExtensionConfig,
  ExtensionsSettings,
  ModuleSettingsItem,
  ModuleType,
  VisibilityRule,
} from 'src/constants';
import { API } from 'src/utils/AmplifyApiUtils';
import { DEFAULT_APPS } from 'src/hooks/useApps';

type AppInstallType =
  | 'core'
  | 'manual'
  | 'link'
  | 'embed'
  | 'custom'
  | 'marketplace';
export interface AppInstall {
  id: string;
  displayName: string;
  type: AppInstallType;
  appId: string;
  content: string;
  icon: string;
  sort: number;
  disabled: boolean;
  embedCount: number;
  isAuthenticated: boolean;
  autoSizeEmbed: boolean;
  embedlyData: string;
  embedCode: string;
  embedLink: string;
  visibilityConfig?: VisibilityRule[];
  queryParams?: Record<string, string>[];
}

const appTypeMap: Record<AppInstallType, ModuleType> = {
  core: 'module',
  manual: 'local',
  link: 'global',
  embed: 'global',
  custom: 'global',
  marketplace: 'global',
};
const getInstalls = async (): Promise<AppInstall[]> => {
  const installs = await API.get('AppAPI', '/v1/installs', {});
  return installs;
};

const mapInstallsToModuleSettings = (
  installs: AppInstall[],
): ModuleSettingsItem[] => {
  const apps = installs.map((install) => {
    return {
      id: install.id,
      label: install.displayName,
      icon: install.icon,
      type: appTypeMap[install.type],
      disabled: install.disabled,
      visibilityConfig: install.visibilityConfig,
    };
  });
  // installs api doesn't return disabled modules so we should add the
  // default core modules as disabled here
  DEFAULT_APPS.forEach((module) => {
    if (!apps.find((i) => i.id === module.id)) {
      apps.push({
        id: module.id,
        label: module.title,
        icon: module.iconName,
        type: 'module',
        disabled: true,
        visibilityConfig: undefined,
      });
    }
  });
  return apps;
};

const mapInstallsToExtensionsSettings = (
  installs: AppInstall[],
): ExtensionsSettings => {
  const settings: Record<string, ExtensionConfig> = {};
  installs.forEach((install) => {
    const appType = appTypeMap[install.type];
    if (appType == 'global' || appType == 'local') {
      settings[install.id] = {
        type: appType,
        sort: install.sort ?? 0,
        icon: install.icon as IconType,
        name: install.displayName,
        embedCount: install.embedCount,
        isAuthenticated: install.isAuthenticated,
        appId: install.appId,
        embedCode: install.embedCode || install.content,
        embedLink: install.embedLink,
        autoSizeEmbed: install.autoSizeEmbed,
        queryParams: install.queryParams,
      };
    }
  });
  return settings;
};

export const applicationSettingsApi = appAPI.injectEndpoints({
  endpoints: (build) => ({
    createApp: build.mutation<
      MarketplaceApplication,
      Omit<MarketplaceApplication, 'id'> & {
        icon: IconType;
      }
    >({
      query: (body) => ({
        method: ApiMethods.post,
        options: { body: { ...body } },
        path: '/apps',
        body,
      }),
      async onQueryStarted(input, { dispatch, getState, queryFulfilled }) {
        // This is terrible. RTK offers no solution for typing getState
        // so we have to resort to typecasting.
        const { settings } = getState() as unknown as RootState;
        const { moduleSettings, extensionsSettings } = settings;

        const {
          data: { id: appId },
        } = await queryFulfilled;

        const newExtensionModuleConfigId = v4();

        // TODO(foley): what do we do about sort? do we even need it on the extension?
        const newExtensionModule: ExtensionConfig = {
          id: newExtensionModuleConfigId,
          type: 'global' as const,
          isAuthenticated: true,
          icon: input.icon,
          name: input.name,
          appId,
          autoSizeEmbed: true,
          sort: 0, // sort is not used any more. All extensionConfig objects has sort 0
        };

        const moduleSettingsItem: ModuleSettingsItem = {
          id: newExtensionModuleConfigId,
          label: input.name,
          icon: input.icon,
          disabled: false,
          type: 'global',
          visibilityConfig: input.visibilityConfig,
        };

        // when app is created, optimistically perform install side-effects
        // which includes updating the moduleSettings and extensionsSettings
        const patch = dispatch(
          applicationSettingsApi.util.updateQueryData(
            'getInstalls',
            undefined,
            (draftState) => {
              draftState.moduleSettings.push(moduleSettingsItem);
              draftState.extensionsSettings[newExtensionModuleConfigId] =
                newExtensionModule;
            },
          ),
        );

        try {
          // The order is important here. We need to update the extension settings
          // before the module settings, because the module settings depend on the
          // extension settings.
          await dispatch(
            updateExtensionSettingsAction({
              ...(extensionsSettings ?? {}),
              [newExtensionModuleConfigId]:
                newExtensionModule as ExtensionConfig,
            }),
          );

          await dispatch(
            updateModuleSettings(
              [...(moduleSettings ?? []), moduleSettingsItem],
              { disableSuccessAlert: true },
            ),
          );
        } catch (e) {
          await patch.undo();
        }
      },
      invalidatesTags: [ApiTags.apps],
    }),
    updateApp: build.mutation<
      MarketplaceApplication,
      MarketplaceApplication & {
        icon: IconType;
        moduleId: string;
      }
    >({
      query: (body) => ({
        method: ApiMethods.put,
        options: {
          body: omit(body, 'id', 'icon', 'moduleId'),
        },
        path: `/apps/${body.id}`,
      }),
      async onQueryStarted(input, { dispatch, getState, queryFulfilled }) {
        // This is terrible. RTK offers no solution for typing getState
        // so we have to resort to typecasting.
        const { settings } = getState() as unknown as RootState;
        const { extensionsSettings, moduleSettings } = settings;
        if (!moduleSettings) {
          throw new Error('Module settings not found');
        }

        const moduleSettingsItem = (moduleSettings ?? []).find(
          (m) => m.id === input.moduleId,
        );
        if (!moduleSettings || !moduleSettingsItem || !input.moduleId) {
          // TODO(foley): Implement error handling.
          return;
        }

        const extensionSettingsItem = extensionsSettings?.[input.moduleId];
        if (!extensionSettingsItem) {
          // TODO(foley): Implement error handling.
          return;
        }

        await queryFulfilled;

        // The order is important here. We need to update the extension settings
        // before the module settings, because the module settings depend on the
        // extension settings.

        await dispatch(
          updateExtensionSettingsAction({
            ...(settings?.extensionsSettings ?? {}),
            [input.moduleId]: {
              ...extensionSettingsItem,
              name: input.name,
              // If icon ever gets set to '' it causes trouble, so let's
              // make sure that does not happen.
              icon: input.icon || 'dashboard',
            },
          }),
        );

        await dispatch(
          updateModuleSettings(
            moduleSettings.map((m) => {
              const updatedModule = {
                ...m,
                label: input.name,
                icon: input.icon || 'dashboard',
              };

              if (m.id === input.moduleId) {
                return updatedModule;
              }
              return m;
            }),
            { disableSuccessAlert: true },
          ),
        );
      },
      invalidatesTags: [ApiTags.apps],
    }),
    getApp: build.query<MarketplaceApplication, { id: string }>({
      query: ({ id }) => ({
        path: `/apps/${id}`,
        method: ApiMethods.get,
        options: {
          queryStringParameters: {},
        },
      }),
      providesTags: [ApiTags.apiKeys],
    }),
    deleteApp: build.mutation<MarketplaceApplication, { id: string }>({
      query: ({ id }) => ({
        path: `/apps/${id}`,
        method: ApiMethods.del,
        options: {
          queryStringParameters: {},
        },
      }),
    }),
    getInstalls: build.query<
      {
        moduleSettings: ModuleSettingsItem[];
        extensionsSettings: ExtensionsSettings;
      },
      void
    >({
      queryFn: async () => {
        const installs = await getInstalls();
        // map installs into their respective moduleSettings and extensionsSettings
        return {
          data: {
            moduleSettings: mapInstallsToModuleSettings(installs),
            extensionsSettings: mapInstallsToExtensionsSettings(installs),
          },
        };
      },
      providesTags: [ApiTags.apiKeys],
    }),
    getAppInstallEmbedCode: build.query<
      string,
      { appId: string; userId?: string; companyId?: string }
    >({
      query: ({ appId, userId, companyId }) => ({
        method: ApiMethods.get,
        path: `/v1/app-installs/${appId}/embed`,
        options: {
          queryStringParameters: {
            userId,
            companyId,
          },
        },
      }),
    }),
  }),
});

export const {
  useCreateAppMutation,
  useGetAppQuery,
  useUpdateAppMutation,
  useDeleteAppMutation,
  useGetInstallsQuery,
  useGetAppInstallEmbedCodeQuery,
} = applicationSettingsApi;
