import { Auth } from 'aws-amplify';
import { RowNode } from '@ag-grid-community/core';
import { CognitoUser, Operations } from 'src/store/user/types';
import { Client, Company, CustomFieldOption } from 'src/store/clients/types';
import { User } from 'src/constants/dataTypes';
import { PortalConfig } from 'src/context';
import { SeverityLevel } from 'src/legacy/components/UI';
import { TrackEvent } from 'src/utils/AnalyticsUtils';
import DateUtils from 'src/utils/DateUtils';
import { EntityStatus } from 'src/constants';
import { MultiSelectOption } from 'src/legacy/components/Select/types';

type FieldsEntityType = Client | Company | User;

export function hasUserNameFields(item: FieldsEntityType): item is User {
  return Boolean('givenName' in item.fields || 'familyName' in item.fields);
}

export const getInitials = (name = '') =>
  name
    .replace(/\s+/, ' ')
    .split(' ')
    .slice(0, 1)
    .map((v) => v && v.at(0)?.toUpperCase())
    .join('');

// less specific check to see if the user is/is not archived or deleted
export const isActive = (user: User | Client) =>
  user.entityStatus !== EntityStatus.Archived &&
  user.entityStatus !== EntityStatus.Deleted;

/**
 * Check if the user is confirmed
 * @param user user/client entity being checked for status
 * @returns boolean value indicating whether the user should be considered "confirmed"
 */
export const isConfirmed = (user: User | Client) =>
  user?.fields.userStatus === 'CONFIRMED' ||
  (user?.fields.userStatus === 'FORCE_CHANGE_PASSWORD' &&
    !DateUtils.isEmptyDateStr(user?.fields.lastLoginDate));

// used to determine if a client is setup so that they would be able to access the portal
// if the user is confirmed they have a password they can use to login to the portal
export const isUserEnabled = (user: User | Client) =>
  (user.fields.userStatus === 'CONFIRMED' ||
    user.fields.userStatus === 'FORCE_CHANGE_PASSWORD') &&
  isActive(user);

export const userTableNameComparator = (
  _a: any,
  _b: any,
  nodeA: RowNode,
  nodeB: RowNode,
) => (nodeA.data.userName || '').localeCompare(nodeB.data.userName || '');

export const hasPermission = (
  permissionName: string,
  permissions?: Record<string, Operations>,
) => {
  if (permissions) {
    if (permissions[permissionName]) {
      return true;
    }
  }
  return false;
};

export const hasOperation = (
  permissionName: string,
  operationName: string,
  permissions?: Record<string, Operations>,
) => {
  return Boolean(getOperation(permissionName, operationName, permissions));
};

export const getOperation = (
  permissionName: string,
  operationName: string,
  permissions?: Record<string, Operations>,
) => {
  if (permissions) {
    const permission = permissions[permissionName];
    if (permission?.operations && permission?.operations[operationName]) {
      return permission.operations[operationName];
    }
  }
  return null;
};

export const getCompanyId = (clientId: string, clients: User[]) =>
  clients.find((client) => client.id === clientId)?.fields.companyId;

export const generatePassword = (passwordLengh: number) => {
  const uppercaseList = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
  const lowercaseList = 'abcdefghijklmnopqrstuvwxyz';
  const numberList = '0123456789';

  const charset = uppercaseList + lowercaseList + numberList;
  let password = '';
  for (let i = 0, n = charset.length; i < passwordLengh; i += 1) {
    password += charset.charAt(Math.floor(Math.random() * n));
  }
  const indexOfUppercase = Math.floor(Math.random() * passwordLengh);
  let indexOfNumber = indexOfUppercase;
  while (indexOfNumber === indexOfUppercase) {
    indexOfNumber = Math.floor(Math.random() * passwordLengh);
  }
  password =
    password.substring(0, indexOfUppercase) +
    uppercaseList[Math.floor(Math.random() * uppercaseList.length)] +
    password.substring(indexOfUppercase + 1);
  password =
    password.substring(0, indexOfNumber) +
    numberList[Math.floor(Math.random() * numberList.length)] +
    password.substring(indexOfNumber + 1);
  return password;
};

export const getEmailDomain = (email: string) => {
  if (email) {
    return email.substring(email.lastIndexOf('@') + 1);
  }
  return '';
};

export const getEmailDomainList = (companies: Company[]) => {
  // TODO use companySearch property for getting company email domain
  // extracted email doman from user email for now
  const emailList = companies.map((company) => ({
    companyId: company.id,
    emailDomains: company.fields ? company.fields.associatedDomains : [],
  }));
  return emailList;
};

export const formatStatus = (user: User) => {
  if (!isActive(user)) {
    return 'Deleted';
  }

  const { userStatus } = user.fields;
  const { invitedBy } = user.fields;

  if (userStatus === 'CONFIRMED') {
    return 'Active';
  }

  if (!invitedBy) {
    return 'Not Invited';
  }

  if (userStatus === 'FORCE_CHANGE_PASSWORD') {
    return 'Invited';
  }

  if (userStatus === 'UNCONFIRMED' || userStatus === 'RESET_REQUIRED') {
    return 'Pending Verification';
  }
  return 'N/A';
};

export const getUserAttributesValue = (
  instance: CognitoUser | null,
  fieldName: string,
) => {
  if (instance && instance.attributes) {
    let attributeValue = '';
    Object.keys(instance.attributes).forEach((key) => {
      if (key === fieldName) {
        attributeValue = instance.attributes[key];
      }
    });

    return attributeValue;
  }
  return '';
};

export interface UserProfile {
  firstName: string;
  lastName: string;
  avatarUrl: string;
  email: string;
  fallbackColor: string;
  id: string;
}

export const getProfileFromUser = (user: User): UserProfile => ({
  firstName: user.fields?.givenName ?? '',
  lastName: user.fields?.familyName ?? '',
  avatarUrl: user.fields?.avatarImageUrl ?? '',
  email: user.fields?.email ?? '',
  id: user.id,
  fallbackColor: user.fields?.fallbackColor ?? '',
});

export const GetProfileFromChannelMember = (user: any): UserProfile => ({
  firstName: user.name ? user.name.trim().split(' ')[0] : undefined,
  lastName: user.name ? user.name.trim().split(' ')[1] : undefined,
  avatarUrl: user.image,
  email: '',
  id: user.id,
  fallbackColor: user.fallbackColor,
});

export const getUserIdFromFullId = (userFullId: string): string => {
  const splitedId = userFullId.split('_');
  const lastVal = splitedId.pop();

  return lastVal || '';
};

export const getFullUserIdFromUserId = (
  portalConfig: PortalConfig,
  userId: string,
): string => `${portalConfig.portalHeader}_${userId}`;

export const handleSignOut = async (
  portalConfig: PortalConfig,
  isTestUser: boolean,
) => {
  try {
    await Auth.signOut();
    TrackEvent(`Signed Out`, { isTestUser });
    // await dispatch(clearStoreData());
    window.location.replace(
      `/auth/signout?next=${
        portalConfig.MarketingSite.Enabled
          ? '/'
          : portalConfig.SignoutRedirectURL
      }`,
    );
  } catch (err) {
    // TODO: handle the error
  }
};

/**
 * @param status client/user status
 * @returns severity level regrding status
 */
export const mapStatusToSeverity = (status: string): SeverityLevel => {
  let severityLevel: SeverityLevel = SeverityLevel.low;
  switch (status.toLowerCase()) {
    case 'active':
      severityLevel = SeverityLevel.low;
      break;
    case 'invited':
      severityLevel = SeverityLevel.medium;
      break;
    case 'not invited':
      severityLevel = SeverityLevel.high;
      break;
    default:
      break;
  }

  return severityLevel;
};

export const nameComparator = (a: User, b: User) => {
  if (a.fields.givenName < b.fields.givenName) {
    return -1;
  }
  if (a.fields.givenName > b.fields.givenName) {
    return 1;
  }
  return 0;
};

export const userProfileComparator = (a: UserProfile, b: UserProfile) =>
  `${a.firstName}${a.lastName}`.localeCompare(`${b.firstName}${b.lastName}`);

const getMultiSelectSortString = (
  selectedOptIDs: string[],
  allOptions: MultiSelectOption[],
) => {
  const optionLabelReducer = (validOptions: string[], optionID: string) => {
    const validOption = allOptions.find((opt) => opt.id === optionID);
    if (validOption) {
      return validOptions.concat(validOption.label);
    }
    return validOptions;
  };
  return selectedOptIDs
    .reduce(optionLabelReducer, [])
    .sort((labelA, labelB) => labelA.localeCompare(labelB))
    .join('');
};

/**
 *
 * @param a first node containing row value for custom field
 * @param b second node containing row val for custom field
 * @param customFieldKey ID of the custom field for lookup on row nodes
 * @param option contains custom field info, we need contained labels for multi-select sorting
 */
export const customFieldsComparator = (
  a: RowNode,
  b: RowNode,
  customFieldKey: string,
  option: CustomFieldOption,
) => {
  let valueA = a.data[customFieldKey];
  let valueB = b.data[customFieldKey];
  // if values are objects (only arrays for now) - we know they are multi-select and need to become sortable strings
  if (Array.isArray(valueA)) {
    valueA = getMultiSelectSortString(valueA, option.options || []);
  }
  if (Array.isArray(valueB)) {
    valueB = getMultiSelectSortString(valueB, option.options || []);
  }
  if (option.type === 'address') {
    valueA = valueA?.fullAddress;
    valueB = valueB?.fullAddress;
  }

  if (!valueA) {
    return 1;
  }

  if (!valueB) {
    return -1;
  }

  // alphanumeric values sorted by .localeCompare
  return valueA.localeCompare(valueB);
};

/**
 *
 * @param userId string - Id of the logged in user
 * @param users User[] - Internal users
 * @returns string - ref
 */
export const getUserRef = (userId: string, users: User[] | null): string => {
  if (!users || users.length === 0) {
    return '';
  }
  const user = users.find((u) => u.id === userId);
  return user?.ref ?? '';
};
