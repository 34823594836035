const codes = [
  // Client-only Values
  'unknown',

  // API Values
  'user_not_found',
  'auth_user_expired',
  'invalid_request_error',
  'api_error',
  'aws_error',
  'record_conflict',
  'payment_processor_error',
  'auth_user_expired',
  'portal_clone_failed',
  'company_not_found',
  'account_already_exists',
  'user_api_permission',
  'not_found',
  'user_api_password_reset',
  'company_client_not_found',
  'custom_fields_not_found',
  'parameter_invalid_empty',
  'esig_request_invalid',
  'tags_perm_err',
  'billing_no_customer',
  'billing_no_active_customer_subscription',
  'billing_no_subscription',
  'billing_no_subscription_items',
  'billing_no_bank_connected',
  'billing_no_valid_bank_connected',
  'billing_no_payment_method',
  'billing_card_declined',
  'generic_decline',
  'articles_perm_err',
  'domains_settings_api_err',
  'domains_settings_api_invalid_domain',
  'domains_settings_duplicate_err',
  'domain_settings_api_invalid_status',
  'domains_settings_api_unauthorized',
  'domains_settings_reset_failed',
  'domains_status_get_failed',
  'dynamic_form_input_missing',
  'dynamic_form_missing_name',
  'dynamic_form_missing_form_id',
  'dynamic_form_invalid_input_option',
  'dynamic_form_no_forms_receivers',
  'dynamic_form_response_not_found',
  'dynamic_form_not_found',
  'dynamic_input_schema_invlaid_type',
  'dynamic_input_schema_missing_options',
  'dynamic_input_schema_missing',
  'dynamic_input_schema_required',
  'dynamic_input_schema_answer_too_long',
  'latest_releases_perm_err',
  'invalid_task',
  'recommended_resource_perm_err',
  'portal_stack_invalid_name',
  'unsupported_media_type',

  // Auth errors
  'CodeMismatchException',
  'already_exists',
  'mfa_required',
] as const;

type ErrorCode = (typeof codes)[number];

interface ApiError {
  code: ErrorCode;
  message: string;
  _unsupported_code?: string;
}

// Intentionally more broadly typed than the array so we can do runtime
// lookups against unknown values without running into type errors.
const codesSet = new Set<string>(codes);

export function ensureError(value: unknown): Error {
  if (value instanceof Error) return value;

  let stringified = '[Unable to stringify the thrown value]';
  try {
    stringified = JSON.stringify(value);
  } catch {
    /* do nothing */
  }

  const error = new Error(
    `This value was thrown as is, not through an Error: ${stringified}`,
  );
  return error;
}

const getApiErrorValues = (untypedValue: unknown): ApiError | undefined => {
  if (!untypedValue || typeof untypedValue !== 'object') {
    return undefined;
  }

  const value = untypedValue as Record<string | symbol | number, unknown>;
  const check = (obj: unknown): ApiError | undefined => {
    if (!obj || typeof obj !== 'object') return undefined;
    const apiError = obj as ApiError;
    if (
      'code' in apiError &&
      // Here we want to send back any code, not just the valid ones.
      typeof apiError.code === 'string' &&
      typeof apiError.message === 'string'
    ) {
      return apiError as ApiError;
    }
    return undefined;
  };

  // This section checks in various places on the object to find out
  // where the API error object is. The reason for this is because I
  // noticed our error handling assumed all 3 of these object shapes
  // in various places, i.e.:
  //               root: ApiError
  //          root.data: ApiError
  // root.response.data: ApiError
  const root = check(value);
  if (root) return root;
  if ('data' in value) {
    const data = check(value.data);
    if (data) return data;
  }
  if (
    'response' in value &&
    value.response &&
    typeof value.response === 'object' &&
    'data' in value.response
  ) {
    const response = check((value.response as any).data);
    if (response) return response;
  }
  if (
    'error' in value &&
    value.error &&
    typeof value.error === 'object' &&
    'data' in value.error &&
    (value.error as any).data
  ) {
    const error = check((value.error as any).data);
    if (error) return error;
  }

  return undefined;
};

export const ensureApiError = (value: unknown): ApiError => {
  const error = ensureError(value);
  const apiError = error as any as ApiError;
  const apiErrorValues = getApiErrorValues(value);
  if (!apiErrorValues) {
    apiError.code = 'unknown';
  } else if (!codesSet.has(apiErrorValues.code)) {
    apiError._unsupported_code = apiErrorValues.code;
    apiError.code = 'unknown';
    if (apiErrorValues.message) apiError.message = apiErrorValues.message;
  } else {
    apiError.code = apiErrorValues.code;
    if (apiErrorValues.message) apiError.message = apiErrorValues.message;
  }

  return error as any as ApiError;
};
