import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import {
  DEFAULT_DATE_FORMAT,
  SIGNATURE_COMPONENT_DEFAULT_HEIGHT,
  SIGNATURE_COMPONENT_DEFAULT_WIDTH,
  SignatureComponentType,
} from 'src/constants';
import {
  ESignatureFlowStatus,
  SignaturePageComponent,
} from 'src/store/signaturePage/types';
import { useAddDateForm } from '../../UI/Date/useAddDateForm';
import { useAddMySignatureForm } from 'src/legacy/components/Signature/SignatureSidebar/useAddMySignatureForm';
import { useAddTextForm } from 'src/legacy/components/Signature/SignatureSidebar/useAddTextForm';
import {
  CompleteReceiverComponentAction,
  InsertEverywhereAction,
  RemoveActiveComponent,
  SetActiveComponent,
  SkipComponentAction,
  UpdateClientSignatureFlowStatus,
} from 'src/store/signaturePage/actions';
import { SignatureClientFormV2 } from 'src/legacy/components/Signature/SignatureComponents/SignatureClientFormV2';
import { RootState } from 'src/store';
import { CANVAS_SIGNATURE_COMPONENT_HEIGHT } from 'src/legacy/components/Canvas/BaseCanvas';
import { scrollToComponent } from 'src/legacy/components/Signature/ClientSignatureIndicatorPanel';

type SelectedComponentObject = {
  component: SignaturePageComponent | null;
  element?: HTMLButtonElement | null;
};

type PrimaryClickAction = {
  formValues: any;
  selectedComponent: SelectedComponentObject;
  callbackFunction: (param: SelectedComponentObject | null) => void;
};

export const useSignatureRequestComponent = () => {
  const dispatch = useDispatch();
  const pageComponents = useSelector(
    (state: RootState) => state.signaturePage.pageComponents,
  );

  const activeComponentKey = useSelector(
    (state: RootState) => state.signaturePage.activeComponentKey,
  );

  const [selectedReceiverSignComponent, setSelectedReceiverSignComponent] =
    React.useState<SelectedComponentObject | null>(null);
  const [selectedReceiverDateComponent, setSelectedReceiverDateComponent] =
    React.useState<SelectedComponentObject | null>(null);
  const [
    selectedReceiverInitialsComponent,
    setSelectedReceiverInitialsComponent,
  ] = React.useState<SelectedComponentObject | null>(null);
  const [selectedReceiverTextComponent, setSelectedReceiverTextComponent] =
    React.useState<SelectedComponentObject | null>(null);

  // if true find the next component to be signed
  const [getNextComponentToSign, setGetNextComponentToSign] =
    React.useState(false);

  // State to track the signature input types whose values are filled using insert everywhere.
  // This helps in skipping these inputs during the "Back to Edit" flow.
  const [filledByInsertEverywhere, setFilledByInsertEverywhere] = useState<
    string[]
  >([]);

  const resetAllFormStates = () => {
    setSelectedReceiverDateComponent(null);
    setSelectedReceiverInitialsComponent(null);
    setSelectedReceiverSignComponent(null);
    setSelectedReceiverTextComponent(null);
  };

  /**
   * handleRequestReceiverInfo - hook method that recieves the eSignature (eSig.) component
   * regarding which component type is. Before changing the active client form we need to clean the previous state for the cases, when two
   * equal types of compoents are in the eSig document. If not clean then state will have the previous component
   * and client form will not show
   * @param component eSignature component
   */
  const handleRequestReceiverInfo = async (
    component: SignaturePageComponent,
  ) => {
    const selectedComponent = {
      component,
    };

    resetAllFormStates();

    switch (component.componentType) {
      case SignatureComponentType.REQUEST_SIGN:
        setSelectedReceiverSignComponent(selectedComponent);
        break;
      case SignatureComponentType.REQUEST_DATE:
        setSelectedReceiverDateComponent(selectedComponent);
        break;
      case SignatureComponentType.REQUEST_INITIAL:
        setSelectedReceiverInitialsComponent(selectedComponent);
        break;
      case SignatureComponentType.REQUEST_TEXT:
        setSelectedReceiverTextComponent(selectedComponent);
        break;
      default:
        console.info('request component', component);
    }
  };

  const handleRemoveActiveComponent = async (
    callbackFunction: (param: SelectedComponentObject | null) => void,
  ) => {
    callbackFunction(null);
  };

  const getResizedDimensionsForSignatures = (
    component: Required<SignaturePageComponent & { fields: any }>,
  ): SignaturePageComponent => {
    const {
      height: placeHolderComponentHeight,
      width: placeHolderComponentWidth,
      fields: { signatureWidth },
    } = component;
    let width: number | '100%';
    let height: number;

    // Documents sent for e-sigs in V1 have width,height = 0. This causes problem for e-signatures in V2.
    // Setting them to default component width and height for correct calculations.
    const componentHeight =
      placeHolderComponentHeight || SIGNATURE_COMPONENT_DEFAULT_HEIGHT;
    const componentWidth =
      placeHolderComponentWidth || SIGNATURE_COMPONENT_DEFAULT_WIDTH;

    const isWidthGreaterThanHeight = componentWidth > componentHeight;

    const placeHolderToImageHeightRatio =
      componentHeight / CANVAS_SIGNATURE_COMPONENT_HEIGHT;

    const placeHolderToImageWidthRatio = componentWidth / signatureWidth;

    const resizedWidth = signatureWidth * placeHolderToImageHeightRatio;

    const resizedHeight =
      CANVAS_SIGNATURE_COMPONENT_HEIGHT * placeHolderToImageWidthRatio;

    if (isWidthGreaterThanHeight) {
      // If width of placeholder component is greater than height, there are two edge cases to be handled:
      // If text is longer than placeholder width, we want image to be stretched.
      // Else component needs to be constrained by height and to be left aligned so that it doesn't stretched to very end.
      if (signatureWidth > componentWidth) {
        width = componentWidth;
        height = componentHeight;
      } else {
        width = resizedWidth;
        height = componentHeight;
      }
    } else {
      // If height of placeholder component is greater than width, component needs to be aligned to center vertically and edge to edge horizontally.
      width = componentWidth;
      height = resizedHeight;
    }
    return {
      ...component,
      resizedHeight: Math.floor(height),
      resizedWidth: Math.floor(width),
    };
  };

  // get all the client components
  const clientComponents = React.useMemo(
    () =>
      pageComponents
        ?.filter((component) => component.componentType.startsWith('request'))
        .sort((a, b) => a.yPosition - b.yPosition) || [],
    [pageComponents],
  );

  /**
   * Method to find the next client signature component that needs to be signed
   * and make it active
   */
  const findNextSigningComponent = () => {
    // If there's a component with no value, make it active for signing
    const notSkippedNotFilledComponent = clientComponents.find(
      (component) =>
        !component.isSkipped &&
        !component.value &&
        component.key !== activeComponentKey,
    );

    if (notSkippedNotFilledComponent) {
      dispatch(SetActiveComponent(notSkippedNotFilledComponent.key));
      setGetNextComponentToSign(false);
      return;
    }

    const currentActiveComponentIndex = clientComponents.findIndex(
      (component) => component.key === activeComponentKey,
    );
    // Back to edit flow: If all the client signature components are signed, mark the
    // next signature component from clientComponents array as active for signing
    // Also we are skipping the client signature components which are already edited using insert everywhere
    const nextActiveComponent =
      filledByInsertEverywhere.length > 0
        ? clientComponents.find(
            (c, i) =>
              !filledByInsertEverywhere.includes(c.componentType) &&
              i > currentActiveComponentIndex,
          )
        : clientComponents.at(currentActiveComponentIndex + 1);

    if (nextActiveComponent) {
      dispatch(SetActiveComponent(nextActiveComponent.key));
      setGetNextComponentToSign(false);
      return;
    }

    // When both nextActiveComponent and notSkippedNotFilledComponent are null, move the user to the next step (End Signature)
    dispatch(RemoveActiveComponent());
    dispatch(
      UpdateClientSignatureFlowStatus(ESignatureFlowStatus.END_SIGNAURE),
    );
    setGetNextComponentToSign(false);
    setFilledByInsertEverywhere([]);
  };

  React.useEffect(() => {
    if (getNextComponentToSign) {
      findNextSigningComponent();
    }
  }, [getNextComponentToSign, clientComponents]);

  React.useEffect(() => {
    // if there is no active component do not return any client signature form
    if (!activeComponentKey) {
      resetAllFormStates();
      return;
    }

    // based on activeComponentKey set client signature form
    const stageComponent = clientComponents.find(
      (c) => c.key === activeComponentKey,
    );

    if (stageComponent) {
      scrollToComponent(stageComponent.key);
      handleRequestReceiverInfo(stageComponent);
    }
  }, [activeComponentKey]);

  const handleSaveComponent = async (
    formValues: any,
    callbackFunction: (param: SelectedComponentObject | null) => void,
  ) => {
    let payload: any = {};
    switch (formValues.componentType) {
      case SignatureComponentType.REQUEST_SIGN:
      case SignatureComponentType.REQUEST_INITIAL:
        payload = getResizedDimensionsForSignatures(formValues);
        break;
      default:
        payload = { ...formValues };
        break;
    }
    dispatch(CompleteReceiverComponentAction(payload));
    handleRemoveActiveComponent(callbackFunction);
    setGetNextComponentToSign(true);
  };

  const handleInsertEverywhere = async (
    formValues: any,
    callbackFunction: (param: SelectedComponentObject | null) => void,
  ) => {
    let payload: any = {};
    switch (formValues.componentType) {
      case SignatureComponentType.REQUEST_SIGN:
      case SignatureComponentType.REQUEST_INITIAL:
        payload = getResizedDimensionsForSignatures(formValues);
        break;
      default:
        payload = { ...formValues };
        break;
    }
    dispatch(InsertEverywhereAction(payload));
    handleRemoveActiveComponent(callbackFunction);
    setFilledByInsertEverywhere((prev) => [...prev, formValues.componentType]);
    setGetNextComponentToSign(true);
  };
  const handleSkipComponent = async (
    formValues: any,
    callbackFunction: (param: SelectedComponentObject | null) => void,
  ) => {
    dispatch(SkipComponentAction(formValues));
    handleRemoveActiveComponent(callbackFunction);
    setGetNextComponentToSign(true);
  };

  // This function finds all the request component for a specific type
  // return true if we have >= 2 components of same request type
  const hasMultipleComponentsByType = (
    requestComponentType: string,
  ): boolean => {
    const components = pageComponents.filter(
      (c) => c.componentType === requestComponentType,
    );
    return components.length >= 2;
  };

  const getPrimaryButtonLabel = (
    selectedComponent: SelectedComponentObject,
  ) => {
    if (!selectedComponent.component) return '';

    // show 'Insert everywhere' label only when we have >= 2 request components of same type
    if (
      !hasMultipleComponentsByType(selectedComponent.component?.componentType)
    )
      return 'Insert';

    return 'Insert everywhere';
  };
  const getSecondaryButtonLabel = (
    selectedComponent: SelectedComponentObject,
  ) => {
    if (!selectedComponent?.component) return '';

    // show secondary button only when we have >= 2 request components of same type
    // since we will be using primary button to insert
    if (!hasMultipleComponentsByType(selectedComponent.component.componentType))
      return '';

    return 'Insert';
  };

  const handlePrimaryButtonClick = ({
    formValues,
    selectedComponent,
    callbackFunction,
  }: PrimaryClickAction) => {
    if (!selectedComponent?.component) return;

    // When we have less that 2 request components of same type
    if (
      !hasMultipleComponentsByType(selectedComponent.component?.componentType)
    ) {
      handleSaveComponent(formValues, callbackFunction);
      return;
    }
    // When insert everywhere is clicked
    handleInsertEverywhere(formValues, callbackFunction);
  };

  const signatureComponentsForm = () => {
    // Show the form to add the signature for Client
    if (selectedReceiverSignComponent?.component)
      return (
        <SignatureClientFormV2
          initialFormValue={{
            name: '',
            isAgree: false,
            ...(selectedReceiverSignComponent?.component || {}),
            value: selectedReceiverSignComponent?.component?.imageTextValue,
          }}
          title="Add your signature"
          primaryButtonActionLabel={getPrimaryButtonLabel(
            selectedReceiverSignComponent,
          )}
          secondaryButtonActionLabel={getSecondaryButtonLabel(
            selectedReceiverSignComponent,
          )}
          onPrimaryButtonClick={(formValues: any) =>
            handlePrimaryButtonClick({
              formValues,
              selectedComponent: selectedReceiverSignComponent,
              callbackFunction: setSelectedReceiverSignComponent,
            })
          }
          onSecondaryButtonClick={(formValues: any) =>
            handleSaveComponent(formValues, setSelectedReceiverSignComponent)
          }
          useFormHook={() =>
            useAddMySignatureForm(
              'signature',
              selectedReceiverSignComponent?.component?.imageTextValue,
            )
          }
        />
      );
    // Show the form to add the date for Client
    if (selectedReceiverDateComponent?.component)
      return (
        <SignatureClientFormV2
          initialFormValue={{
            ...(selectedReceiverDateComponent?.component || {}),
            value:
              selectedReceiverDateComponent?.component.value ??
              moment().format(DEFAULT_DATE_FORMAT),
          }}
          title="Add a date"
          primaryButtonActionLabel={getPrimaryButtonLabel(
            selectedReceiverDateComponent,
          )}
          secondaryButtonActionLabel={getSecondaryButtonLabel(
            selectedReceiverDateComponent,
          )}
          onPrimaryButtonClick={(formValues: any) =>
            handlePrimaryButtonClick({
              formValues,
              selectedComponent: selectedReceiverDateComponent,
              callbackFunction: setSelectedReceiverDateComponent,
            })
          }
          onSecondaryButtonClick={(formValues: any) =>
            handleSaveComponent(formValues, setSelectedReceiverDateComponent)
          }
          useFormHook={useAddDateForm}
        />
      );
    // Show the form to add the initials for Client
    if (selectedReceiverInitialsComponent?.component) {
      return (
        <SignatureClientFormV2
          initialFormValue={{
            name: '',
            ...(selectedReceiverInitialsComponent?.component || {}),
            value: selectedReceiverInitialsComponent?.component?.imageTextValue,
          }}
          title="Add your initials"
          primaryButtonActionLabel={getPrimaryButtonLabel(
            selectedReceiverInitialsComponent,
          )}
          secondaryButtonActionLabel={getSecondaryButtonLabel(
            selectedReceiverInitialsComponent,
          )}
          onPrimaryButtonClick={(formValues: any) =>
            handlePrimaryButtonClick({
              formValues,
              selectedComponent: selectedReceiverInitialsComponent,
              callbackFunction: setSelectedReceiverInitialsComponent,
            })
          }
          onSecondaryButtonClick={(formValues: any) =>
            handleSaveComponent(
              formValues,
              setSelectedReceiverInitialsComponent,
            )
          }
          useFormHook={() =>
            useAddMySignatureForm(
              'initial',
              selectedReceiverInitialsComponent?.component?.imageTextValue,
            )
          }
        />
      );
    }
    // Show the form to add text for Client
    if (selectedReceiverTextComponent?.component) {
      return (
        <SignatureClientFormV2
          initialFormValue={{
            ...(selectedReceiverTextComponent?.component || {}),
          }}
          title={selectedReceiverTextComponent?.component?.label ?? 'Add text'}
          primaryButtonActionLabel="Insert"
          secondaryButtonActionLabel={
            selectedReceiverTextComponent?.component?.isOptional ? 'Skip' : ''
          }
          onPrimaryButtonClick={(formValues: any) =>
            handleSaveComponent(formValues, setSelectedReceiverTextComponent)
          }
          onSecondaryButtonClick={(formValues: any) =>
            handleSkipComponent(formValues, setSelectedReceiverTextComponent)
          }
          useFormHook={() =>
            useAddTextForm({
              label: selectedReceiverTextComponent?.component?.label,
            })
          }
          isOptional={selectedReceiverTextComponent?.component?.isOptional}
        />
      );
    }
    return null;
  };

  const SignatureComponentsForm = () => signatureComponentsForm();

  return {
    handleRequestReceiverInfo,
    SignatureComponentsForm,
  };
};
