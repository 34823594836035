import React from 'react';
import { PostOrPage } from '@tryghost/content-api';
import { HomePageSection } from 'src/legacy/components/HomePage/HomePageSection';
import { DateUtils } from 'src/utils';
import { LatestReleaseCard } from 'src/legacy/components/HomePage/LatestReleaseSection/LatestReleaseCard';

interface LatestReleaseSectionProps {
  latestReleasePost: PostOrPage | null;
}
export const LatestReleaseSection: React.FC<LatestReleaseSectionProps> = ({
  latestReleasePost,
}) => {
  const latestReleaseAt = React.useMemo(() => {
    if (!latestReleasePost?.published_at) return '';
    const releaseDate = new Date(latestReleasePost?.published_at);

    const releasePeriodInDays = DateUtils.getDiffFromDate(releaseDate, 'days');
    const releasePeriodInHours = DateUtils.getDiffFromDate(
      releaseDate,
      'hours',
    );
    const releasePeriodInMinutes = DateUtils.getDiffFromDate(
      releaseDate,
      'minutes',
    );

    if (releasePeriodInDays >= 1) {
      return `${releasePeriodInDays} day${
        releasePeriodInDays > 1 ? 's' : ''
      } ago `;
    }

    if (releasePeriodInHours >= 1) {
      return `${releasePeriodInHours} hour${
        releasePeriodInHours > 1 ? 's' : ''
      } ago`;
    }

    return `${releasePeriodInMinutes} minute${
      releasePeriodInMinutes > 1 ? 's' : ''
    } ago`;
  }, [latestReleasePost]);

  if (!latestReleasePost) return null;
  return (
    <HomePageSection title="Latest release" rightElement={latestReleaseAt}>
      <LatestReleaseCard latestReleasePost={latestReleasePost} />
    </HomePageSection>
  );
};
