import React from 'react';
import {
  Box,
  Theme,
  useMediaQuery,
  useTheme,
  SvgIconProps,
} from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/styles';
import { useDispatch, useSelector } from 'react-redux';
import { PortalConfigContext, RouteContext } from 'src/context';
import { RootState } from 'src/store';
import {
  toggleHelpCenterModalAction,
  alertSnackbar,
} from 'src/store/ui/actions';
import { ModalWrapper } from 'src/legacy/components/Modals';
import {
  QuestionIcon,
  ChevronRight,
  DocsIcon,
  UpdatesIcon,
  SlackIcon,
  PaperClipIcon,
  InfoIcon,
} from 'src/legacy/components/Icons';
import BaseTypography from 'src/legacy/components/Text/BaseTypography';
import * as Colors from 'src/theme/colors';
import * as Constants from 'src/constants';
import { BaseTextField } from 'src/legacy/components/TextField';
import { FileUploader } from 'src/legacy/components/Files';
import UiClient from 'src/clients/UiClients';
import { typography13RegularStyle } from 'src/legacy/components/Text';
import { shortcutsSidebarOpen } from 'src/store/shortcuts/slice';
import { useKeyboardShortcuts } from 'src/hooks/useKeyboardShortcuts';
import { REFERRAL_PAGE_V2 } from 'src/constants';
import history from 'src/history';
import {
  API_DOCS_URL,
  GET_STARTED_VIDEO_URL,
  JOIN_SLACK_COMMUNITY_URL,
  PORTAL_UPDATES_URL,
  SUPPORT_EMAIL,
} from 'src/constants/externalUrls';
import { useRouteAccess } from 'src/routes/routeAccess';

const helpCenterSections = [
  {
    Icon: DocsIcon,
    title: 'Help desk',
    desc: Constants.HELP_CENTER_DESC_FOR_PORTAL_GUIDE,
    linkTo: 'https://copilot.com/guide',
  },
  {
    Icon: SlackIcon,
    title: 'Join Slack community',
    desc: Constants.HELP_CENTER_DESC_FOR_JOIN_SLACK_COMMUNITY,
    linkTo: JOIN_SLACK_COMMUNITY_URL,
  },
  {
    Icon: QuestionIcon,
    title: 'Contact support',
    desc: Constants.HELP_CENTER_DESC_FOR_CONTACT_SUPPORT,
    linkTo: '',
  },
  {
    Icon: UpdatesIcon,
    title: 'Updates',
    desc: Constants.HELP_CENTER_DESC_FOR_UPDATES,
    linkTo: 'https://www.copilot.com/updates/',
  },
  {
    Icon: InfoIcon,
    title: 'Keyboard shortcuts',
    desc: Constants.HELP_CENTER_DESC_KEYBOARD_SHORTCUTS,
    linkTo: '',
  },
];

export interface HelpCenterLineItemProps {
  title: string;
  desc: string;
  linkTo: string;
  Icon?: (props: SvgIconProps) => JSX.Element;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      [theme.breakpoints.down('xs')]: {
        height: '100%',
      },
      [theme.breakpoints.up('sm')]: {
        width: '480px',
      },
      '& .MuiDialogActions-root': {
        '&>div': {
          margin: 0,
          width: '100%',
        },
        '& button': {
          backgroundColor: Colors.BlackHeadings,
          border: `1px solid ${Colors.NonHoverBlackBorder}`,
          color: Colors.white,
          width: '100%',
        },
      },
    },
    lineItem: {
      cursor: 'pointer',
      '& .item-icon': {
        fontSize: 14,
      },
      '& .right-arrow': {
        fontSize: 9,
        color: Colors.LightGray,
      },
      '&:hover': {
        '& .right-arrow': {
          color: Colors.BlackHeadings,
        },
      },
    },
    modalContent: {
      '& .MuiInputBase-root .MuiOutlinedInput-notchedOutline': {
        border: 'none',
      },
    },
    messageBox: {
      '&.MuiOutlinedInput-root.MuiOutlinedInput-multiline': {
        padding: 0,
      },
    },
    messageInput: {
      '&&.MuiInputBase-input': {
        ...typography13RegularStyle,
        padding: 0,
      },
    },
  }),
);
type QueryParams = {
  pageAction: string;
  body: string; // that's for the message content
};
export const HelpCenterModal: React.FC = () => {
  const classes = useStyles();
  const { query } = React.useContext(RouteContext);
  const { pageAction, body: supportMessage } = query as QueryParams;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));
  const dispatch = useDispatch();
  const routeAccess = useRouteAccess();
  const portalConfig = React.useContext(PortalConfigContext);
  const [attachedFileKey, setAttachedFileKey] = React.useState('');
  const [attachedFileFullPath, setAttachedFileFullPath] = React.useState('');
  const [message, setMessage] = React.useState('');
  const [modalInfo, setModalInfo] = React.useState({
    mode: 'HELP',
    title: 'Help',
    description: '',
    action: null,
  });

  const open = useSelector(
    (state: RootState) => state.ui.helpCenterModal.isOpen,
  );

  const closeHelpCenterModal = () => {
    dispatch(toggleHelpCenterModalAction(false));
  };

  const switchToContactSupportModal = () => {
    setModalInfo({
      mode: 'CONTACT_SUPPORT',
      title: 'Contact support',
      description: `You can also email us at ${SUPPORT_EMAIL}`,
      action: null,
    });
  };
  const handleHelpCenterItemClicked = (
    item: HelpCenterLineItemProps | undefined,
  ) => {
    if (item?.linkTo) {
      window.open(item?.linkTo);
      return;
    }
    if (item?.title === 'Keyboard shortcuts') {
      dispatch(shortcutsSidebarOpen(true));
      // close help center modal when shortcut sidebar is open
      closeHelpCenterModal();
      return;
    }
    switchToContactSupportModal();
  };

  React.useEffect(() => {
    // when page action is contactSupport switch to contact support modal
    if (pageAction === 'contactSupport' && open && supportMessage) {
      setTimeout(() => {
        switchToContactSupportModal();
        // set the message content to the query body param
        setMessage(supportMessage);
      }, 100);
    }
  }, [pageAction, open, supportMessage]);

  useKeyboardShortcuts([
    {
      id: 'open-help-desk',
      section: 'Help',
      name: 'Open help desk',
      perform: () => {
        handleHelpCenterItemClicked(helpCenterSections.at(0));
      },
    },
    {
      id: 'watch-welcome-video',
      section: 'Help',
      name: 'Explore video tutorials',
      perform: () => window.open(GET_STARTED_VIDEO_URL, '_blank'),
    },
    {
      id: 'contact-support',
      section: 'Help',
      name: 'Contact support',
      perform: () => {
        dispatch(toggleHelpCenterModalAction(true));
        setTimeout(() => switchToContactSupportModal(), 100);
      },
    },
    {
      id: 'join-slack-community',
      section: 'Miscellaneous',
      name: 'Join Slack community',
      perform: () => window.open(JOIN_SLACK_COMMUNITY_URL, '_blank'),
    },
    ...(routeAccess.allowSettingsReferralPage
      ? [
          {
            id: 'refer-portal',
            section: 'Miscellaneous',
            name: 'Refer Copilot',
            perform: () => history.push(REFERRAL_PAGE_V2.path),
          },
        ]
      : []),
    {
      id: 'see-recent-updates',
      section: 'Miscellaneous',
      name: 'See recent updates',
      perform: () => window.open(PORTAL_UPDATES_URL, '_blank'),
    },
    ...(routeAccess.allowSettingsApiPage
      ? [
          {
            id: 'open-api-docs',
            section: 'Miscellaneous',
            name: 'Open API documentation',
            perform: () => window.open(API_DOCS_URL, '_blank'),
          },
        ]
      : []),
    {
      id: 'show-keyboard-shortcuts',
      section: 'Miscellaneous',
      name: 'Show keyboard shortcuts',
      perform: () => dispatch(shortcutsSidebarOpen(true)),
    },
  ]);

  const renderLineItem = (item: HelpCenterLineItemProps, hasBorder = true) => (
    <Box
      width={1}
      display="flex"
      className={classes.lineItem}
      onClick={() => handleHelpCenterItemClicked(item)}
      key={item.title}
    >
      <Box mt={1.75}>{item.Icon && <item.Icon className="item-icon" />}</Box>
      <Box
        display="flex"
        width={1}
        alignItems="center"
        justifyContent="space-between"
        marginLeft="13px"
        py="11px"
        borderBottom={
          hasBorder ? `1px solid ${Colors.DividersAndCardBorders}` : 'none'
        }
      >
        <Box>
          <BaseTypography
            fontType="13Medium"
            style={{ color: Colors.BlackHeadings }}
          >
            {item.title}
          </BaseTypography>
          <BaseTypography style={{ color: Colors.GraySmall }}>
            {item.desc}
          </BaseTypography>
        </Box>
        <ChevronRight className="right-arrow" />
      </Box>
    </Box>
  );

  const messageChangeHandler = (event: any) => {
    const { value } = event.target;
    setMessage(value);
  };

  const renderContactSupportModalContent = () => (
    <Box
      minHeight="194px"
      my={1}
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
      className={classes.modalContent}
    >
      <BaseTextField
        InputProps={{
          classes: {
            root: classes.messageBox,
            inputMultiline: classes.messageInput,
          },
        }}
        fullWidth
        placeholder="How can we help you?"
        name="message"
        value={message}
        multiline
        rows={7}
        onChange={messageChangeHandler}
        variant="outlined"
        label=""
        autoFocus
      />
      <Box mt={2}>
        <FileUploader
          fileKey={attachedFileKey}
          onChangeFileKey={(key: string) => setAttachedFileKey(key)}
          prePath={`${portalConfig.portalHeader}/support`}
          label="Attach images, files, or videos"
          startIcon={<PaperClipIcon />}
          useUploadPortalFile
          setS3FilePath={(path: string) => setAttachedFileFullPath(path)}
        />
      </Box>
    </Box>
  );

  React.useEffect(() => {
    setAttachedFileKey('');
    setAttachedFileFullPath('');
    setMessage('');
    setModalInfo({
      mode: 'HELP',
      title: 'Help Center',
      description: '',
      action: null,
    });
  }, [open]);

  const sendEmailHandler = async () => {
    if (!message) {
      dispatch(
        alertSnackbar({
          errorMessage: `Cannot send message without content.`,
        }),
      );
    } else {
      try {
        await UiClient.contactSupport(message, attachedFileFullPath);
        dispatch(
          alertSnackbar({
            successMessage: `We’ll be in touch with you soon over email.`,
          }),
        );
        closeHelpCenterModal();
      } catch (error) {
        dispatch(
          alertSnackbar({
            errorMessage: 'Sending message failed.',
          }),
        );
      }
    }
  };

  return open ? (
    <ModalWrapper
      fullScreen={isMobile}
      open={open}
      title={modalInfo.title}
      description={modalInfo.description}
      onClose={closeHelpCenterModal}
      positiveAction={modalInfo.mode !== 'HELP'}
      successButtonLabel="Send message"
      onSuccess={sendEmailHandler}
    >
      {modalInfo.mode === 'HELP' ? (
        <Box display="flex" flexDirection="column" my={-2}>
          {helpCenterSections.map((lineItem, index, arr) =>
            renderLineItem(lineItem, arr.length !== index + 1),
          )}
        </Box>
      ) : (
        renderContactSupportModalContent()
      )}
    </ModalWrapper>
  ) : null;
};
