import React, { useMemo } from 'react';
import { Grid, Theme, Tooltip, makeStyles } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { v4 } from 'uuid';
import moment from 'moment';
import { useFormikContext } from 'formik';
import {
  InformationCircleIcon,
  NoCompanyIcon,
  SignatureDateIcon,
} from 'src/legacy/components/Icons';
import { SignatureSidebarButton } from 'src/legacy/components/Signature/SignatureComponents';
import {
  CONTRACT_COMPONENT_DEFAULT_HEIGHT,
  CONTRACT_COMPONENT_DEFAULT_WIDTH,
  Company,
  DEFAULT_DATE_FORMAT,
  SignatureComponentType,
  User,
} from 'src/constants';
import { InputType, useGetPortalConfigQuery } from 'src/services/api';
import { RootState } from 'src/store';
import {
  AddComponentAction,
  SetSelectedComponent,
} from 'src/store/signaturePage/actions';
import { ComponentCoordinatesType } from 'src/store/signaturePage/types';
import { MapFieldTypeToDefaultProps } from 'src/legacy/components/CustomFieldsMenu';
import { PredefinedAutofillKeys } from 'src/entities/Contract';
import { GraySmall } from 'src/theme/colors';
import MemoBaseTypography from 'src/legacy/components/Text/BaseTypography';
import { getFieldTypeUsingComponentType } from 'src/utils/ContractUtils';
import { ensureUnreachable } from 'src/utils/common_utils';
import { ContractTemplateForm } from '../ContractBuilderAndSignerForm';
import { toCamelCase } from 'src/utils/StringUtils';
import { CustomFieldOption } from 'src/store/clients/types';
import { MultiSelectOption } from 'src/legacy/components/Select/types';
import { TruncatedText } from 'src/legacy/components/UI';

const CUSTOM_CLIENT_INPUT_TOOLTIP_TEXT = `These values are dynamically updated based on the selected recipient.`;

export const getSelectedRecipientAutoFieldValue = ({
  key,
  recipient,
  recipientCompany,
  customFieldsMap,
}: {
  key: string | PredefinedAutofillKeys;
  recipient?: User;
  recipientCompany?: Company;
  customFieldsMap: Record<string, CustomFieldOption<MultiSelectOption>>;
}) => {
  const clientInfoFields = recipient?.fields;

  if (!clientInfoFields || !key) return null;

  if (isContractPredefinedKey(key)) {
    switch (key) {
      case PredefinedAutofillKeys.FirstName:
        return clientInfoFields.givenName;
      case PredefinedAutofillKeys.FullName:
        return `${clientInfoFields.givenName} ${clientInfoFields.familyName}`;
      case PredefinedAutofillKeys.LastName:
        return clientInfoFields.familyName;
      case PredefinedAutofillKeys.Email:
        return clientInfoFields.email;
      case PredefinedAutofillKeys.ShareDate: {
        return moment().format(DEFAULT_DATE_FORMAT);
      }
      case PredefinedAutofillKeys.Company: {
        return recipientCompany?.fields?.name || null;
      }
      default:
        return ensureUnreachable(key);
    }
  }

  const customField = customFieldsMap[key];

  if (!customField || !clientInfoFields?.customFields) return null;

  // Handle the case when the custom field type is 'multiSelect'.
  // Here we will separate multiple options using ","
  if (customField.type === 'multiSelect' && customField?.options?.length) {
    const tagOptions = customField.options;
    const tags = clientInfoFields.customFields[customField.id];
    const tagLabels: string[] = [];
    // For clients without multi-select custom fields value assigned, tags will be null, so we need to return early.
    if (!tagOptions || !tags) return '';
    tags.forEach((tag: string) => {
      const tagOption = tagOptions.find((i) => i.id === tag);
      if (tagOption) tagLabels.push(tagOption.label);
    });
    return tagLabels.join(', ');
  }

  return clientInfoFields.customFields[customField.id];
};

const useStyles = makeStyles((theme: Theme) => ({
  customInputSignatureButton: {
    maxWidth: CONTRACT_COMPONENT_DEFAULT_WIDTH,
    flex: 1,
    '& svg': {
      height: 16,
    },
    '& .MuiButton-label div': {
      maxWidth: 120,
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    },
  },
  inputTitle: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1),
    marginBottom: theme.spacing(1.5),
  },
  tooltipIcon: {
    height: 12,
    width: 12,
    color: GraySmall,
  },
  flexContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: theme.spacing(1),
  },
}));

const isContractPredefinedKey = (key: string): key is PredefinedAutofillKeys =>
  Object.values(PredefinedAutofillKeys).includes(key as PredefinedAutofillKeys);

export const SelectedRecipientInfoInputs = () => {
  const dispatch = useDispatch();
  const { values: formikValues } = useFormikContext<ContractTemplateForm>();
  const clients = useSelector(
    (state: RootState) => state.clients.activeClients,
  );
  const companies = useSelector(
    (state: RootState) => state.clients.activeCompanies,
  );
  const { data: portalConfigEntity } = useGetPortalConfigQuery();

  const classes = useStyles();
  const customFieldsMap = useSelector(
    (state: RootState) =>
      state.clients?.clientCustomFields?.additionalFields || {},
  );

  const selectedRecipientId = formikValues?.recipientId || '';

  /**
   * Handles the component drop action.
   * @param {ComponentCoordinatesType} coords - The coordinates where the component is dropped.
   * @param {SignatureComponentType} componentType - The type of the dropped component.
   * @param {string} value - Actual value containing recipient info
   */
  const handleComponentDropped = (
    coords: ComponentCoordinatesType,
    componentType: SignatureComponentType,
    value: string,
    autofillKey: string,
  ) => {
    const key = v4();
    const stagedComponent = {
      componentType,
      inputType: InputType.AutoFill,
      fieldType: getFieldTypeUsingComponentType(componentType),
      key,
      fields: { key, value },
      ...coords,
      height: CONTRACT_COMPONENT_DEFAULT_HEIGHT,
      width: CONTRACT_COMPONENT_DEFAULT_WIDTH,
      autoFillField: autofillKey,
    };

    dispatch(AddComponentAction(stagedComponent));
    dispatch(SetSelectedComponent(stagedComponent.fields.key));
  };

  const getClientCustomInputValue = (key: string) => {
    return getSelectedRecipientAutoFieldValue({
      key,
      recipient: clients.find((c) => c.id === selectedRecipientId),
      recipientCompany: companies.find((c) => c.id === selectedRecipientId),
      customFieldsMap,
    });
  };

  const clientCustomInputs = useMemo(() => {
    const defaultFields = [
      {
        type: 'text',
        id: PredefinedAutofillKeys.FullName,
        value: getClientCustomInputValue(PredefinedAutofillKeys.FullName),
        title: 'Full name',
        label: `{{client.${PredefinedAutofillKeys.FullName}}}`,
      },
      {
        type: 'text',
        id: PredefinedAutofillKeys.FirstName,
        value: getClientCustomInputValue(PredefinedAutofillKeys.FirstName),
        title: 'First name',
        label: `{{client.${PredefinedAutofillKeys.FirstName}}}`,
      },
      {
        type: 'text',
        id: PredefinedAutofillKeys.LastName,
        value: getClientCustomInputValue(PredefinedAutofillKeys.LastName),
        title: 'Last name',
        label: `{{client.${PredefinedAutofillKeys.LastName}}}`,
      },
      {
        type: 'email',
        id: PredefinedAutofillKeys.Email,
        value: getClientCustomInputValue(PredefinedAutofillKeys.Email),
        title: 'Email',
        label: `{{client.${PredefinedAutofillKeys.Email}}}`,
      },
      ...(!portalConfigEntity?.structFields?.disableCompanies
        ? [
            {
              type: 'text',
              id: PredefinedAutofillKeys.Company,
              value: getClientCustomInputValue(PredefinedAutofillKeys.Company),
              title: 'Company',
              label: `{{client.${PredefinedAutofillKeys.Company}}}`,
            },
          ]
        : []),
    ];

    // Using client's custom properties list to list autofill inputs
    const customFields = Object.values(customFieldsMap).map((f) => ({
      type: f.type,
      id: f.id,
      value: getClientCustomInputValue(f.id),
      title: f.name,
      label: `{{client.${toCamelCase(f.name)}}}`,
    }));

    const allFields = [...defaultFields, ...customFields];
    if (!selectedRecipientId) {
      return allFields;
    }

    return allFields.filter((c) => c.value);
  }, [customFieldsMap, portalConfigEntity, selectedRecipientId]);

  return (
    <>
      <div className={classes.inputTitle}>
        <MemoBaseTypography fontType="12Medium">
          Autofill fields
        </MemoBaseTypography>
        <Tooltip title={CUSTOM_CLIENT_INPUT_TOOLTIP_TEXT}>
          <div>
            <InformationCircleIcon className={classes.tooltipIcon} />
          </div>
        </Tooltip>
      </div>
      <Grid container spacing={2}>
        {clientCustomInputs.map(({ label, type, id, title, value }) => (
          <Grid item xs={12} key={id}>
            <div className={classes.flexContainer}>
              <SignatureSidebarButton
                className={classes.customInputSignatureButton}
                label={selectedRecipientId ? value : label}
                onDrop={(coordinates) => {
                  handleComponentDropped(
                    coordinates,
                    SignatureComponentType.OWNER_TEXT,
                    value,
                    id,
                  );
                }}
                buttonIcon={
                  id === PredefinedAutofillKeys.Company
                    ? NoCompanyIcon
                    : MapFieldTypeToDefaultProps[type].icon
                }
                disabled={!selectedRecipientId}
              />

              <TruncatedText maxChars={12} text={title} />
            </div>
          </Grid>
        ))}
        <Grid item xs={12}>
          <div className={classes.flexContainer}>
            <SignatureSidebarButton
              label={moment().format(DEFAULT_DATE_FORMAT)}
              onDrop={(coordinates) => {
                handleComponentDropped(
                  coordinates,
                  SignatureComponentType.OWNER_DATE,
                  moment().format(DEFAULT_DATE_FORMAT),
                  PredefinedAutofillKeys.ShareDate,
                );
              }}
              buttonIcon={SignatureDateIcon}
              disabled={!selectedRecipientId}
            />
            <MemoBaseTypography>Share date</MemoBaseTypography>
          </div>
        </Grid>
      </Grid>
    </>
  );
};
