import React, { useContext } from 'react';
import { Link, createStyles, makeStyles, Theme } from '@material-ui/core';
import classNames from 'classnames';
import { GraySmall } from 'src/theme/colors';
import BaseTypography from 'src/legacy/components/Text/BaseTypography';
import { PRIVACY_POLICY_LINK, TOS_LINK } from 'src/constants';
import { FlagsContext } from 'src/context';
import { AUTH_STATES } from 'src/constants/authConsts';
import { AuthLinkActionButton } from 'src/legacy/components/Auth/AuthLinkActionButton';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    loginActionsWrapper: {
      marginTop: theme.spacing(3),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      gap: theme.spacing(1),
    },
    actionsWrapper: {
      marginTop: theme.spacing(5),
      display: 'flex',
      justifyContent: 'center',
    },
    centerWrapper: {
      margin: 'auto',
      maxWidth: '340px',
    },
    link: {
      color: GraySmall,
      fontSize: 14,
      fontWeight: 500,
    },
    note: {
      color: GraySmall,
    },
    alreadyHaveAccountLink: {
      marginTop: theme.spacing(2.5),
      color: GraySmall,
    },
    alreadyHaveAccountLinkSpacing: {
      marginTop: theme.spacing(1),
    },
    loginWrapperSpacing: {
      marginTop: theme.spacing(2.5),
    },
    actionWrapperSpacing: {
      marginTop: 53,
    },
  }),
);
interface OnboardingLoginFormFooterActionsProps {
  showOnboardingLoginActions: boolean;
  hidePolicy?: boolean;
}
export const OnboardingLoginFormFooterActions: React.FC<
  OnboardingLoginFormFooterActionsProps
> = ({ showOnboardingLoginActions, hidePolicy = false }) => {
  const classes = useStyles();
  const { GoogleLoginForInternalUser } = useContext(FlagsContext);
  return (
    <>
      {showOnboardingLoginActions && (
        <div
          className={classNames(classes.loginActionsWrapper, {
            [classes.loginWrapperSpacing]: GoogleLoginForInternalUser,
          })}
        >
          {GoogleLoginForInternalUser && (
            <AuthLinkActionButton
              href={`?step=${AUTH_STATES.FORGOT_PASSWORD}`}
              linkText="Forgot password?"
            />
          )}
          <AuthLinkActionButton
            href="/signup"
            linkText={
              GoogleLoginForInternalUser
                ? `Don't have an account?`
                : 'New to Copilot?'
            }
          />
        </div>
      )}
      {!showOnboardingLoginActions && (
        <div
          className={classNames(classes.actionsWrapper, {
            [classes.actionWrapperSpacing]: GoogleLoginForInternalUser,
          })}
        >
          <div className={classes.centerWrapper}>
            {!hidePolicy && (
              <BaseTypography
                fontType="13Regular"
                align="center"
                className={classes.note}
              >
                You acknowledge that you read, and agree to our{' '}
                <Link href={TOS_LINK} target="_blank" className={classes.link}>
                  Terms of Service
                </Link>{' '}
                and our{' '}
                <Link
                  href={PRIVACY_POLICY_LINK}
                  target="_blank"
                  className={classes.link}
                >
                  Privacy Policy
                </Link>
                .
              </BaseTypography>
            )}
            <BaseTypography
              fontType="13Regular"
              align="center"
              className={classNames(classes.alreadyHaveAccountLink, {
                [classes.alreadyHaveAccountLinkSpacing]:
                  GoogleLoginForInternalUser,
              })}
            >
              <Link href="/login" target="_self">
                Already have an account?
              </Link>
            </BaseTypography>
          </div>
        </div>
      )}
    </>
  );
};
