import { useMediaQuery, useTheme } from '@material-ui/core';
import { Auth } from 'aws-amplify';
import React, { useContext } from 'react';
import UsersClient from 'src/clients/UsersClient';
import { LoadingWrapper } from 'src/legacy/components/Loading';
import { OnboardingAuthLayout } from 'src/legacy/components/Onboarding/Login/OnboardingAuthLayout';
import { OnboardingWorkspaceSelect } from 'src/legacy/components/Onboarding/Login/OnboardingWorkspaceSelect';
import { OnboardingWorkspaceSelectV2 } from 'src/legacy/components/Onboarding/Login/OnboardingWorkspaceSelect.v2';
import {
  AuthCompleteInput,
  AuthenticatedPortalDataType,
} from 'src/legacy/components/Onboarding/Login/onboardingLoginTypes';
import { OnboardingPageLayout } from 'src/legacy/components/Onboarding/OnboardingPageLayout';
import { REDIRECT_PATH_AFTER_GOOGLE_LOGIN } from 'src/constants/authConsts';
import { FlagsContext, PortalConfigContext, RouteContext } from 'src/context';
import { useAppDispatch, useAppSelector } from 'src/hooks/useStore';
import { useWorkspaces } from 'src/hooks/useWorkspaces';
import { setProcessingCallback } from 'src/store/auth/slice';
import { logoutFromHostedUi } from 'src/utils/AuthUtils';
import { CognitoUserWithAttributes } from 'src/utils/CognitoUtils';
import { removeSpaceToLowerCase } from 'src/utils/StringUtils';

export interface queryParams {
  next?: string;
  step?: string;
  email?: string;
  poolId?: string;
  auth?: string; // one time password used when activating user
  action?: string;
}

/**
 * When this component is loaded we need to decide whether to show the login page or the workspace select page.
 * The way we decide is by checking the portal-sessions cookie passed in the window
 *
 * We need to show a login page if all sessions are expired or if there are no sessions. In this case a user needs to login.
 * and get a new session. If there is a session that is not expired we can go to the next step
 *
 * Once we have a session that is not expired we can make an api call to /GET /portals/...
 * If there is only one workspace we do not need to show the workspace select component
 * and we can directly go to /portal/portalId. Otherwise we need to show the workspace select component where the user selects
 * a workspace and then we go to /portal/portalId
 *
 * @returns renders the login to portal page and subsequent select workspace component
 */
export const OnboardingLoginPage = () => {
  const { GoogleLoginForInternalUser } = useContext(FlagsContext);
  const theme = useTheme();
  const isNonMobileWindow = useMediaQuery(theme.breakpoints.up('sm'));
  const [authenticatedPortalsData, setAuthenticatedPortalsData] =
    React.useState<AuthCompleteInput | null>(null);
  const portalConfig = React.useContext(PortalConfigContext);
  const { query }: { query: queryParams } = React.useContext(RouteContext);
  const { getWorkspaces } = useWorkspaces();

  // after auth is complete, we load workspaces
  // this is used to track the api call as well as the
  // computation to determine the next step
  // this is used to show a progress indicator meanwhile
  const [isProcessingWorkspace, setProcessingWorkspace] = React.useState(false);

  const { googleSignedIn, googleSignInProgress } = useAppSelector((state) => ({
    googleSignInError: state.auth.error,
    googleSignInProgress: state.auth.processingCallback,
    googleSignedIn: state.auth.signedIn,
  }));

  const dispatch = useAppDispatch();

  const OnboardingWorkspaceComponent = GoogleLoginForInternalUser
    ? OnboardingWorkspaceSelectV2
    : OnboardingWorkspaceSelect;

  const nextPathForGoogleLogin = React.useCallback(() => {
    if (typeof window === 'undefined') {
      return;
    }

    // for google login, the next path is stored in the session storage
    const googleRedirectPath = window.sessionStorage.getItem(
      REDIRECT_PATH_AFTER_GOOGLE_LOGIN,
    );

    if (googleRedirectPath) {
      // if a next param is set in the storage and not in the url
      // then we update the url to set the next param
      const pageParams = new URLSearchParams(window.location.search);
      if (!pageParams.has('next')) {
        pageParams.set('next', googleRedirectPath);

        // this is used so a page refresh is not done by the browser
        window.history.replaceState(
          null,
          '',
          `${window.location.pathname}?${pageParams}`,
        );
      }

      window.sessionStorage.removeItem(REDIRECT_PATH_AFTER_GOOGLE_LOGIN);
    }
  }, []);

  /**
   * When login is complete and a workspace is chosen this function will
   * go to the selected workspace. There are two cases:
   * 1. The user is logging in with new credentials and we need to create a new session
   * 2. The user is selecting a portal that they had previously logged into and we can use the existing session
   * @param email user email
   * @param password user password
   * @param portalData authenticated portal data
   */
  const handleWorkspaceSelected = async (
    portalData: AuthenticatedPortalDataType,
  ) => {
    if (!authenticatedPortalsData?.authSession) {
      return;
    }

    const { authSession, authenticatedPortals } = authenticatedPortalsData;
    let existingAuthSession: AuthenticatedPortalDataType | undefined;
    // check if the selected workspaces is already in an existing session
    // AND the email used at login is different than the email in the existing session.
    // If the email is the same then we should continue with creating a new session to
    // replace and expired session information
    Object.entries(authenticatedPortals).forEach(
      ([email, authPortalsByEmail]) =>
        Object.values(authPortalsByEmail).forEach((authPortal) => {
          if (authPortal.id === portalData.id && authSession.email !== email) {
            // select workspace found with different email than the one used to login
            existingAuthSession = authPortal;
          }
        }),
    );

    const nextPathValue = new URLSearchParams(window.location.search).get(
      'next',
    );
    const nextPath = nextPathValue ? `?next=${nextPathValue}` : '';

    if (existingAuthSession) {
      // if there is an existing auth session we can directly navigate there
      // once portal session is set we will use nextPath for redirection
      window.location.href = `/portal/${existingAuthSession.id}${nextPath}`;
      return;
    }

    try {
      const result = await UsersClient.setWorkspace({
        ...authSession,
        portalId: portalData.id,
        hostname: portalData.hostname,
      });
      if (result.data.redirect && authSession.userId) {
        window.location.href = `${result.data.redirect}${nextPath}`;
      }
    } catch (err) {
      console.error('Error setting workspace', err);
    }
  };
  /**
   * When the onboarding login page is mounted,
   * this function is called to decide whether
   * to show the workspace select component or the login component.
   */
  const loadWorkspaces = async () => {
    if (typeof window === 'undefined') {
      return;
    }

    try {
      setProcessingWorkspace(true);
      const { portalIdToSession, workspaces: workspacesResponse } =
        await getWorkspaces({ querySession: true });
      if (Object.keys(portalIdToSession).length === 0) {
        setAuthenticatedPortalsData(null);
        return;
      }

      const {
        // workspace_login is a specific session type that is used for the workspace login flow
        workspace_login: workspaceLoginSession,
        ...portalSessions
      } = portalIdToSession;

      const nextPath = new URLSearchParams(window.location.search).get('next');

      // if we have portal id avialable in query
      // redirect user to the portal
      if (nextPath && query.email) {
        // checking if the query next url redirects to a portal
        if (nextPath.includes('/portal/')) {
          // get portal id from query next
          const [nextPathParts] = nextPath.split('?'); // remove query params to extract portal id;
          const portalId = nextPathParts.replace('/portal/', '');

          const portalData = workspacesResponse[query.email][portalId];
          try {
            await UsersClient.setWorkspace({
              ...workspaceLoginSession,
              portalId: portalData.id,
              hostname: portalData.hostname,
            });

            window.location.replace(nextPath);
            return;
          } catch (err) {
            console.error('Error setting workspace', err);
          }
        }
      }

      // when there is only email in the workspace response map
      if (Object.keys(workspacesResponse).length === 1) {
        const [email] = Object.keys(workspacesResponse);
        // and only one auth portal data object inside that email object
        // we can directly go to the portal
        if (Object.keys(workspacesResponse[email]).length === 1) {
          const [portalData] = Object.values(workspacesResponse[email]);
          const portalId = portalData.id;
          const defaultPortalSessionData = portalSessions[portalId];

          // if there is only one portal workspace found then by default the userId
          // associated with the portal is the same as the one used to login
          const { userId } = workspaceLoginSession;
          if (!userId) {
            throw new Error('User Id is undefined');
          }

          window.localStorage.setItem(
            `CognitoIdentityServiceProvider.${portalConfig.AWS.Auth.userPoolWebClientId}.LastAuthUser`,
            userId,
          );
          const session = await Auth.currentSession();
          const accessToken = session.getAccessToken().getJwtToken();

          await UsersClient.setUserSession({
            ...defaultPortalSessionData,
            portalId,
            accessToken,
          });

          // set the next query param so that server-side can redirect to the correct page
          // if there is an authorized session for the portal
          window.location.replace(`/portal/${portalId}?next=${nextPath}`);
          return;
        }
      }

      setAuthenticatedPortalsData({
        authSession: workspaceLoginSession,
        authenticatedPortals: workspacesResponse,
      });
      setProcessingWorkspace(false);
    } catch (error) {
      setProcessingWorkspace(false);
      console.error('Error while setting workspaces', error);
    }
  };

  const handleAuthComplete = () => {
    // save authenticated portal in local storage
    // so that we can list them in the workspace dropdown menu
    loadWorkspaces();
  };

  const completeGoogleLogin = async () => {
    try {
      let user: CognitoUserWithAttributes | undefined;
      try {
        user = await Auth.currentAuthenticatedUser();
      } catch (error) {
        console.error('failed to get the current authenticated user', error);
        // this could happen due to some unknown race condition with amplify
        // a reload usually solves the issue
        window.location.reload();
        return;
      }

      if (!user) {
        // There have been sentry errors where `user` is undefined. This safeguards against that.
        // Let's reload the page to see if that fixes the issue, per the pattern established above.
        window.location.reload();
        return;
      }

      // if the email query param is set, we want to confirm the login against a valid user
      const emailInvitedFrom = query.email;
      if (emailInvitedFrom && emailInvitedFrom !== user.attributes.email) {
        // if the emails don't match, this is not a valid email
        // so we don't want to continue the process and show a error message
        //
        // also if there is a mismatch here, we want to make sure the user can select a different
        // google account when trying again
        // so, we log this user account out this will redirect the user to the login page
        logoutFromHostedUi(
          portalConfig,
          `${window.location.origin}/login?app-error=invite_login_mismatch`,
        );
      }

      // set cookies for workspaces
      const email = removeSpaceToLowerCase(user.attributes.email);

      await UsersClient.setAppUserCookie(
        user,
        'workspace_login',
        'workspaces',
        email,
      );

      window.localStorage.setItem(
        `CognitoIdentityServiceProvider.${portalConfig.AWS.Auth.userPoolWebClientId}.LastAuthUser`,
        user.getUsername(),
      );

      // complete the auth flow
      dispatch(setProcessingCallback(false));
    } catch (err) {
      dispatch(setProcessingCallback(false));
      console.error(err);
      throw err;
    }
  };

  React.useEffect(() => {
    // Look up the user and complete the authentication flow, if the user has been signed in.
    // this is done when
    // - when the event for sign is triggerred and googleSignInProgress is set.
    //   googleSignInProgress is set when it first dispatches the processingCallback event.
    // - we want to confirm a login after signup, this is done after a signup with google.
    //   This is done so that the token can be refreshed with the permissions for the created protal
    //   and the user from db
    if (
      (googleSignedIn && googleSignInProgress) ||
      query.action === 'confirm-login'
    ) {
      nextPathForGoogleLogin();

      // query params have been read, we clear them out
      if (query.action === 'confirm-login') {
        window.history.replaceState(null, '', window.location.pathname);
      }

      completeGoogleLogin()
        .then(() => handleAuthComplete())
        .catch((err) => console.error('google login failed', err));
    }
  }, [googleSignedIn, googleSignInProgress, query]);

  // on ssr the window location is not available
  // at this point the query params won't be available
  // these query params are used to show the loading state
  // when they are undefined the loading state wont be shown
  // and that would cause a flicker on the page.
  // this workaround will prevent rendering the component
  // until the query params are available.
  // TODO: The right solution is to use the route context
  // which holds the query params even on ssr.
  if (typeof window === 'undefined') return null;

  // if the code and state query params are set in the url, then we are
  // receiving a auth callback from google and the loading indicator
  // should be present
  // this is not done via route context since it does not update the query params when amplify clears it
  const queryParams = new URLSearchParams(window.location.search);

  const isGoogleRedirect = queryParams.has('code') && queryParams.has('state');

  const isLoading =
    googleSignInProgress ||
    isProcessingWorkspace ||
    queryParams.get('action') === 'confirm-login' ||
    isGoogleRedirect;

  return (
    <OnboardingPageLayout data-test-i="internal-login-page">
      <LoadingWrapper
        hideContentWhileLoading
        isLoading={isLoading}
        {...(GoogleLoginForInternalUser && isNonMobileWindow
          ? { color: 'white' }
          : {})}
      >
        {!authenticatedPortalsData ? (
          <OnboardingAuthLayout onAuthComplete={handleAuthComplete} />
        ) : (
          <OnboardingWorkspaceComponent
            onWorkspaceSelected={handleWorkspaceSelected}
            authenticatedPortals={authenticatedPortalsData.authenticatedPortals}
          />
        )}
      </LoadingWrapper>
    </OnboardingPageLayout>
  );
};
