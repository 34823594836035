import React from 'react';
import { UserCellRendererProps } from 'src/legacy/components/AgGrid/Renderer/types';
import { Avatar } from 'copilot-design-system';

export const UserCellRenderer: React.FC<UserCellRendererProps> = React.memo(
  ({ data, value, useCellData = false, fieldKeys = {} }) => {
    const userData = useCellData ? data : value;
    const {
      nameField,
      avatarField,
      firstNameField,
      lastNameField,
      hideCellField,
    } = fieldKeys;

    const hideUserCell = hideCellField && data[hideCellField];

    const name = React.useMemo(() => {
      if (nameField) {
        return userData[nameField];
      }

      if (firstNameField && lastNameField) {
        return `${userData[firstNameField]} ${userData[lastNameField]}`;
      }

      return `${userData.firstName} ${userData.lastName}`;
    }, [userData, nameField, firstNameField, lastNameField]);

    if (hideUserCell) return null;

    return (
      <div className="flex gap-3 items-center">
        <Avatar
          src={avatarField ? userData[avatarField] : userData.avatarUrl}
          fallbackColor={userData.fallbackColor}
          variant="circle"
          text={name.charAt(0).toUpperCase()}
          size="md"
        />
        <div className="flex flex-col gap-1">
          <span className="text-[12px] text-primary">{name}</span>
          <span className="text-[12px] text-secondary">{userData.email}</span>
        </div>
      </div>
    );
  },
);
