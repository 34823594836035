import React, { FC, useContext, useState } from 'react';
import { Auth, I18n } from 'aws-amplify';
import { Box, Theme, Typography, makeStyles } from '@material-ui/core';
import { Formik } from 'formik';
import * as Yup from 'yup';
import clsx from 'clsx';
import Button from 'src/legacy/components/Button';
import { AUTH_STATES } from 'src/constants/authConsts';
import { authScreenStyles } from 'src/legacy/components/Auth/styles';
import { removeSpaceFromString } from 'src/utils/StringUtils';
import AuthContainer from 'src/legacy/components/Auth/AuthContainer';
import { BaseTextField } from 'src/legacy/components/TextField';
import RowDivider from 'src/legacy/components/RowDivider';
import { AuthLinkActionButton } from 'src/legacy/components/Auth/AuthLinkActionButton';
import { IAuthenticatorReturn } from 'src/legacy/components/AwsAmplify';
import { FlagsContext } from 'src/context';
import { ClientAuthContainer } from 'src/legacy/components/Auth/ClientAuthContainer';

const useStyles = makeStyles((theme: Theme) => ({
  ...authScreenStyles(theme),
}));

const schema = Yup.object().shape({
  username: Yup.string().max(64).required('Account is required'),
  code: Yup.string().max(64).required('Code is required'),
});

const validAuthStates = ['confirmSignIn'];

const ConfirmRegisterForm: FC<IAuthenticatorReturn> = (props) => {
  const {
    authData,
    onStateChange,
    handleInputChange,
    inputs,
    hide,
    authState,
  } = props;

  const classes = useStyles();

  const [isConfirmSignUpLoading, setIsConfirmSignUpLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(
    'One of the fields filled incorrectly.',
  );

  React.useEffect(() => onStateChange(AUTH_STATES.CONFIRM_SIGN_UP), []);

  // fetching username from auth data
  const usernameFromAuthData = () => {
    if (!authData) {
      return '';
    }

    let username = '';
    if (typeof authData === 'object') {
      // user object
      username = authData?.user ? authData?.user?.username : authData?.username;
    } else {
      username = authData; // username string
    }

    return username;
  };

  const resend = () => {
    const username = usernameFromAuthData() || inputs?.username;
    if (!Auth || typeof Auth.resendSignUp !== 'function') {
      throw new Error(
        'No Auth module found, please ensure @aws-amplify/auth is imported',
      );
    }
    if (username)
      Auth.resendSignUp(username).catch((err) => setErrorMessage(err.message));
  };

  const usernameFromAuth = usernameFromAuthData();

  if (!validAuthStates.includes(authState)) return null;

  if (hide && hide.includes(AUTH_STATES.CONFIRM_SIGN_UP)) {
    return null;
  }

  const { GoogleLoginForClients } = useContext(FlagsContext);
  const Container = GoogleLoginForClients ? ClientAuthContainer : AuthContainer;

  return (
    <Container title="Confirm Register">
      <Formik
        initialValues={{
          username: usernameFromAuth || authData?.username,
          code: '',
        }}
        validationSchema={schema}
        onSubmit={async (values, { setStatus, setErrors }) => {
          try {
            setIsConfirmSignUpLoading(true);
            const username =
              usernameFromAuth || authData?.username || values.username;

            await Auth.confirmSignUp(username, values.code);
            onStateChange(AUTH_STATES.SIGNED_UP, {
              username: authData.username,
              password: authData.password,
            });
            setIsConfirmSignUpLoading(false);
          } catch (error) {
            setStatus({ success: false });
            if (error instanceof Error) {
              setErrors({
                username: error && error.message ? error.message : errorMessage,
                code: error && error.message ? error.message : errorMessage,
              });
            }
            setIsConfirmSignUpLoading(false);
          }
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          touched,
          values,
        }) => {
          const isSendEnabled =
            (usernameFromAuth || (values.username && !errors.username)) &&
            values.code &&
            !errors.code;

          return (
            <form noValidate onSubmit={handleSubmit}>
              <Box className={classes.fields} mt={10}>
                {usernameFromAuth ? (
                  <Box mb={2}>
                    <Typography variant="body1">
                      Enter the verification code sent to your email
                    </Typography>
                  </Box>
                ) : (
                  <BaseTextField
                    fullWidth
                    type="text"
                    key="username"
                    sizeVariant={GoogleLoginForClients ? 'tall' : 'medium'}
                    name="username"
                    variant="outlined"
                    onBlur={handleBlur}
                    onChange={handleInputChange}
                    onInput={handleChange}
                    value={removeSpaceFromString(values.username ?? '')}
                    label={I18n.get('Username')}
                    error={Boolean(touched.username && errors.username)}
                    helperText={(touched.username && errors.username) || ' '}
                    autoComplete="off"
                  />
                )}
                <BaseTextField
                  fullWidth
                  type="text"
                  sizeVariant={GoogleLoginForClients ? 'tall' : 'medium'}
                  key="code"
                  name="code"
                  variant="outlined"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  onInput={handleChange}
                  value={values.code}
                  label={I18n.get('Code')}
                  error={Boolean(touched.code && errors.code)}
                  helperText={(touched.code && errors.code) || ' '}
                  autoComplete="off"
                />
              </Box>
              <Box display="flex" justifyContent="center" mt={2}>
                <Button
                  htmlId="cofirm-button"
                  type="submit"
                  color="primary"
                  variant="contained"
                  className={clsx({
                    [classes.submitButton]: GoogleLoginForClients,
                  })}
                  isLoading={isConfirmSignUpLoading}
                  disabled={!isSendEnabled}
                >
                  {I18n.get('Confirm')}
                </Button>
              </Box>
              <AuthLinkActionButton
                linkText="Resend code"
                className={classes.navigationActionsContainerMobile}
                onClick={resend}
              />
            </form>
          );
        }}
      </Formik>
      <RowDivider className={classes.divider} mt={2.5} mb={1.5} />
      <AuthLinkActionButton
        linkText="Resend code"
        className={classes.navigationActionsContainerDesktop}
        onClick={resend}
      />
    </Container>
  );
};

export default ConfirmRegisterForm;
