import { BaseEntity } from 'src/constants';
import { CheckboxCheckedIcon } from 'src/legacy/components/Icons';
import {
  LongAnswerIcon,
  ShortAnswerIcon,
  RadioIcon,
  UploadIcon,
  TitleIcon,
} from 'src/legacy/components/Icons/QuestionTypes';
import { SvgIconProps } from '@material-ui/core';
import { Icon, IconType } from 'copilot-design-system';
import React from 'react';

// This value is only used to identify other option during processing and is not saved any where
export const OtherOptionKeyword = '_other_option_';

export const FormQuestionRequiredValidationMessage = 'Input is required';

export enum FormVisibility {
  AllClients = 'allClients',
  RequestedClients = 'requestedClients',
}

export enum FormResponseStatus {
  Pending = 'pending',
  Open = 'open',
  Completed = 'completed',
}

export type OptionsInfo = {
  value: string;
  isOther: boolean;
};

export interface QuestionAnswer extends QuestionType {
  responseValue?: string | string[] | number;
  otherOptionValue?: string;
  questionId: string;
  isQuestionEdited?: boolean;
  answer?: string | string[];
  options?: OptionsInfo[]; // for multiSelect and singleSelect options with other option
}

export type QuestionAnswerWithIdentityId = QuestionAnswer & {
  identityId: string;
};

export interface FormResponse extends BaseEntity {
  id: string;
  object: string;
  ref?: string;
  data: string;
  creatorId?: string;
  createdAt: string;
  updatedAt?: string;
  formId: string;
  clientId: string;
  fields: FormResponseFields;
  filteredListIndexPkey?: string;
  identityId: string;
  submissionDate?: string;
}

export interface FormResponseFields {
  formName: string;
  formDescription: string;
  formFieldIds: string[];
  formFields: { [key: string]: FormResponseQuestion };
  status: string;
  allowMultipleSubmissions: boolean;
  visibility: FormVisibility;
  submissionDate?: string;
}

export interface FormResponseQuestion {
  title: string;
  description?: string;
  type: InputsTypes;
  multipleChoiceOptions?: string[];
  isRequired: boolean;
  answer?: string | string[];
  hasOtherOption?: boolean;
}

export interface FormFields {
  formFieldIds: string[];
  name: string;
  description: string;
  allowMultipleSubmissions: boolean;
  visibility: string; // requestedClients | allClients
  formFields: QuestionType[];
}

export interface FormTemplate extends BaseEntity {
  id: string;
  object: string;
  ref?: string;
  data: string;
  creatorId?: string;
  createdAt: Date;
  updatedAt?: Date;
  additionalFields: {
    formResponseRequests: number;
    formResponseSubmissions: number;
    latestSubmissionDate?: string;
  };
  fields: FormFields;
  identityId: string;
}

export enum InputsTypes {
  ShortAnswer = 'shortAnswer',
  LongAnswer = 'longAnswer',
  MultiSelect = 'multiSelect',
  FileUpload = 'fileUpload',
  SingleSelect = 'singleSelect',
  Title = 'title', // this is not an question input type, it's a card type used to display a questions section title
  PhoneNumber = 'phoneNumber',
  Email = 'email',
  Date = 'date',
  Dropdown = 'dropdown',
}

export enum FormBuilderFormikErrors {
  title = 'Form title is required',
  description = 'Form description is required',
  formVisibility = 'Form visibility is required',
  questions = 'At least add one question',
  questionType = 'Question type is required',
  questionTitle = 'Question title is required',
  titleCard = 'Title is required',
  questionChoice = 'Choice field is required',
  addNonTitleQuestions = 'Add one or more questions to your form before trying to save',
  otherOptionLabel = 'Other option is required',
}

export type QuestionType = {
  formFieldId?: string;
  questionKey?: string;
  type: InputsTypes;
  title: string;
  description?: string;
  multipleChoiceOptions?: string[];
  isRequired: boolean;
  hasOtherOption?: boolean;
};

export type FormConfig = {
  title: string;
  description: string;
  allowMultipleSubmissions: boolean;
  visibility: FormVisibility;
  questions: QuestionType[];
  id?: string;
  name: string;
};

export const formQuestionsDesignSystemIconRenderer = (
  iconElement: IconType,
  className?: string,
): ((props: SvgIconProps) => JSX.Element) => {
  return (props): JSX.Element => {
    return (
      <div className="Form-question-svg-root bg-black border-r-[1px] flex justify-center items-center rounded-[3px]">
        <Icon
          icon={iconElement}
          className={`text-white p-[2px] ${className || ''}`}
          {...props}
        />
      </div>
    );
  };
};

export const QuestionsOptions = [
  {
    title: 'Title card',
    questionLabel: 'Title',
    descriptionLabel: 'Description',
    type: InputsTypes.Title,
    Icon: TitleIcon,
    hasDivider: true,
  },
  {
    title: 'Short answer',
    questionLabel: 'Question title',
    descriptionLabel: 'Question description',
    type: InputsTypes.ShortAnswer,
    Icon: ShortAnswerIcon,
    hasDivider: false,
  },
  {
    title: 'Long answer',
    questionLabel: 'Question title',
    descriptionLabel: 'Question description',
    type: InputsTypes.LongAnswer,
    Icon: LongAnswerIcon,
    hasDivider: true,
  },
  {
    title: 'Multi select',
    questionLabel: 'Question title',
    descriptionLabel: 'Question description',
    type: InputsTypes.MultiSelect,
    Icon: CheckboxCheckedIcon,
    hasDivider: false,
  },
  {
    title: 'Single select',
    questionLabel: 'Question title',
    descriptionLabel: 'Question description',
    type: InputsTypes.SingleSelect,
    Icon: RadioIcon,
    hasDivider: true,
  },
  {
    title: 'File upload',
    questionLabel: 'Question title',
    descriptionLabel: 'Question description',
    type: InputsTypes.FileUpload,
    Icon: UploadIcon,
    hasDivider: false,
  },
  {
    title: 'Date',
    questionLabel: 'Date',
    descriptionLabel: '',
    type: InputsTypes.Date,
    Icon: formQuestionsDesignSystemIconRenderer(
      'Calender',
      'w-[10px] h-[10px]',
    ),
    hasDivider: false,
  },
  {
    title: 'Email',
    questionLabel: 'Email',
    descriptionLabel: '',
    type: InputsTypes.Email,
    Icon: formQuestionsDesignSystemIconRenderer('Email', 'w-[10px] h-[10px]'),
    hasDivider: false,
  },
  {
    title: 'Phone number',
    questionLabel: 'Phone number',
    descriptionLabel: '',
    type: InputsTypes.PhoneNumber,
    Icon: formQuestionsDesignSystemIconRenderer(
      'MobileNumber',
      'w-[10px] h-[10px]',
    ),
    hasDivider: false,
  },
] as const;

export type FormResponseValues = { questionAnswers: QuestionAnswer[] };
export interface FormNotificationResponse {
  succeededIds: Array<string> | null;
  failedIds: Array<string> | null;
  unauthorizedIds: Array<string> | null;
}
