import React, { CSSProperties } from 'react';
import {
  makeStyles,
  createStyles,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import { createSelector } from '@reduxjs/toolkit';
import AutoSizer, { Size } from 'react-virtualized-auto-sizer';
import { FixedSizeList as List, ListChildComponentProps } from 'react-window';
import StandardPage from 'src/legacy/components/UI/StandardPage';
import { RootState } from 'src/store/reduxTypes';
import {
  LinkUrlListWrapper,
  SelectorWrapper,
} from 'src/legacy/components/ListWrappers/LinkUrlListWrapper';
import { ChannelsSidebar } from 'src/legacy/components/Channels/ChannelsSidebar';
import {
  ChannelOptionData,
  ChannelSidebarItem,
} from 'src/legacy/components/Channels/ChannelSidebarItem';
import { ChannelType } from 'src/legacy/components/Channels/useChannelForm';
import { InboxSidebarItemData } from 'src/legacy/components/Inbox';
import { useAppSelector } from 'src/hooks/useStore';

export interface BaseChannelFormData {
  fields: {
    membersType: string;
    memberIDs: string[];
  };
}

export interface ItemRenderProps<T> {
  channelOption: T;
  style?: CSSProperties;
  onSelect: (channelData: T) => void;
}

const useStyles = makeStyles(() =>
  createStyles({
    // the auto sizer container is a child of the drawer paper
    // which is a flex container (see SideDrawer.tsx)
    // When using an AutoSizer as a direct child of a flex box
    // it should be wrapped inside a div with flex: 1 1 auto;
    // Docs link: https://github.com/bvaughn/react-virtualized/blob/master/docs/usingAutoSizer.md#can-i-use-autosizer-within-a-flex-container
    autoSizerContainer: {
      flex: '1 1 auto',
    },
    list: {
      position: 'relative',
      '&::-webkit-scrollbar': {
        display: 'none',
      },
    },
  }),
);

export interface CreateActionModalProps {
  open: boolean;
  onClose: () => void;
}

interface BaseChannelSidebarProps<T> {
  sidebarItemOptions: any;
  standardPageParams?: any;
  onSelectChannel: (sidebarItemOption: any) => void;
  isChannelRootSelected?: (sidebarItemOption: any) => boolean;
  shouldSetQueryParam?: boolean;
  title?: React.ReactNode;
  modalTitle?: string;
  noCreateAction?: boolean;
  // this will help in rendering the list with independent styling
  itemRenderer?: React.FC<ItemRenderProps<T>>;
  // if true disable the default behaviour of selecting the first channel of sidebar
  disableDefaultSelect?: boolean;
  onCreate?: (values: BaseChannelFormData) => Promise<void>;
  initialCreateFormValue?: BaseChannelFormData;
  entity: ChannelType;
  // override the default create modal
  CreateModal?: React.FC<CreateActionModalProps>;
  headerActionsSlot?: React.ReactNode;
  allowKeyboardShortcuts?: boolean;
}
const ITEM_HEIGHT_V2 = 68;

const selector = createSelector(
  [
    (state: RootState) => state.user.isClient,
    (state: RootState) => state.files.selectedChannel?.id,
    (state: RootState) => state.dashboard.selectedItemId,
  ],
  (
    isClient: boolean,
    fileSelectedChannelId: string | undefined,
    selectedExtensionItemId: string,
  ) => ({
    isClient,
    fileSelectedChannelId,
    selectedExtensionItemId,
  }),
);

const BaseChannelSidebar = <
  T extends ChannelOptionData | InboxSidebarItemData,
>({
  onCreate,
  initialCreateFormValue,
  entity,
  sidebarItemOptions,
  standardPageParams,
  onSelectChannel,
  isChannelRootSelected,
  shouldSetQueryParam = true,
  title = null,
  modalTitle = '',
  noCreateAction = false,
  headerActionsSlot,
  itemRenderer: ItemRenderer,
  disableDefaultSelect = false,
  CreateModal,
  allowKeyboardShortcuts = true,
}: BaseChannelSidebarProps<T>) => {
  const classes = useStyles();

  const theme = useTheme();
  const isSmallWidth = useMediaQuery(theme.breakpoints.down('xs'), {
    noSsr: true,
  });

  const { isClient, fileSelectedChannelId, selectedExtensionItemId } =
    useAppSelector(selector);

  const channelSidebarItemRenderer = (props: ListChildComponentProps) => {
    const { index, style, data } = props;
    const { handleItemSelected } = data;
    const channelOption = sidebarItemOptions[index];

    let isSelected = false;
    switch (entity) {
      case 'fileChannel':
        isSelected = fileSelectedChannelId === channelOption.fileChannel.id;
        break;
      default:
        isSelected = selectedExtensionItemId === channelOption.id;
    }

    // check if root channel is selected using callback prop,
    // if no callback prop then root is selected by default
    const isRootSelected = isChannelRootSelected
      ? isChannelRootSelected(channelOption)
      : true;

    // if we have ItemRender component use it to render the list
    // else use the default channel sidebar item
    if (ItemRenderer) {
      return (
        <div style={style as CSSProperties}>
          <ItemRenderer
            channelOption={channelOption}
            onSelect={onSelectChannel}
            key={`${entity}-channel-${index}`}
          />
        </div>
      );
    }

    return (
      <div style={style as CSSProperties}>
        <ChannelSidebarItem
          channelType={entity}
          channelOption={channelOption}
          onSelect={handleItemSelected}
          isSelected={isSelected}
          isRootSelected={isRootSelected}
          key={`${entity}-channel-${index}`}
        />
      </div>
    );
  };

  const sidebarContentRenderer = () => (
    <div className={classes.autoSizerContainer}>
      <AutoSizer>
        {({ height, width }: Size) => (
          <LinkUrlListWrapper
            fallbackIndex={!isSmallWidth ? 0 : null}
            channelType={entity}
            onSelect={onSelectChannel}
            data={sidebarItemOptions}
            shouldSetQueryParam={shouldSetQueryParam}
            disableDefaultSelect={disableDefaultSelect}
            component={(
              onSelectItem: SelectorWrapper,
              refList: React.RefObject<any>,
            ) => (
              <List
                ref={refList}
                className={classes.list}
                height={height}
                width={width}
                itemSize={ITEM_HEIGHT_V2}
                itemCount={sidebarItemOptions.length}
                itemData={{
                  handleItemSelected: onSelectItem,
                }}
              >
                {channelSidebarItemRenderer}
              </List>
            )}
          />
        )}
      </AutoSizer>
    </div>
  );

  const sidebarContent = () => {
    if (sidebarItemOptions.length > 0) {
      return sidebarContentRenderer();
    }
    if (!isClient && isSmallWidth) {
      return <StandardPage {...standardPageParams} />;
    }
    return null;
  };

  return (
    <ChannelsSidebar
      pageTitle={title}
      entity={entity}
      onCreate={onCreate}
      initialCreateFormValue={initialCreateFormValue}
      modalTitle={modalTitle}
      noCreateAction={noCreateAction}
      CreateModal={CreateModal}
      headerActionsSlot={headerActionsSlot}
      allowKeyboardShortcuts={allowKeyboardShortcuts}
    >
      {sidebarContent()}
    </ChannelsSidebar>
  );
};

export default BaseChannelSidebar;
