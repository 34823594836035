import React from 'react';
import { SUPPORT_EMAIL } from 'src/constants/externalUrls';
import {
  Body,
  Heading,
  SecondaryButton,
  Tag,
  TagProps,
} from 'copilot-design-system';

export type AccountVerificationStatus =
  | 'pending'
  | 'failed'
  | 'unverified'
  | 'verified'
  | 'restricted'
  | 'onboarding';
export interface AccountStatusData {
  accountType: string;
  loginUrl: string;
  verificationStatus: AccountVerificationStatus | '';
  accountName: string;
  currency: string;
  accountCountry: string;
  businessName: string;
}
interface VerificationStatusProps {
  statusData: AccountStatusData;
  onConnectExpressClick: () => void;
}

interface VerificationStatusData {
  title: string;
  description: string;
  actionLabel?: string;
  tagColor?: TagProps['color'];
}

const VerificationStatusMapper: Record<
  AccountVerificationStatus,
  VerificationStatusData
> = {
  pending: {
    title: 'Verifying',
    description:
      'Please be patient as your banking information is being verified.',
    tagColor: 'info',
  },
  failed: {
    title: 'Failed to connect',
    description: `Your account could not be connected.`,
    actionLabel: 'Edit',
    tagColor: 'danger',
  },
  verified: {
    title: 'Successfully linked',
    description: `You're all set to bill clients and receive payments. Open your payout dashboard to manage your payment method, check your balance, and view upcoming payouts.`,
    actionLabel: 'Open dashboard',
    tagColor: 'success',
  },
  unverified: {
    title: 'Not verified',
    description:
      'Information that you entered may not be correct or additional verification is required.',
    actionLabel: 'Verify now',
    tagColor: 'warning',
  },
  restricted: {
    title: 'Restricted',
    description: `Your account has been restricted due to a large number of failures when charging or managing payment methods. Please contact ${SUPPORT_EMAIL} for further assistance.`,
    tagColor: 'danger',
  },
  onboarding: {
    title: 'Onboarding',
    description: `Your account is being onboarded. Please complete the onboarding process to start receiving payments.`,
    actionLabel: 'Complete',
    tagColor: 'warning',
  },
};

export const VerificationStatus: React.FC<VerificationStatusProps> = ({
  statusData,
  onConnectExpressClick,
}) => {
  const {
    loginUrl,
    verificationStatus: status,
    accountName,
    currency,
    accountType,
  } = statusData;

  const handleEdit = () => {
    if (!loginUrl) return;
    window.open(loginUrl, '__target');
  };

  const actionLabel = status && VerificationStatusMapper[status].actionLabel;

  return status ? (
    <div>
      <div className="flex gap-2 items-center">
        <Heading variant="lg">
          {`${accountName} (${currency.toUpperCase()})`}
        </Heading>
        <Tag
          color={VerificationStatusMapper[status].tagColor || 'default'}
          text={VerificationStatusMapper[status].title}
        />
      </div>
      <Body variant="sm" className="text-gray-small mt-1">
        {VerificationStatusMapper[status].description}
      </Body>
      <div className="mt-2">
        {accountType === 'standard' && (
          <SecondaryButton
            onClick={onConnectExpressClick}
            label="Connect Express"
          />
        )}
        {actionLabel &&
          loginUrl &&
          (accountType !== 'standard' || status === 'onboarding') && (
            <SecondaryButton onClick={handleEdit} label={actionLabel} />
          )}
      </div>
    </div>
  ) : null;
};
