import React, { useContext, useEffect, useState } from 'react';
import { Button, Theme, makeStyles } from '@material-ui/core';
import { useFormikContext } from 'formik';
import classNames from 'classnames';
import { DropzoneFilesIcon } from '../Icons';
import { GraySmall, red } from 'src/theme/colors';
import MemoBaseTypography from '../Text/BaseTypography';
import { ContractTemplateForm } from './ContractBuilderAndSignerForm';
import { FileDropzone } from '../FileDropzone';
import { FileInputUploadItem } from '../UI/FileUploadInputItem';
import { ContractBuilderContext } from 'src/context/contractBuilderContext';
import {
  ResetESignToInitialValues,
  requestLoadPages,
} from 'src/store/signaturePage/actions';
import { useCreateContractFilesMutation } from 'src/services/api/contractsApi';
import { S3Utils } from 'src/utils';
import { useProgressUpload } from 'src/hooks/useProgressUpload';
import { beautifyFileName } from 'src/utils/StringUtils';
import { RouteContext } from 'src/context';
import { ContractDetailsPageQueryParams } from './ContractDetailsPage';
import { getFileNameFromFileKey } from '../Files/helpers';
import { FileWithPreview } from 'src/types/files';
import { alertSnackbar } from 'src/store/ui/actions';
import { ensureApiError } from 'src/utils/Errors';
import { useAppDispatch } from 'src/hooks/useStore';

const useStyles = makeStyles((theme: Theme) => ({
  dropzone: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    border: '1px dashed #ccc',
    padding: theme.spacing(3.25),
    gap: theme.spacing(2),
    borderRadius: theme.shape.borderRadius,
  },
  errorDropzone: {
    borderColor: red,
  },
  filePreview: {
    boxShadow: 'none',
    maxWidth: 'unset',
  },
  errorText: {
    color: red,
    textAlign: 'right',
  },
}));

interface ContractFileDropzoneProps {
  name: keyof ContractTemplateForm;
}

/**
 * Drag-and-drop input for uploading contract template PDFs.
 * @param {string} name - The name of the formik field associated with this input.
 *
 * @returns {JSX.Element} The JSX element rendering the contract template PDF upload input.
 */
export const ContractFileDropzone = ({ name }: ContractFileDropzoneProps) => {
  const { query } = useContext(RouteContext);
  const { templateId } = query as ContractDetailsPageQueryParams;
  const editTempateFlow = Boolean(templateId);
  const dispatch = useAppDispatch();
  const { contractPdfInfo, setContractPdfInfo } = useContext(
    ContractBuilderContext,
  );
  const classes = useStyles();
  const { setFieldValue, setValues, values } =
    useFormikContext<ContractTemplateForm>();

  const [errorMessage, setErrorMessage] = useState('');

  const [createContractFile] = useCreateContractFilesMutation();

  const { startUploadFiles, setUploadProgress, exitUploadFiles } =
    useProgressUpload();

  const [selectedPdfFile, setSelectedPdfFile] =
    React.useState<FileWithPreview | null>(null);

  // State to track the file size of the S3-uploaded file.
  // This value will be used to display the file size of a PDF during the edit flow.
  // For the create flow, the file size can be obtained from the local file.
  const [s3UploadedFileSize, setS3UploadedFileSize] = useState(0);

  /**
   * Handles the event when one or more files are dropped into the dropzone.
   *
   * @param {FileWithPreview[]} acceptedFiles - An array of accepted files.
   */
  const handleFileDrop = async (acceptedFiles: FileWithPreview[]) => {
    setErrorMessage(''); // clear old error

    // If no accepted files, exit early.
    if (!acceptedFiles.length) {
      setErrorMessage('Contract template must be a PDF.');
      return;
    }

    const file = acceptedFiles.at(0);

    if (!file) return;

    const filePaths = await S3Utils.uploadFilesToS3({
      acceptedFiles,
      targetPath: '',
      startUploadFiles,
      setUploadProgress,
      exitUploadFiles,
      ignoreFolders: false,
    });

    const filePath = filePaths.at(0);
    dispatch(requestLoadPages());
    const response = await createContractFile(filePath.key);
    if ('error' in response) {
      const error = ensureApiError(response.error);
      dispatch(
        alertSnackbar({
          errorMessage:
            error.code === 'unsupported_media_type'
              ? error.message
              : 'Failed to upload the contract file',
        }),
      );
      return;
    }

    setFieldValue(name, file.name);
    setSelectedPdfFile(file);

    // either the .pdf extension is present, or it is not,
    // and in both cases, we want the first element of the split array
    setValues({
      ...values,
      title: values.title?.trim() || beautifyFileName(file.name),
      fileKey: filePath.key,
    });

    setContractPdfInfo({
      fileKey: filePath.key,
      pageFileKeys: response.data.pageKeys,
      identityId: response.data.identityId,
    });
  };

  /**
   * Clears the selected PDF file and resets the form field value.
   */
  const handleRemoveFile = () => {
    setFieldValue(name, '');
    setSelectedPdfFile(null);
    dispatch(ResetESignToInitialValues());
  };

  const hasError = Boolean(errorMessage);

  const getUploadedFileInfo = async () => {
    const listOfFiles = await S3Utils.listFiles(contractPdfInfo.fileKey, {
      identityId: contractPdfInfo.identityId,
      level: 'protected',
    });

    const files = listOfFiles.results;

    // Retrieve the information of the first file in the list (assuming it's a PDF).
    const pdfFile = files.at(0);
    if (pdfFile) {
      setS3UploadedFileSize(pdfFile.size || 0);
    }
  };

  /**
   * Fetch uploaded file information when in edit template flow.
   */
  useEffect(() => {
    if (!editTempateFlow) return;
    if (!contractPdfInfo.fileKey) return;
    getUploadedFileInfo();
  }, [editTempateFlow, contractPdfInfo]);

  if (selectedPdfFile || editTempateFlow) {
    return (
      <FileInputUploadItem
        className={classes.filePreview}
        name={
          selectedPdfFile?.name ||
          getFileNameFromFileKey(contractPdfInfo.fileKey)
        }
        size={selectedPdfFile?.size || s3UploadedFileSize}
        extension="pdf"
        readOnly={editTempateFlow}
        onRemove={handleRemoveFile}
      />
    );
  }

  return (
    <div>
      <MemoBaseTypography fontType="13Medium">
        Upload a .pdf file
      </MemoBaseTypography>
      <FileDropzone
        accept={['application/pdf']}
        onDrop={handleFileDrop}
        disabled={editTempateFlow}
      >
        <div
          className={classNames(classes.dropzone, {
            [classes.errorDropzone]: hasError,
          })}
        >
          <DropzoneFilesIcon
            style={{
              fontSize: 60,
            }}
          />
          <Button variant="contained" color="secondary" size="medium">
            Choose a file
          </Button>

          <MemoBaseTypography
            fontType="12Medium"
            style={{
              color: GraySmall,
            }}
          >
            Or drop a file to upload
          </MemoBaseTypography>
        </div>
      </FileDropzone>
      {hasError && (
        <MemoBaseTypography fontType="13Regular" className={classes.errorText}>
          {errorMessage}
        </MemoBaseTypography>
      )}
    </div>
  );
};
