import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import * as ReduxType from 'src/store/reduxTypes';
import { AlertModal } from 'src/legacy/components/Modals';
import { AlertCircleIcon } from 'src/legacy/components/Icons';
import {
  NO_ACTIVE_PLAN_PROMPT_BODY_ADMIN,
  NO_ACTIVE_PLAN_PROMPT_BODY_STAFF,
  NO_ACTIVE_PLAN_PROMPT_TITLE,
  PERM_MOD_SETTINGS_PLANS,
  PLANS_OPERATIONS,
  PLAN_PAGE,
  TRIAL_PLAN_EXPIRED_PROMPT_BODY_ADMIN,
  TRIAL_PLAN_EXPIRED_PROMPT_BODY_STAFF,
  TRIAL_PLAN_EXPIRED_PROMPT_TITLE,
} from 'src/constants';
import * as UserUtils from 'src/utils/UserUtils';
import { usePlanStatus } from 'src/hooks/usePlanStatusHook';
import { RouteContext } from 'src/context';
import { useSettingsPageRedirection } from 'src/legacy/components/Settings/useSettingsPageRedirection';
import { useCustomerSubscription } from 'src/hooks/useCustomerSubscription';

export const ChoosePlanAlertDialog: React.FC = () => {
  const [planAlertModalData, setPlanAlertModalData] = useState({
    title: '',
    message: '',
  });

  const userLoaded = useSelector(
    (state: ReduxType.RootState) => state.user.loaded,
  );

  const { pathname } = React.useContext(RouteContext);
  const { stripeCustomer } = useCustomerSubscription();
  const { isTrialPeriodExpired, isCancelledPlan, isPaidPlan } = usePlanStatus();

  const permissions = useSelector(
    (state: ReduxType.RootState) => state.user.data?.permissions,
  );

  const enableBillingAction = UserUtils.hasOperation(
    PERM_MOD_SETTINGS_PLANS,
    PLANS_OPERATIONS.UpgradePlan,
    permissions,
  );

  useEffect(() => {
    if (userLoaded) {
      // Hide plan alert modal
      // when stripe customer is not available
      // or settings page is opened
      if (!stripeCustomer || pathname.startsWith('/settings/')) {
        setPlanAlertModalData({
          title: '',
          message: '',
        });
      } else if (isCancelledPlan && !isPaidPlan) {
        // when paid subscription is cancelled because of any reason
        // manual cancellation or non-payment cancellation
        if (enableBillingAction) {
          setPlanAlertModalData({
            title: NO_ACTIVE_PLAN_PROMPT_TITLE,
            message: NO_ACTIVE_PLAN_PROMPT_BODY_ADMIN,
          });
        } else {
          setPlanAlertModalData({
            title: NO_ACTIVE_PLAN_PROMPT_TITLE,
            message: NO_ACTIVE_PLAN_PROMPT_BODY_STAFF,
          });
        }
      } else if (isTrialPeriodExpired && !isPaidPlan) {
        // when trial has expired
        if (enableBillingAction) {
          setPlanAlertModalData({
            title: TRIAL_PLAN_EXPIRED_PROMPT_TITLE,
            message: TRIAL_PLAN_EXPIRED_PROMPT_BODY_ADMIN,
          });
        } else {
          setPlanAlertModalData({
            title: TRIAL_PLAN_EXPIRED_PROMPT_TITLE,
            message: TRIAL_PLAN_EXPIRED_PROMPT_BODY_STAFF,
          });
        }
      }
    }
  }, [
    stripeCustomer,
    userLoaded,
    isTrialPeriodExpired,
    isCancelledPlan,
    isPaidPlan,
    pathname,
  ]);

  const { goToSettings: goToPlanSettingsPage } = useSettingsPageRedirection(
    true,
    PLAN_PAGE.path,
  );

  const handleConfirm = () => {
    goToPlanSettingsPage();
  };
  return (
    <AlertModal
      title={planAlertModalData.title}
      open={Boolean(planAlertModalData.title)}
      onSuccess={enableBillingAction ? handleConfirm : undefined}
      successButtonLabel="Choose a plan"
      Icon={AlertCircleIcon}
    >
      {planAlertModalData.message}
    </AlertModal>
  );
};
