import React, { ComponentProps, useMemo, useState } from 'react';
import { Autocomplete } from '@material-ui/lab';
import { getCountryDataList } from 'countries-list';
import { BaseTextField } from 'src/legacy/components/TextField';
import { ChevronDownIcon } from 'src/legacy/components/Icons/ChevronDown';

interface CountryInputProps
  extends Pick<
    ComponentProps<typeof BaseTextField>,
    'label' | 'error' | 'helperText'
  > {
  className?: string;
  country: string;
  onCountryChange: (value: string | null) => void;
  autoFocus?: boolean;
}

const CountryInput = ({
  className,
  country,
  onCountryChange,
  label,
  error,
  helperText,
  autoFocus,
}: CountryInputProps) => {
  const [inputValue, setInputValue] = useState('');

  const baseCountries = useMemo(
    () => getCountryDataList().sort((a, b) => a.name.localeCompare(b.name)),
    [],
  );

  const expandedCountries = useMemo(() => {
    const unitedStates = baseCountries.findIndex((c) => c.iso2 === 'US');
    const unitedKingdom = baseCountries.findIndex((c) => c.iso2 === 'GB');
    const australia = baseCountries.findIndex((c) => c.iso2 === 'AU');
    const canada = baseCountries.findIndex((c) => c.iso2 === 'CA');

    return [
      baseCountries[unitedStates],
      baseCountries[canada],
      baseCountries[australia],
      baseCountries[unitedKingdom],
      ...baseCountries,
    ];
  }, [country, inputValue]);

  const selectCountryInfo = useMemo(() => {
    if (country === '') {
      return null;
    }

    return baseCountries.find((c) => c.iso2 === country);
  }, [country]);

  return (
    <Autocomplete
      className={className}
      classes={{
        option: 'min-h-0',
      }}
      value={selectCountryInfo}
      options={country || inputValue ? baseCountries : expandedCountries}
      getOptionLabel={(option) => option.name}
      onChange={(_, value) => {
        onCountryChange(value ? value.iso2 : null);
      }}
      onInputChange={(_, value) => {
        setInputValue(value);
      }}
      renderInput={(params) => (
        <BaseTextField
          {...params}
          label={label}
          variant="outlined"
          fullWidth
          error={error}
          helperText={helperText}
          InputProps={{
            ...params.InputProps,
            className: '',
            autoFocus,
            endAdornment: (
              <ChevronDownIcon className="w-[16px] h-[16px] text-secondary" />
            ),
          }}
          inputProps={{ ...params.inputProps, 'data-1p-ignore': true }}
        />
      )}
    />
  );
};

export default CountryInput;
