import React, { useContext } from 'react';
import {
  Box,
  Typography,
  IconButton,
  useTheme,
  useMediaQuery,
  makeStyles,
  Theme,
} from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import { ChevronLeft } from '@material-ui/icons';
import { RootState } from 'src/store';
import {
  toggleSecondarySidebar,
  updateSearchValueAction,
} from 'src/store/ui/actions';
import MenuLogo from 'src/legacy/components/Navbar/MenuLogo';
import SearchInput from 'src/legacy/components/SearchInput/SearchInput';
import {
  FILES_PAGE,
  MESSAGES_PAGE,
  FORMS_RESPONSE_PAGE,
  CLIENT_DETAILS_PAGE,
  CLIENT_APPS_PAGE,
  APP_SETUP_PAGE,
} from 'src/constants';
import { RouteContext } from 'src/context';
import { TruncatedText } from 'src/legacy/components/UI';
import { typography15MediumStyle } from 'src/legacy/components/Text';
import useNavigate from 'src/hooks/useNavigate';
import { unselectFileChannel } from 'src/store/files/actions';
import { setSelectedItemAction } from 'src/store/reduxTypes';
import BaseTypography from 'src/legacy/components/Text/BaseTypography';
import { RightSidebarToggle } from 'src/legacy/components/RightSidebar/RightSidebarToggle';

export interface NavbarContentProps {
  title?: string;
  userLoaded: boolean;
  userLoading: boolean;
  isClient: boolean;
  onOpenSidebarMobile: () => void;
  showSearchbar: boolean;
  showBreadCrumb?: boolean;
  BreadCrumbComponent?: React.FC;
  showNavbarAuthActions?: boolean;
  // eslint-disable-next-line react/no-unused-prop-types
  headerContent?: React.ReactNode;
}

interface QueryParamProps {
  formId?: string;
  channelId?: string;
  selectedFilePath?: string;
  clientUserId?: string;
}

const useStyles = makeStyles((theme: Theme) => ({
  menuWrapper: {
    marginRight: theme.spacing(1.5),
  },
}));

const NavbarContent: React.FC<NavbarContentProps> = ({
  title,
  userLoaded,
  userLoading,
  isClient,
  onOpenSidebarMobile,
  children,
  showSearchbar,
  showBreadCrumb = false,
  BreadCrumbComponent,
  showNavbarAuthActions = true,
}) => {
  if (!userLoaded) {
    return null;
  }

  const classes = useStyles();
  const { navigate } = useNavigate();
  const dispatch = useDispatch();

  const handleSearchChange = (searchVal: string) => {
    dispatch(updateSearchValueAction(searchVal));
  };

  const secondarySideBar = useSelector(
    (state: RootState) => state.ui.secondarySideBar,
  );

  const messageLoadingError = useSelector(
    (state: RootState) => state.messages.loadingError,
  );

  const { pathname, query } = useContext(RouteContext);

  const isMessagesPages = pathname === MESSAGES_PAGE.path;
  const isFilesPages = pathname === FILES_PAGE.path;
  const isExtensionsPage = pathname === CLIENT_APPS_PAGE.path;
  const isClientDetailsPage = pathname === CLIENT_DETAILS_PAGE.path;
  const isFormsResponsePage = pathname === FORMS_RESPONSE_PAGE.path;
  const theme = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down('xs'));
  const isSettingsPage = pathname.includes('/settings');
  const isAddAppPage = pathname.includes(`${APP_SETUP_PAGE.path}/new`);
  const { channelId, selectedFilePath, clientUserId } =
    query as QueryParamProps;
  const selectedFilePathString: string = selectedFilePath || '';

  // we can add other pages where app bar should be hidden
  const canHideAppBar =
    (isMessagesPages ||
      isFilesPages ||
      isExtensionsPage ||
      isFormsResponsePage) &&
    !secondarySideBar.isOpen &&
    !secondarySideBar.hidden &&
    isMobileScreen;

  const getTitle = () => {
    switch (pathname) {
      default:
        return title;
    }
  };

  const pageToolbarRenderer = () => {
    if (messageLoadingError) {
      return (
        <BaseTypography fontType="13Medium">
          {messageLoadingError}
        </BaseTypography>
      );
    }

    if (canHideAppBar && secondarySideBar.info) {
      const pathParts: string[] = selectedFilePathString
        .split('/')
        .filter((i) => i.length > 0);
      return (
        <TruncatedText
          text={
            pathname === FILES_PAGE.path && pathParts.length > 0
              ? pathParts[pathParts.length - 1]
              : secondarySideBar.info
          }
          styleProps={{ ...typography15MediumStyle }}
          maxChars={20}
        />
      );
    }

    if (showSearchbar) {
      // Temporary fix to disable search on all pages with search input for client user
      // TODO: remove this once we have search on all pages regardless of user type
      if (isClient) return null;
      return <SearchInput handleChange={handleSearchChange} />;
    }

    if (showBreadCrumb) {
      if (BreadCrumbComponent) {
        return <BreadCrumbComponent />;
      }
    }
    return <Typography variant="h3">{getTitle()}</Typography>;
  };

  /**
   * get a dispatch callback function for the left arrow action, or "undefined" if some other action is needed and not a dispatch func
   */
  const leftActionHandler = () => {
    if (isFilesPages || isClientDetailsPage) {
      const navigationPath: string = pathname;

      if (channelId !== '') {
        const pathParts: string[] = selectedFilePathString
          ? selectedFilePathString.split('/').filter((part) => part !== '')
          : [];
        if (pathParts.length > 1) {
          navigate(
            `${pathname}?${
              isClientDetailsPage ? `clientUserId=${clientUserId}&` : ''
            }channelId=${channelId}&selectedFilePath=${encodeURIComponent(
              pathParts.slice(0, pathParts.length - 1).join('/'),
            )}`,
          );
          return;
        }
        if (pathParts.length === 1) {
          navigate(
            `${pathname}?${
              isClientDetailsPage ? `clientUserId=${clientUserId}&` : ''
            }channelId=${channelId}`,
          );
          return;
        }
      }
      dispatch(
        unselectFileChannel({
          navigate,
          navigationPath,
          openSecondarySidebar: true,
        }),
      );
    }

    if (isExtensionsPage) {
      dispatch(setSelectedItemAction(''));
    }
    // this is the default callback function for the left arrow, which just opens the secondary sidebar
    dispatch(toggleSecondarySidebar({ isOpen: true }));
  };

  const appBarLeftAction = () => {
    if (
      (canHideAppBar && secondarySideBar.info) ||
      (selectedFilePathString !== '' && isMobileScreen)
    ) {
      return (
        <IconButton onClick={leftActionHandler} edge="start" color="inherit">
          <ChevronLeft />
        </IconButton>
      );
    }

    return <MenuLogo menuClickCallback={onOpenSidebarMobile} />;
  };
  const getPageToolbarJustifyContent = () => {
    if (isSettingsPage || isAddAppPage) {
      return 'flex-start';
    }

    return isMobileScreen ? 'center' : 'flex-start';
  };
  return (
    <Box display="flex" width={1}>
      <div className={classes.menuWrapper}>{appBarLeftAction()}</div>
      <Box
        display="flex"
        justifyContent={getPageToolbarJustifyContent()}
        alignItems="center"
        flex={canHideAppBar && secondarySideBar.info ? 0.9 : 1}
      >
        {pageToolbarRenderer()}
      </Box>

      {children}
      {(!secondarySideBar.info || !canHideAppBar) &&
        showNavbarAuthActions &&
        userLoaded &&
        !userLoading &&
        pathname.includes(CLIENT_DETAILS_PAGE.path) && (
          <Box display="flex" alignItems="center">
            <RightSidebarToggle />
          </Box>
        )}
    </Box>
  );
};

export default NavbarContent;
