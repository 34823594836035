import React, { useContext } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'src/store';
import { FormModal } from 'src/legacy/components/Modals';
import {
  useChannelForm,
  ChannelType,
} from 'src/legacy/components/Channels/useChannelForm';
import { useGetChannelModalTexts } from 'src/legacy/components/Channels/useGetChannelModalTexts';
import {
  CreateButtonProps,
  defaultCreateButtonProps,
} from 'src/legacy/components/UI/Buttons/CreateButton';
import { CreateActionModalProps } from 'src/legacy/components/Channels/BaseChannelSidebar';
import { RouteContext } from 'src/context';
import { SideDrawer } from '../UI/SideDrawer';
import { ChannelsListToolbar } from './ChannelListToolbar';
import { Hidden } from '@material-ui/core';
import { useGetInstallsQuery } from 'src/services/api/applicationsApi';

const CREATE_CHANNEL_FALLBACK_TITLE = 'Create a new channel';

export interface ChannelsSidebarProps {
  entity: ChannelType;
  onCreate?: (values: any) => Promise<void>;
  initialCreateFormValue: any;
  modalTitle?: string;
  hasChannels?: boolean;
  newButtonProps?: Partial<CreateButtonProps>;
  newChannelAction?: string;
  onSecondaryOptionClick?: (option: string) => void;
  noCreateAction?: boolean;
  pageTitle: React.ReactNode;
  CreateModal?: React.FC<CreateActionModalProps>;
  headerActionsSlot?: React.ReactNode;
  allowKeyboardShortcuts: boolean;
}

export const ChannelsSidebar: React.FC<ChannelsSidebarProps> = React.memo(
  ({
    onCreate,
    entity,
    children,
    initialCreateFormValue,
    modalTitle,
    hasChannels = true,
    newButtonProps,
    newChannelAction,
    onSecondaryOptionClick,
    noCreateAction = false,
    pageTitle,
    CreateModal,
    headerActionsSlot,
    allowKeyboardShortcuts = true,
  }) => {
    const { query } = useContext(RouteContext);
    const { channelId: activeChannelId } = query;
    const bannerOptions = useSelector(
      (state: RootState) => state.ui.bannerOptions,
    );
    const { data: { moduleSettings } = {} } = useGetInstallsQuery();
    const [openCreateModal, setOpenCreateModal] = React.useState(false);

    const handleOpenModal = () => setOpenCreateModal(true);
    const handleCloseModal = () => setOpenCreateModal(false);
    const { positiveAction } = useGetChannelModalTexts(entity, moduleSettings);

    /**
     * Based on the action that is selected in menu
     * we should perform new channel action to open modal
     * or call prop function to handle secondary option
     * @param optionValue menu option selected
     */
    const handleClickOptions = (optionValue: string) => {
      if (optionValue === newChannelAction) {
        handleOpenModal();
      } else if (onSecondaryOptionClick) {
        onSecondaryOptionClick(optionValue);
      }
    };

    const createButtonProps = React.useMemo(() => {
      if (noCreateAction) {
        return undefined;
      }

      return newButtonProps
        ? {
            ...defaultCreateButtonProps,
            ...(newButtonProps || {}),
            htmlId: newButtonProps?.htmlId || defaultCreateButtonProps.htmlId,
            onClickOption: newButtonProps?.onClickOption || handleClickOptions,
            onClickOptionAction:
              newButtonProps?.onClickOptionAction || handleOpenModal,
            caretOptions:
              newButtonProps?.caretOptions ||
              defaultCreateButtonProps.caretOptions,
            dropDownTitle:
              newButtonProps?.dropDownTitle ||
              defaultCreateButtonProps.dropDownTitle,
            actionOptionTitle:
              newButtonProps?.actionOptionTitle ||
              defaultCreateButtonProps.actionOptionTitle,
            mainOptions:
              newButtonProps?.mainOptions ||
              defaultCreateButtonProps.mainOptions,
          }
        : {
            onClick: handleOpenModal,
          };
    }, [newButtonProps, noCreateAction]);

    return (
      <>
        {hasChannels && (
          <Hidden xsDown={Boolean(activeChannelId)}>
            <SideDrawer hasBanner={Boolean(bannerOptions)}>
              <ChannelsListToolbar
                createButtonProps={createButtonProps}
                pageTitle={pageTitle}
                actionsSlot={headerActionsSlot}
                allowKeyboardShortcuts={allowKeyboardShortcuts}
              />

              {children}
            </SideDrawer>
          </Hidden>
        )}
        {CreateModal ? (
          <CreateModal onClose={handleCloseModal} open={openCreateModal} />
        ) : (
          <>
            {onCreate && (
              <FormModal
                buttonLabel={positiveAction}
                initialFormValue={initialCreateFormValue}
                entityName="channel"
                handleSave={onCreate}
                useFormHook={() => useChannelForm(entity)}
                open={openCreateModal}
                onClose={handleCloseModal}
                createButtonProps={{ fullWidth: true }}
                title={modalTitle || CREATE_CHANNEL_FALLBACK_TITLE}
              />
            )}
          </>
        )}
      </>
    );
  },
);
