import * as React from 'react';
import {
  Theme,
  makeStyles,
  InputBase,
  Divider,
  useTheme,
  useMediaQuery,
  IconButton,
} from '@material-ui/core';
import debounce from 'lodash/debounce';
import {
  NonHoverBorder,
  BlackHeadings,
  BlackSmall,
  GraySmall,
} from 'src/theme/colors';
import { CloseIcon, SearchIcon } from 'src/legacy/components/Icons';
import * as Colors from 'src/theme/colors';
import { updateSearchValueAction } from 'src/store/ui/actions';
import {
  typography13MediumStyle,
  typography13RegularStyle,
} from 'src/legacy/components/Text';
import { RootState } from 'src/store';
import { PrimaryButtonShadow } from 'src/theme/shadows';
import { MENU_LOGO_WIDTH } from 'src/legacy/components/Navbar/MenuLogo';
import { useOSDetector } from 'src/hooks/useOSDetector';
import { useKeyboardShortcuts } from 'src/hooks/useKeyboardShortcuts';
import { useAppDispatch, useAppSelector } from 'src/hooks/useStore';

type StyleProps = {
  showIconOnly: boolean;
  fullWidthOnFocus?: boolean;
  expandWidth?: number;
};

const INITIAL_SEARCH_WIDTH = 50;
const SEARCH_ICON_BUTTON_WIDTH = 0;
const FULL_SEARCH_WIDTH = 298;
const COLLAPSED_SEARCH_WIDTH = 130;
const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
  },
  input: {
    backgroundColor: Colors.white,
    '& .MuiInputBase-input': {
      paddingLeft: theme.spacing(2),
      fontSize: typography13MediumStyle.fontSize,
      fontWeight: typography13MediumStyle.fontWeight,
      transition: 'width 0.5s',
      width: (props: StyleProps) =>
        props.showIconOnly ? SEARCH_ICON_BUTTON_WIDTH : INITIAL_SEARCH_WIDTH,
      [theme.breakpoints.down('sm')]: {
        width: (props: StyleProps) =>
          !props.showIconOnly && SEARCH_ICON_BUTTON_WIDTH,
      },
      '&::placeholder': {
        opacity: 1,
      },
      '&:not(:placeholder-shown)': {
        width: ({ expandWidth, fullWidthOnFocus }) =>
          fullWidthOnFocus ? expandWidth : FULL_SEARCH_WIDTH,
        [theme.breakpoints.down('sm')]: {
          width: ({ expandWidth, fullWidthOnFocus }) =>
            fullWidthOnFocus ? expandWidth : COLLAPSED_SEARCH_WIDTH,
        },
      },
      '&:hover': {
        cursor: 'pointer',
      },
    },
    '&.MuiInputBase-root': {
      border: `1px solid ${NonHoverBorder}`,
      boxShadow: (props: StyleProps) =>
        props.showIconOnly ? PrimaryButtonShadow : 'unset',
      height: 28,
      color: BlackSmall,
      borderRadius: theme.spacing(0.5),
      '&.Mui-focused': {
        '&.MuiInputBase-root': {
          border: `1px solid ${BlackSmall}`,
        },
        '& .MuiInputBase-input': {
          width: ({ expandWidth, fullWidthOnFocus }) =>
            fullWidthOnFocus ? expandWidth : FULL_SEARCH_WIDTH,
          [theme.breakpoints.down('sm')]: {
            width: ({ expandWidth, fullWidthOnFocus }) =>
              fullWidthOnFocus ? expandWidth : COLLAPSED_SEARCH_WIDTH,
          },
          '&::placeholder': {
            color: BlackHeadings,
            fontSize: typography13RegularStyle.fontSize,
            fontWeight: typography13RegularStyle.fontWeight,
          },
        },
      },
      '&:hover': {
        backgroundColor: Colors.HoverBackground,
        color: BlackHeadings,
        cursor: 'pointer',
      },
    },
  },
  iconContainer: {
    padding: theme.spacing(0, 0.5, 0, 1),
    display: 'flex',
    alignItems: 'center',
    height: 0,
    width: 0,
    '&:hover': {
      cursor: 'pointer',
    },
  },
  searchIcon: {
    height: 12,
    width: 12,
    marginRight: theme.spacing(1),
    color: Colors.BlackSmall,
  },
  closeIcon: {
    width: 8,
    height: 8,
    position: 'absolute',
    right: 0,
    marginRight: theme.spacing(1.5),
    color: GraySmall,
    '&:hover': {
      color: BlackHeadings,
    },
  },
  iconButton: {
    padding: 'unset',
  },
  separator: {
    margin: theme.spacing(0, 2),
    height: 22,
    [theme.breakpoints.down('sm')]: {
      margin: theme.spacing(0, 1.5),
    },
  },
}));

type Props = {
  showIconOnly: boolean;
  onFocused?: () => void;
  onBlurred?: () => void;
  fullWidthOnFocus?: boolean;
  expandWidth?: number;
  // Some pages have searchbars in both channel list and channel details
  // In this case, we want to prioritize the keyboard shortcuts of the channel details
  // allowKeyboardShortcuts is used to disable the keyboard shortcuts of the searchbar in this case
  allowKeyboardShortcuts?: boolean;
};

export const Searchbox = ({
  showIconOnly = true,
  onFocused,
  onBlurred,
  fullWidthOnFocus,
  expandWidth,
  allowKeyboardShortcuts = true,
}: Props) => {
  const classes = useStyles({
    showIconOnly,
    fullWidthOnFocus,
    expandWidth,
  });
  const dispatch = useAppDispatch();
  const searchInputRef = React.useRef<HTMLInputElement | null>(null);
  const { isOSDeleteKeyPressed } = useOSDetector();
  const searchInputValue = useAppSelector(
    (state: RootState) => state.ui.searchValue,
  );
  const { enableNativeFind } = useAppSelector((state: RootState) => state.ui);
  const [searchKey, setSearchKey] = React.useState(searchInputValue);

  useKeyboardShortcuts(
    allowKeyboardShortcuts
      ? [
          {
            id: 'search-shortcut',
            name: 'Search',
            shortcut: ['/'],
            perform: () => {
              if (searchInputRef.current) {
                searchInputRef.current.focus();
              }
            },
          },
        ]
      : [],
  );

  /**
   * This function handles key press for default search behavior.
   * @param ev: Key press event
   */

  const { isOSMetaKeyPressed } = useOSDetector();

  const handleKeyPress = React.useCallback(
    (ev: KeyboardEvent) => {
      const functionalKey = ['f', 'F'];
      if (
        isOSMetaKeyPressed(ev) &&
        functionalKey.includes(ev.key) &&
        !enableNativeFind
      ) {
        ev.preventDefault();
        if (allowKeyboardShortcuts) {
          if (searchInputRef.current) {
            searchInputRef.current.focus();
          }
        }
      }
    },
    [enableNativeFind, isOSMetaKeyPressed, allowKeyboardShortcuts],
  );

  React.useEffect(() => {
    window.addEventListener('keydown', handleKeyPress, false);
    return () => {
      window.removeEventListener('keydown', handleKeyPress, false);
    };
  }, [handleKeyPress]);

  const handleKeydown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Escape') {
      if (searchInputRef.current) {
        // un-focus input
        searchInputRef.current.blur();
      }
    } else if (isOSDeleteKeyPressed(event)) {
      // Delete key is being used to delete sidebar items in webapp
      // We do not want to delete the sidebar items when searchbar is active
      event.stopPropagation();
    }
  };

  // when the user clicks on the close icon, reset the search
  const resetSearch = () => {
    setSearchKey('');
  };

  // this function is called when the user types in the search box
  const handleKeyChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchKey(event.target.value);
  };

  // This useEffect is called when the searchKey changes
  // it debounces the update of the search value in the store
  // in order to avoid too many API calls.
  React.useEffect(() => {
    const doubouncedFunc = debounce(() => {
      dispatch(updateSearchValueAction(searchKey));
    }, 500);

    doubouncedFunc();

    return () => {
      // cancel the debounce function when the component unmounts
      doubouncedFunc.cancel();
    };
  }, [searchKey]);

  // The search redux state is used to persist the search value
  // when user navigates to specific pages. When it is cleared
  // we need to clear the search box as well.
  React.useEffect(() => {
    if (!searchInputValue) {
      setSearchKey('');
    }
  }, [searchInputValue]);

  return (
    <InputBase
      autoComplete="off"
      inputRef={searchInputRef}
      id="search-elements"
      value={searchKey}
      className={classes.input}
      startAdornment={
        <div className={classes.iconContainer}>
          <SearchIcon className={classes.searchIcon} />
        </div>
      }
      endAdornment={
        <IconButton
          hidden={!searchKey}
          onClick={resetSearch}
          className={classes.iconButton}
        >
          <CloseIcon color="primary" className={classes.closeIcon} />
        </IconButton>
      }
      placeholder="Search"
      onFocus={(e) => {
        e.target.placeholder = 'Find in view...';
        if (onFocused) {
          onFocused();
        }
      }}
      onBlur={(e) => {
        e.target.placeholder = 'Search';
        if (onBlurred) {
          onBlurred();
        }
      }}
      onChange={handleKeyChange}
      onKeyDown={handleKeydown}
    />
  );
};

type TableSearchProps = {
  showDivider?: boolean;
};

export const TableSearch = ({ showDivider = true }: TableSearchProps) => {
  const theme = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down('xs'));
  const classes = useStyles({
    showIconOnly: false,
  });
  // expanded with of search input on mobile screens
  const [expandWidth, setExpandWidth] = React.useState(0);

  const tableSearch = React.useRef<HTMLDivElement>(null);

  const getSearchExpandWidth = () => {
    // distance between left:0 and table search
    const tableSearchOffset = tableSearch.current?.offsetLeft || 0;
    // this is the distance between closed tablesearch and menu toggle
    // button on small screen.
    const spaceBetweenMenuAndSearch =
      tableSearchOffset - (MENU_LOGO_WIDTH + 16); // considering menu button padding and margin on page title
    setExpandWidth(spaceBetweenMenuAndSearch);
  };

  React.useEffect(() => {
    getSearchExpandWidth();
  }, [tableSearch.current]);

  React.useEffect(() => {
    window.addEventListener('resize', getSearchExpandWidth);
    return () => window.removeEventListener('resize', getSearchExpandWidth);
  }, []);

  return (
    <div className={classes.root} ref={tableSearch}>
      <Searchbox
        showIconOnly={false}
        expandWidth={expandWidth}
        fullWidthOnFocus={isMobileScreen}
      />
      {showDivider && (
        <Divider orientation="vertical" className={classes.separator} />
      )}
    </div>
  );
};

type ChannelDetailsSearchbarProps = {
  setQuery: (key: string) => void;
  query: string;
  expandWidth: number;
};

export const ChannelDetailsSearchbar = ({
  setQuery,
  query,
  expandWidth,
}: ChannelDetailsSearchbarProps) => {
  const searchInputRef = React.useRef<HTMLDivElement>(null);
  const theme = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down('xs'));
  const classes = useStyles({
    showIconOnly: false,
    expandWidth,
    fullWidthOnFocus: isMobileScreen,
  });
  const { isOSMetaKeyPressed } = useOSDetector();
  const enableNativeFind = useAppSelector(
    (state: RootState) => state.ui.enableNativeFind,
  );

  useKeyboardShortcuts([
    {
      id: 'channel-search-shortcut',
      name: 'Channel Search',
      shortcut: ['/'],
      perform: () => {
        if (searchInputRef.current) {
          searchInputRef.current.focus();
        }
      },
    },
  ]);

  const handleKeyPress = React.useCallback(
    (ev: KeyboardEvent) => {
      const functionalKey = ['f', 'F'];
      if (
        isOSMetaKeyPressed(ev) &&
        functionalKey.includes(ev.key) &&
        !enableNativeFind
      ) {
        ev.preventDefault();
        if (searchInputRef?.current) {
          searchInputRef?.current.focus();
        }
      }
      if (ev.key === 'Escape') {
        if (searchInputRef.current) {
          // un-focus input
          searchInputRef.current.blur();
        }
      }
    },
    [enableNativeFind, isOSMetaKeyPressed, searchInputRef],
  );

  React.useEffect(() => {
    window.addEventListener('keydown', handleKeyPress, false);
    return () => {
      window.removeEventListener('keydown', handleKeyPress, false);
    };
  }, [handleKeyPress]);
  return (
    <InputBase
      autoComplete="off"
      id="message-search-elements"
      inputRef={searchInputRef}
      value={query}
      className={classes.input}
      startAdornment={
        <div className={classes.iconContainer}>
          <SearchIcon className={classes.searchIcon} />
        </div>
      }
      endAdornment={
        <IconButton
          hidden={!query}
          onClick={() => setQuery('')}
          className={classes.iconButton}
        >
          <CloseIcon color="primary" className={classes.closeIcon} />
        </IconButton>
      }
      placeholder="Search"
      onFocus={(e) => {
        e.target.placeholder = 'Find in view...';
      }}
      onBlur={(e) => {
        e.target.placeholder = 'Search';
      }}
      onChange={(e) => setQuery(e.target.value)}
      inputProps={{ 'data-testid': 'channel-details-search' }}
    />
  );
};
