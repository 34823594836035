import React from 'react';
import { CognitoUser } from 'amazon-cognito-identity-js';
import { AUTH_STATES } from 'src/constants/authConsts';
import { RouteContext } from 'src/context';
import { OnboardingAuth } from 'src/legacy/components/Onboarding/Login/OnboardingAuth';
import { OnboardingForgotPasswordPage } from 'src/legacy/components/Onboarding/Login/OnboardingForgotPasswordPage';
import { queryParams } from 'src/legacy/components/Onboarding/Login/OnboardingLoginPage';
import { OnboardingRegisterPage } from 'src/legacy/components/Onboarding/Login/OnboardingRegisterPage';

export type OnboardingAuthLayoutProps = {
  onAuthComplete: () => void;
};

interface MfaVerificationData {
  user: CognitoUser;
  email: string;
}
/**
 * This component is used to render the different auth forms
 * for internal user authentication ux. It is similar to the
 * ClientLogin component which is used to do the same for
 * the client user authentication ux.
 */
export const OnboardingAuthLayout = ({
  onAuthComplete,
}: OnboardingAuthLayoutProps) => {
  const { query }: { query: queryParams } = React.useContext(RouteContext);
  const { step } = query || '';

  const renderRegisterForm = query.auth && step === AUTH_STATES.SIGN_IN;
  const renderForgotPasswordForm = step === AUTH_STATES.FORGOT_PASSWORD;

  // this is used to store the user  being signed in
  // when an mfa challenge is required
  const [mfaData, setMfaData] = React.useState<MfaVerificationData>();

  const renderAuthForm = React.useCallback(() => {
    if (query && step) {
      if (renderRegisterForm) {
        return <OnboardingRegisterPage onAuthComplete={onAuthComplete} />;
      }

      // when mfa data is set, we want the user to complete the mfa challenge and
      // log in. This is done by calling the OnboardingAuth with the user and
      // setting the challenge type to be mfa
      if (mfaData) {
        return (
          <OnboardingAuth
            user={mfaData.user}
            mfaChallengeParam={{
              type: 'SOFTWARE_TOKEN_MFA',
              email: mfaData.email,
            }}
            onAuthComplete={onAuthComplete}
          />
        );
      }

      if (renderForgotPasswordForm) {
        return (
          // onAuthComplete is invoked when the user has mfa disabled
          // When mfa is enabled, onMfaRequired with the data of user trying to
          // sign in
          // This data is set in the mfaData which renders the OnboardingAuth component,
          // which takes care of completing the challenge and signing in the user
          <OnboardingForgotPasswordPage
            onAuthComplete={onAuthComplete}
            onMfaRequired={(user, email) => {
              // when mfa is required, the user is stored to
              // confirm signing in later
              setMfaData({ user, email });
            }}
          />
        );
      }
    }

    return <OnboardingAuth onAuthComplete={onAuthComplete} />;
  }, [renderRegisterForm, renderForgotPasswordForm, query, mfaData]);

  return <>{renderAuthForm()}</>;
};
