import React, { useEffect, useRef, useState } from 'react';
import { Popover, PopoverActions } from '@material-ui/core';
import { Formik } from 'formik';
import { getCountryData, TCountryCode } from 'countries-list';
import { Heading, PrimaryButton } from 'copilot-design-system';
import GoogleMapsAddressInput from 'src/legacy/components/Address/GoogleMapsAddressInput';
import CountryInput from 'src/legacy/components/Address/CountryInput';
import {
  AddressCustomField,
  AddressCustomFieldValues,
} from 'src/store/clients/types';
import BaseTypography from 'src/legacy/components/Text/BaseTypography';
import { BaseTextField } from 'src/legacy/components/TextField';
import { LightGray } from 'src/theme/colors';
import { CustomFieldValueData } from 'src/legacy/components/ClientDetailsPage/clientDetailsTypes';
import { EditableCellValueWrapper } from 'src/legacy/components/UI/Tables/EditableCellWrapper';

import OptionsMenu from 'src/legacy/components/AddressInput/OptionsMenu';
import useAddressValidation from 'src/legacy/components/AddressInput/useAddressValidation';

const EMPTY_ADDRESS: Required<AddressCustomFieldValues> = {
  addressLine1: '',
  addressLine2: '',
  city: '',
  country: '',
  fullAddress: '',
  postalCode: '',
  region: '',
};

interface AddressFormProps {
  fieldId: string;
  initialValues: Required<AddressCustomFieldValues>;
  closePopover: () => void;
  updatePopoverPosition: () => void;
  onSaveValue: (property: CustomFieldValueData) => void;
}

const AddressForm = ({
  fieldId,
  initialValues,
  closePopover,
  updatePopoverPosition,
  onSaveValue,
}: AddressFormProps) => {
  // save initial fullAddress value from before any editing happens
  const initialFullAddress = useRef(initialValues.fullAddress);
  const { isOnlyFullAddress, addressFieldSchema } = useAddressValidation(
    initialValues.addressLine1,
  );

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={addressFieldSchema}
      validateOnChange
      validateOnBlur
      onSubmit={(values) => {
        onSaveValue({
          fieldId,
          value: {
            ...values,
            fullAddress: !isOnlyFullAddress({
              country: values.country,
              fullAddress: values.fullAddress,
            })
              ? [
                  values.addressLine1,
                  values.addressLine2,
                  [values.city, values.region, values.postalCode]
                    .filter((val) => val !== '')
                    .join(', '),
                  getCountryData(values.country as TCountryCode).name,
                ]
                  .filter((val) => val !== '')
                  .join('\n')
              : values.fullAddress,
          },
        });
        closePopover();
      }}
    >
      {({
        errors,
        handleBlur: formikHandleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        setFieldValue,
        setValues,
        submitForm,
        touched,
        values,
      }) => {
        // forces the popover to reposition after the formik blur handler so that the added error text
        // doesn't cause the bottom of the popover to be cut off
        const handleBlur = (e: React.FocusEvent<any>) => {
          formikHandleBlur(e);
          updatePopoverPosition();
        };
        // forces the popover to reposition after country is changed or a submission is attempted so that the
        // popover content isn't cut off due to size changes
        useEffect(() => {
          updatePopoverPosition();
        }, [values.country, isSubmitting]);

        const isSelectedCountryUnitedKingdom = values.country === 'GB';
        return (
          <div className="p-3">
            <div className="flex items-start justify-between gap-2">
              <Heading size="lg" className="mb-1">
                Address
              </Heading>
              <OptionsMenu
                handleClear={() => {
                  setValues(EMPTY_ADDRESS);
                  onSaveValue({
                    fieldId,
                    value: EMPTY_ADDRESS,
                  });
                  closePopover();
                }}
                // fullAddress is guaranteed to nonEmpty for a valid saved address
                isEmptyAddress={initialFullAddress.current === ''}
              />
            </div>
            <form onSubmit={handleSubmit}>
              <div className="flex flex-col gap-3">
                <CountryInput
                  label="Country"
                  autoFocus
                  country={values.country}
                  onCountryChange={(country) => {
                    // On country change, reset the address fields
                    setValues(EMPTY_ADDRESS);
                    if (country) {
                      setFieldValue('country', country);
                    }
                  }}
                  error={touched.country && errors.country !== undefined}
                  helperText={touched.country && errors.country}
                />

                {/* show other fields if either the country has been selected or a fullAddress already exists (possible from csv import) */}
                {(values.country !== '' || values.fullAddress !== '') && (
                  <>
                    {isOnlyFullAddress({
                      country: values.country,
                      fullAddress: values.fullAddress,
                    }) ? (
                      <BaseTextField
                        name="fullAddress"
                        multiline
                        label="Address"
                        variant="outlined"
                        fullWidth
                        value={values.fullAddress}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        minRows={3}
                        autoFocus
                        error={
                          touched.fullAddress &&
                          errors.fullAddress !== undefined
                        }
                        helperText={touched.fullAddress && errors.fullAddress}
                        inputProps={{ 'data-1p-ignore': true }}
                      />
                    ) : (
                      <>
                        <GoogleMapsAddressInput
                          label="Address line 1"
                          addressValue={values.addressLine1}
                          autoFocus
                          onAddressValueChange={(e) => {
                            setFieldValue('addressLine1', e.target.value);
                          }}
                          country={values.country}
                          error={
                            touched.addressLine1 &&
                            errors.addressLine1 !== undefined
                          }
                          helperText={
                            touched.addressLine1 && errors.addressLine1
                          }
                          onAutocompleteChange={({
                            addressLine1,
                            addressLine2,
                            city,
                            state,
                            zipCode,
                          }) => {
                            // fires when an autocomplete option is selected
                            setValues({
                              ...values,
                              addressLine1:
                                addressLine1 !== ''
                                  ? `${addressLine1} ${addressLine2}`
                                  : addressLine2,
                              city,
                              region: !isSelectedCountryUnitedKingdom
                                ? state
                                : '',
                              postalCode: zipCode,
                            });
                          }}
                          onAutocompleteInputChange={(newValue, reason) => {
                            // fires when an autocomplete is typed in
                            if (reason === 'clear') {
                              setValues({
                                ...EMPTY_ADDRESS,
                                country: values.country,
                              });
                            } else if (reason !== 'reset') {
                              setFieldValue('addressLine1', newValue);
                            }
                          }}
                        />

                        <BaseTextField
                          variant="outlined"
                          name="addressLine2"
                          value={values.addressLine2}
                          label="Address line 2"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          fullWidth
                          inputProps={{ 'data-1p-ignore': true }}
                        />

                        <BaseTextField
                          variant="outlined"
                          name="city"
                          value={values.city}
                          label="City"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          fullWidth
                          error={touched.city && errors.city !== undefined}
                          helperText={touched.city && errors.city}
                          inputProps={{ 'data-1p-ignore': true }}
                        />

                        {!isSelectedCountryUnitedKingdom && (
                          <BaseTextField
                            variant="outlined"
                            name="region"
                            value={values.region}
                            label="State"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            fullWidth
                            error={
                              touched.region && errors.region !== undefined
                            }
                            helperText={touched.region && errors.region}
                            inputProps={{ 'data-1p-ignore': true }}
                          />
                        )}

                        <BaseTextField
                          variant="outlined"
                          name="postalCode"
                          value={values.postalCode}
                          label={
                            values.country === 'US' ? 'Zip code' : 'Postal code'
                          }
                          onChange={handleChange}
                          onBlur={handleBlur}
                          fullWidth
                          error={
                            touched.postalCode &&
                            errors.postalCode !== undefined
                          }
                          helperText={touched.postalCode && errors.postalCode}
                          inputProps={{ 'data-1p-ignore': true }}
                        />
                      </>
                    )}
                    <PrimaryButton label="Save" onClick={submitForm} />
                  </>
                )}
              </div>
            </form>
          </div>
        );
      }}
    </Formik>
  );
};

interface AddressInputProps {
  field: AddressCustomField;
  value: AddressCustomFieldValues;
  placeholder: string;
  onSaveValue: (property: CustomFieldValueData) => void;
  onClose?: () => void;
  renderAsCell?: boolean;
  shouldOpenMenu?: boolean;
}

const AddressInput = ({
  field,
  value,
  placeholder,
  onSaveValue,
  onClose,
  renderAsCell,
  shouldOpenMenu,
}: AddressInputProps) => {
  const containerRef = useRef<HTMLDivElement | null>(null);
  const popoverActions = useRef<PopoverActions>(null);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  // open the popover when shouldOpenMenu is set to true
  useEffect(() => {
    if (shouldOpenMenu && containerRef !== null) {
      setAnchorEl(containerRef.current);
    }
  }, [shouldOpenMenu]);

  return (
    <>
      <div
        ref={containerRef}
        className="w-full h-full flex items-center pt-1"
        onClickCapture={(e) => {
          e.stopPropagation();
          setAnchorEl(e.currentTarget);
        }}
      >
        {renderAsCell ? (
          <EditableCellValueWrapper>
            <BaseTypography>{value.fullAddress}</BaseTypography>
          </EditableCellValueWrapper>
        ) : (
          <>
            {!value.fullAddress ? (
              <BaseTypography fontType="13Medium" style={{ color: LightGray }}>
                {placeholder}
              </BaseTypography>
            ) : (
              <BaseTypography
                className="whitespace-pre-wrap"
                fontType="13Medium"
              >
                {value.fullAddress}
              </BaseTypography>
            )}
          </>
        )}
      </div>
      <Popover
        onClose={() => {
          if (onClose) onClose();
          setAnchorEl(null);
        }}
        open={anchorEl !== null}
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        action={popoverActions}
        classes={{
          paper:
            'border border-solid border-secondary-hover shadow-dropdown-menu w-[263px]',
        }}
      >
        <AddressForm
          fieldId={field.id}
          initialValues={{
            addressLine1: value.addressLine1 ?? '',
            addressLine2: value.addressLine2 ?? '',
            city: value.city ?? '',
            country: value.country ?? '',
            fullAddress: value.fullAddress ?? '',
            region: value.region ?? '',
            postalCode: value.postalCode ?? '',
          }}
          closePopover={() => {
            setAnchorEl(null);
          }}
          updatePopoverPosition={() => {
            if (popoverActions.current) {
              popoverActions.current.updatePosition();
            }
          }}
          onSaveValue={onSaveValue}
        />
      </Popover>
    </>
  );
};

export default AddressInput;
