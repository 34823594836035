import React, { FC, ReactNode } from 'react';
import { useSelector } from 'react-redux';
import { Button, Theme, createStyles, makeStyles } from '@material-ui/core';
import { RootState } from 'src/store/reduxTypes';
import { ArrowRightIcon } from 'src/legacy/components/Icons';
import { CRM_CLIENTS_PAGE } from 'src/constants/pathConsts';
import useNavigate from 'src/hooks/useNavigate';
import { PageCallout } from 'src/legacy/components/PageCallout';
import { DESKTOP_APP_BAR_HEIGHT, MOBILE_APP_BAR_HEIGHT } from 'src/constants';
import { DividersAndCardBorders } from 'src/theme/colors';
import MenuLogo from 'src/legacy/components/Navbar/MenuLogo';
import BaseTypography from 'src/legacy/components/Text/BaseTypography';
import IconTile from 'src/legacy/components/UI/IconTile';

type StyleProps = {
  defaultMode?: false;
  isChannelsLayout?: boolean;
};
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
      width: '100%',
    },
    content: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: (props?: StyleProps) => (props?.defaultMode ? '80%' : '100%'),
      padding: (props?: StyleProps) =>
        props?.isChannelsLayout ? theme.spacing(0, 2) : 'inherit',
      width: '100%',
      position: 'relative',
    },
    header: {
      display: 'flex',
      alignItems: 'center',
      height: DESKTOP_APP_BAR_HEIGHT,
      width: '100%',
      borderBottom: `1px solid ${DividersAndCardBorders}`,
      gap: theme.spacing(1.5),
      padding: theme.spacing(0, 4.5),
      [theme.breakpoints.down('xs')]: {
        padding: theme.spacing(0, 2),
        height: MOBILE_APP_BAR_HEIGHT,
      },
    },
  }),
);
const StandardPageNavbar: FC<{ pageTitle: ReactNode }> = ({ pageTitle }) => {
  const classes = useStyles();

  return (
    <div className={classes.header}>
      <MenuLogo />
      <BaseTypography fontType="13Medium">{pageTitle}</BaseTypography>
    </div>
  );
};
export interface StandardPageProps {
  pageTitle?: ReactNode;
  hasNavbar?: boolean;
  header?: string;
  title?: string;
  topIcon?: React.ReactNode;
  showTopIcon?: boolean;
  desc?: string;
  learnMoreLinkUrl?: string;
  startIconForActionButton?: React.ReactNode;
  endIconForActionButton?: React.ReactNode;
  labelForActionButton?: React.ReactNode;
  onAction?: () => void;
  actionButton?: React.ReactNode;
  noClientTitle?: string;
  noClientDesc?: string;
  hideGoToCRMButton?: boolean;
  titleForClient?: string;
  descForClient?: string;
  actionButtonForClient?: React.ReactNode;
  showContent?: boolean;
  navAction?: {
    path: string;
    label: string;
  };
  defaultMode?: boolean; // if true don't apply any settings based on client or internal user, and only uses the base properties i.e. title, topIcon, desc and actionButton.... rest are ignored
  disableHideChannelListToolbar?: boolean;
  // Overriders for default actions
  customStandardPageActions?: React.ReactNode;
}

export const StandardPage: React.FC<StandardPageProps> = ({
  pageTitle,
  title,
  topIcon,
  showTopIcon = true,
  desc,
  learnMoreLinkUrl,
  startIconForActionButton,
  endIconForActionButton,
  labelForActionButton,
  onAction,
  actionButton,
  noClientTitle = '',
  noClientDesc = '',
  hideGoToCRMButton = false,
  titleForClient = '',
  descForClient = '',
  actionButtonForClient,
  showContent = false,
  navAction,
  children,
  defaultMode = false,
  customStandardPageActions,
  hasNavbar = false, // channels layouts should use standard page navbar
}) => {
  const classes = useStyles({
    isChannelsLayout: hasNavbar,
  });
  const isClient = useSelector((state: RootState) => state.user.isClient);

  const { navigate } = useNavigate();
  const activeClients = useSelector(
    (state: RootState) => state.clients.activeClients,
  );
  if (isClient && !titleForClient && !defaultMode) return null;

  let titleToUse = title;
  let descToUse = desc;
  let actionBtnToUse = actionButton;

  if (isClient && !defaultMode) {
    titleToUse = titleForClient;
    descToUse = descForClient;
    actionBtnToUse = actionButtonForClient;
  } else if ((!activeClients || activeClients.length < 1) && !defaultMode) {
    if (noClientTitle) {
      titleToUse = noClientTitle;
      descToUse = noClientDesc;
      actionBtnToUse = hideGoToCRMButton ? null : (
        <Button
          color="primary"
          variant="contained"
          endIcon={<ArrowRightIcon />}
          onClick={() => navigate(CRM_CLIENTS_PAGE.path)}
        >
          Go to Clients
        </Button>
      );
    }
  }

  const renderActionButton = () => {
    if (actionBtnToUse) {
      return actionBtnToUse;
    }

    if (navAction) {
      return (
        <Button
          color="primary"
          variant="contained"
          endIcon={<ArrowRightIcon />}
          onClick={() => navigate(navAction.path)}
        >
          {`Go to ${navAction.label}`}
        </Button>
      );
    }

    // There are actions that either do not use the same styles or have multiple buttons
    // If customStandardPageActions are passed in, use them instead of the default action button
    if (customStandardPageActions) return customStandardPageActions;

    if (onAction) {
      return (
        <Button
          variant="contained"
          color="primary"
          {...(startIconForActionButton
            ? { startIcon: startIconForActionButton }
            : {})}
          {...(endIconForActionButton
            ? { startIcon: endIconForActionButton }
            : {})}
          onClick={onAction}
        >
          {labelForActionButton}
        </Button>
      );
    }

    return null;
  };

  return showContent ? (
    // needed to satisfy React.FC return type
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>{children}</>
  ) : (
    <div className={classes.root}>
      {hasNavbar && <StandardPageNavbar pageTitle={pageTitle || ''} />}
      <div className={classes.content}>
        <PageCallout
          title={titleToUse || ''}
          description={descToUse || ''}
          link={!isClient ? learnMoreLinkUrl || '' : ''}
          linkText="Learn more"
          actionButtonRenderer={renderActionButton}
        >
          {showTopIcon && <IconTile icon={topIcon} />}
        </PageCallout>
      </div>
    </div>
  );
};
