import * as ModuleIcons from 'src/legacy/components/Icons/ModuleIcons';
import { BacklinksIcons } from 'src/legacy/components/Icons/Backlinks';
import { KeywordsIcon } from 'src/legacy/components/Icons/Keywords';
import { VisibleIcon } from 'src/legacy/components/Icons/Visible';
import { PaymentsIcon } from 'src/legacy/components/Icons/Payments';

export * from './Back';
export * from './Clients';
export * from './Clock';
export * from './Stats';
export * from './Eye';
export * from './Preview';
export * from './Files';
export * from './Shop';
export * from './Survey';
export * from './Tweet';
export * from './Smartphone';
export * from './Qrc';
export * from './StripeLogo';
export * from './GoogleDrive';
export * from './CancelInvoice';
export * from './Messages';
export * from './Pages';
export * from './PagesDark';
export * from './Payments';
export * from './Plus';
export * from './ChevronDown';
export * from './Account';
export * from './Billing';
export * from './Customization';
export * from './MainSettings';
export * from './FileFormats';
export * from './CustomFields';
export * from './Logo';
export * from './User';
export * from './Company';
export * from './Task';
export * from './Copy';
export * from './Reorder';
export * from './Required';
export * from './FileUploadIcon';
export * from './Forms';
export * from './ArrowRight';
export * from './SuccessCheckmark';
export * from './PaintRoll';
export * from './Paint';
export * from './UserPlus';
export * from './WorldNet';
export * from './Close';
export * from './Snackbars/SuccessIcon';
export * from './Snackbars/ErrorIcon';
export * from './Snackbars/UploadIcon';
export * from './BookOpen';
export * from './Portal';
export * from './PortalModules';
export * from './Edit';
export * from './MultiAnswer';
export * from './Team';
export * from './Switch';
export * from './Puzzle';
export * from './Search';
export * from './Forward';
export * from './ExternalLink';
export * from './ChevronRight';
export * from './Check';
export * from './Reset';
export * from './CheckboxChecked';
export * from './AlertCircle';
export * from './Send';
export * from './CreditCard';
export * from './ArrowDown';
export * from './AlertFilled';
export * from './SendHorizontalIcon';
export * from './Checkbox';
export * from './MoreActions';
export * from './Refresh';
export * from './Play';
export * from './People';
export * from './ChatBubble';
export * from './Article';
export * from './Invoice';
export * from './FolderFill';
export * from './Subscription';
export * from './FormsFilled';
export * from './Sidebar';
export * from './SidebarOpen';
export * from './ErrorCloseMarkIcon';
export * from './DocsIcon';
export * from './UpdatesIcon';
export * from './SlackIcon';
export * from './Template';
export * from './PaperClip';
export * from './Dashboard';
export * from './Info';
export * from './FileDropzone';
export * from './CreditCardFilled';
export * from './Bank';
export * from './Integrations';
export * from './Diamond';
export * from './PortalBlack';
export * from './CaretRight';
export * from './DefaultCompany';
export * from './Extensions';
export * from './Backlinks';
export * from './Keywords';
export * from './ModuleIcons';
export * from './Visible';
export * from './Date';
export * from './Group';
export * from './DownloadIcon';
export * from './ExtensionDisconnect';
export * from './CheckedCircle';
export * from './TextEditor';
export * from './Invisible';
export * from './QuestionIcon';
export * from './NoCompany';
export * from './SignatureIcons';
export * from './NotificationIcon';
export * from './Referrals';
export * from './Facebook';
export * from './LinkedIn';
export * from './Twitter';
export * from './Instagram';
export * from './DollarIcon';
export * from './CopilotLogo';
export * from './ImportIcon';
export * from './Core';
export * from './CopilotBrandingIcon';
export * from './Logout';
export * from './DropzoneFilesIcon';
export * from './Export';
export * from './AccountOutlined';
export * from './DropzoneFilesIcon';
export * from './Hamburger';
export * from './QuestionShortIcon';
export * from './QuickBooksIcon';
export * from './AuthIcons';
export * from './TrayOutline';
export * from './TrayFullOutline';
export * from './RatingStar';
export * from './ArrowNe';
export * from './CrossIcon';
export * from './TimelineInvoiceIcon';
export * from './PaymentFailedIcon';
export * from './PauseIcon';
export * from './BankCard';
export * from './VisaCard';
export * from './HeartFilled';

const {
  SpeedometerIcon,
  AnalyticsIcon,
  PieChartIcon,
  CubeIcon,
  FolderIcon,
  BookIcon,
  ClipboardIcon,
  ColorFilterIcon,
  ChatboxIcon,
  LinkIcon,
  ShapesIcon,
  ContractIcon,
  ...otherModuleIcons
} = ModuleIcons;

export const PickerIcons = {
  messages: ChatboxIcon,
  payments: PaymentsIcon,
  files: FolderIcon,
  forms: ClipboardIcon,
  knowledge: BookIcon,
  contracts: ContractIcon,
  ...otherModuleIcons,
};

export const IconNameToComponent = {
  dashboard: SpeedometerIcon,
  keywords: KeywordsIcon,
  backlinks: BacklinksIcons,
  extension: LinkIcon,
  linegraph: AnalyticsIcon,
  piechart: PieChartIcon,
  shapes: ShapesIcon,
  visible: VisibleIcon,
  cube: CubeIcon,
  venn: ColorFilterIcon,
  ...PickerIcons,
};

export type IconType = KeyOf<typeof IconNameToComponent>;

const isIconName = (val: string | IconType): val is IconType =>
  val in IconNameToComponent;

export const getIconName = (
  val: string,
  fallback: IconType = 'AppsIcon',
): IconType => (isIconName(val) ? val : fallback);

export const getIconElement = (
  val: string,
  fallback: IconType = 'AppsIcon',
): ValueOf<typeof IconNameToComponent> => {
  if (isIconName(val)) {
    return IconNameToComponent[val];
  }
  return IconNameToComponent[fallback];
};
