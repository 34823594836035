import { Auth, Storage } from 'aws-amplify';
import Axios, { AxiosRequestConfig } from 'axios';
import { FOLDER_FILE_KEY } from 'src/constants/fileConsts';
import { UploadProgressProps } from 'src/hooks/useProgressUpload';
import { API } from 'src/utils/AmplifyApiUtils';
import { ensureError } from './Errors';
import { notify } from 'src/clients/ApiService';
import { Dispatch } from 'redux';

interface SignedUploadResult {
  Location: string;
}
type UploadFunctionType = () => Promise<UploadResponse>;
const FILE_UPLOAD_ERRORS = ['AWSS3ProviderManagedUpload', 'Network Error'];
const handleFileUploadFailure = async (
  error: unknown,
  uploadFn?: UploadFunctionType,
  dispatch?: Dispatch,
) => {
  const { message } = ensureError(error);
  if (message.includes('The provided token has expired')) {
    // this error is placed here to track in sentry if the fix below is working
    // we know this works, if the console error following this is not triggered
    console.error('Amplify failed to auto-refresh token', error);
    try {
      // trigger the cognito refresh token
      // amplify refreshes the token if it detects that the token is invalid
      const session = await Auth.currentSession();
      if (session.isValid() && uploadFn) {
        return uploadFn();
      }

      // if the session is not valid then we will not be able to upload a file
      throw new Error('failed to get a valid session');
    } catch (e) {
      console.error(
        'Failed to explicitly refresh user session and upload files',
        e,
      );

      // since we have not been able to fix the error,
      // we throw the error again and expect the error handling added in the consumer function
      // to show a graceful error message
      throw e;
    }
  }

  // check if the error is a file upload then clear the progress snackbar and show an error message
  if (FILE_UPLOAD_ERRORS.includes(message)) {
    if (dispatch) {
      notify({
        status: 'error',
        errorMessage: 'File upload failed. Please try again.',
        dispatch,
      });
    }
  }
  return null;
};
export type FileWithPath = File & { path: string };

function isFileWithPath(file: File | FileWithPath): file is FileWithPath {
  return 'path' in file;
}

export interface S3AmplifyOptions {
  level?: string;
  progressCallback?: (progress: ProgressData) => void;
}

export interface UploadResponse {
  ETag?: string;
  Location?: string;
  key?: string;
  Bucket?: string;
}

export interface UploadFilesToS3Input {
  acceptedFiles: any;
  targetPath?: string;
  startUploadFiles: () => void;
  exitUploadFiles: () => void;
  setUploadProgress: (fileProgress: UploadProgressProps) => void;
  filePathToS3?: string;
  modifiedFolderUploadBasePath?: string;
  ignoreFolders?: boolean;
  isUserFiles?: boolean;
  dispatch?: Dispatch;
}

interface ProgressData {
  loaded: number;
  totalSize: number;
  total?: number;
}

export type S3File = File | FileWithPath;

/**
 * get all the directories possible for a filepath "A/B/C" return "A", "A/B", "A/B/C"
 * @param targetPath prefix to each of the subpaths
 * @param filePath full filepath of an uploaded file
 * */
export const getAllSubpaths = (targetPath: string, filePath: string) => {
  const pathParts: string[] = filePath.split('/');
  const subpaths: string[] = [];
  for (let idx = 0; idx < pathParts.length; idx += 1) {
    if (idx > 0) {
      subpaths.push(
        `${targetPath ? `${targetPath}/` : ''}${pathParts
          .slice(0, idx)
          .join('/')}`,
      );
    }
  }
  return subpaths;
};

/**
 * get a finalized list of items ready to be uploaded as FILE_METADATA objects in the DB based on this S3 upload
 * @param uploadedFiles as a result of S3 upload action
 * @param folderPaths determined from properties on File objects input by the user
 * @param targetPath a prefix path for all files undergoing this given action
 * */
export const getFileMetadataObjects = (
  uploadedFiles: any[],
  folderPaths: string[],
  targetPath: string,
) => {
  const formattedTargetPath =
    targetPath && targetPath.endsWith('/')
      ? targetPath.replace(/\/$/, '')
      : targetPath;
  const allPaths = new Set<string>(); // will contain a set of strings to be used to create placeholder folder nodes included in API request
  const formattedUploadedFiles = uploadedFiles.map((file, idx) => {
    let filePathParts: string[] = [];
    if (formattedTargetPath) {
      filePathParts.push(formattedTargetPath);
    }
    if (folderPaths[idx]) {
      // if webkitRelativePath/path property is present, this should be a folder upload and we need to check if path property needs additions
      const directoryPath: string = folderPaths[idx]
        .split('/')
        .slice(0, -1)
        .join('/');
      if (directoryPath) {
        // this "if" conditional will be entered if the file object that has been uploaded had a "path" property from being inside a folder when being uploaded
        filePathParts = filePathParts.concat(directoryPath.split('/'));
        // add the full path on this file to the set of "allPaths" to help create folder placeholder objects later
        allPaths.add(
          `${
            formattedTargetPath ? `${formattedTargetPath}/` : ''
          }${directoryPath}`,
        );
      }
      // add all possible path combinations for this given file to the set of strings
      getAllSubpaths(formattedTargetPath, directoryPath).forEach(
        (subPath: string) => {
          allPaths.add(subPath);
        },
      );
    }
    return {
      ...file,
      path: filePathParts.join('/'),
    };
  });
  // for all relative paths, we need to create a file metadata object in database to represent the folder node
  allPaths.forEach((relPath) =>
    formattedUploadedFiles.push({ key: FOLDER_FILE_KEY, path: relPath }),
  );
  return formattedUploadedFiles;
};

/**
 * in a folder upload, get an ordered list of the relative folder paths associated with each uploaded file
 * @param files if originating from a file upload they will have either path or webkitrelativepath set
 * */
export const getFilePathList = (
  files: S3File[],
  modifiedFolderUploadBasePath: string,
  ignoreFolders: boolean,
) =>
  files.map((file) => {
    let originalPath = '';
    if (!ignoreFolders) {
      originalPath = isFileWithPath(file)
        ? file.path.substring(1)
        : file.webkitRelativePath;
    }

    if (originalPath && modifiedFolderUploadBasePath) {
      return [modifiedFolderUploadBasePath]
        .concat(originalPath.split('/').slice(1))
        .join('/');
    }
    return originalPath;
  });

export default class S3Utils {
  static uploadAdCreative(
    userId: string,
    campaignName: string,
    fileWithType: { file: File; format: string },
  ) {
    const fileKey = `${userId}/${campaignName}/${fileWithType.format
      .split('/')[0]
      .replace(/\s/g, '')}/${fileWithType.file.name}`;

    return this.uploadFile(fileKey, fileWithType.file, {});
  }

  static uploadPackageAttachment(attachmentField: string, file: File) {
    const fileKey = `${attachmentField}/${file.name}`;

    return this.uploadFile(fileKey, file, { level: 'protected' });
  }

  static async uploadFile(
    key: string,
    file: File,
    options: S3AmplifyOptions,
    dispatch?: Dispatch,
  ): Promise<any> {
    const uploadFunction = () => {
      return Storage.put(key, file, {
        ...options,
        contentType: file.type,
      } as any);
      // Had to type as any because we're missing type definition for S3ProviderPutConfig that comes with 'aws-amplify/storage'
    };

    try {
      return await uploadFunction();
    } catch (err) {
      handleFileUploadFailure(err, uploadFunction, dispatch);
    }
  }

  static getFile(key: string, options = {}) {
    return Storage.get(key, options);
  }

  static listFiles(path: string, options = {}) {
    return Storage.list(path, { track: true, ...options });
  }

  static removeFile(key: string) {
    return Storage.remove(key);
  }

  static getFileUrlWithSignedKey = async (
    key: string,
    signingRoute: string,
  ) => {
    const s3presignedUrl = await API.get('AppAPI', signingRoute, {
      queryStringParameters: {
        key,
      },
      responseType: 'text',
    });
    return s3presignedUrl;
  };

  static downloadFileWithSignedKey = async (
    key: string,
    signingRoute: string,
  ) => {
    const s3presignedUrl = await API.get('AppAPI', signingRoute, {
      queryStringParameters: {
        key,
      },
      responseType: 'text',
    });

    const config: AxiosRequestConfig = { responseType: 'blob' };
    return Axios.get(s3presignedUrl, config);
  };

  static async uploadFileWithSignedKey(
    file: File,
    key: string,
    signingRoute: string,
    progressCallback?: (loaded: number, total: number) => void,
    dispatch?: Dispatch,
  ): Promise<SignedUploadResult> {
    const s3presignedUrl = await API.get('AppAPI', signingRoute, {
      queryStringParameters: {
        key,
      },
      responseType: 'text',
    });

    try {
      await Axios.put(s3presignedUrl, file, {
        headers: {
          'Content-Type': file.type,
        },
        onUploadProgress: (progressEvent) => {
          if (progressCallback) {
            progressCallback(progressEvent.loaded, progressEvent.total);
          }
        },
      });
      return {
        // get base path without including the AWS generated signatures
        Location: s3presignedUrl.split('?')[0],
      };
    } catch (error) {
      handleFileUploadFailure(error, undefined, dispatch);
      return {
        Location: '',
      };
    }
  }

  static async uploadPortalFile(
    file: File,
    key: string,
    progressCallback?: (loaded: number, total: number) => void,
    dispatch?: Dispatch,
  ): Promise<SignedUploadResult> {
    const s3presignedUrl = await API.get('AppAPI', `/upload/signedUrl`, {
      queryStringParameters: {
        key,
        access: 'public',
      },
      responseType: 'text',
    });

    try {
      await Axios.put(s3presignedUrl, file, {
        headers: {
          'Content-Type': file.type,
        },
        onUploadProgress: (progressEvent) => {
          if (progressCallback) {
            progressCallback(progressEvent.loaded, progressEvent.total);
          }
        },
      });
      return {
        Location: s3presignedUrl.split('?')[0],
      };
    } catch (error) {
      handleFileUploadFailure(error, undefined, dispatch);
      return {
        Location: '',
      };
    }
  }

  static async uploadFilesToS3(uploadInput: UploadFilesToS3Input) {
    const {
      acceptedFiles,
      targetPath = '',
      startUploadFiles,
      exitUploadFiles,
      setUploadProgress,
      filePathToS3,
      modifiedFolderUploadBasePath = '',
      ignoreFolders = false,
      isUserFiles = true,
      dispatch,
    } = uploadInput;
    let files: Array<File> = [];
    if (acceptedFiles.target && acceptedFiles.target.files) {
      files = [...acceptedFiles.target.files];
    } else {
      files = acceptedFiles;
    }
    files = files.filter((f) => !f.name.startsWith('.'));
    const isoDate = new Date().toISOString();
    const path = filePathToS3 || `files/${isoDate}`;

    const loaded: Array<number> = files.map(() => 0);
    let totalSize = 0;

    if (files && files.length > 0) {
      totalSize = files.reduce((sum, { size }) => sum + size, 0);
    }

    if (totalSize > 0) {
      startUploadFiles();
    }

    try {
      const uploadedFiles = await Promise.all(
        files.map(async (file: any, index: number) => {
          const fileKeyComponents: string[] = [path];
          if (file.webkitRelativePath) {
            // this property may look like "casper/icon.png" thus creating a file key like files/2021-08-25T13:53:09.443Z/casper/icon.png/icon.png
            // while it looks strange, this will ensure that file keys for files with the same name in different directories unique
            fileKeyComponents.push(file.webkitRelativePath);
          }
          fileKeyComponents.push(file.name);
          const filePath = `${fileKeyComponents.join('/')}`;
          const progressCallback = (progressData: ProgressData) => {
            // Check if loaded chunk has size greater than the last loaded chunk size
            // This check is needed to avoid final entry of S3 Multipart upload event that emits load chunk size less than that of total file size.
            loaded[index] =
              loaded[index] < progressData.loaded
                ? progressData.loaded
                : loaded[index];

            setUploadProgress({
              totalSize,
              loaded,
            });
          };

          // when files we're going to upload are
          // belonging to unique user, we need to store
          // them in the protected s3 bucket, otherwise
          // we store them in the public s3 bucket
          return !isUserFiles
            ? this.uploadPortalFile(
                file,
                `${targetPath}/${file.name}`,
                (progress, total) => {
                  progressCallback({ loaded: progress, totalSize: total });
                },
                dispatch,
              )
            : this.uploadFile(
                filePath,
                file,
                {
                  level: 'protected',
                  progressCallback,
                },
                dispatch,
              );
        }),
      );
      const folderPaths: string[] = getFilePathList(
        files,
        modifiedFolderUploadBasePath,
        ignoreFolders,
      );
      return getFileMetadataObjects(uploadedFiles, folderPaths, targetPath);
    } catch (err) {
      console.error('S3 upload failed', err);
      exitUploadFiles();
      return [];
    }
  }

  static getBlobFromS3Url = async (url: string) => {
    try {
      // Fetch the file from S3
      const response = await fetch(url);

      // Check if the request was successful
      if (!response.ok) {
        throw new Error(`Failed to fetch file: ${response.statusText}`);
      }

      // Convert the file to a Blob
      const blob = await response.blob();

      // Create a Blob URL
      const blobUrl = URL.createObjectURL(blob);

      return blobUrl;
    } catch (error) {
      console.error('Error creating blob URL:', error);
      return null;
    }
  };
}
