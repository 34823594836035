import React from 'react';
import { RouteContext } from 'src/context';
import { useRouteAccess } from 'src/routes/routeAccess';
import { PageContent } from 'src/legacy/components/UI';

export interface PageLayoutProps {
  routeAccessCheck?: keyof ReturnType<typeof useRouteAccess>;
  noToolbar?: boolean;
  fullpage?: boolean;
  noSpace?: boolean;
}

export const PageLayout: React.FC<PageLayoutProps> = ({
  routeAccessCheck,
  children,
  noToolbar = false,
  fullpage = false,
  noSpace = false,
}) => {
  const routeAccess = useRouteAccess();
  const { pathname } = React.useContext(RouteContext);
  const isSettingsPage = pathname.startsWith('/settings');
  return (
    <PageContent
      allowed={routeAccessCheck ? routeAccess[routeAccessCheck] : undefined}
      noToolbar={noToolbar}
      fullPage={fullpage}
      noSpace={noSpace}
      isSettingsPage={isSettingsPage}
    >
      {children}
    </PageContent>
  );
};
