import React, { useState } from 'react';
import { Theme, makeStyles, useMediaQuery, useTheme } from '@material-ui/core';
import { ColDef } from '@ag-grid-community/core';
import { FiSend as SendIcon } from 'react-icons/fi';
import { useSelector } from 'react-redux';
import {
  useArchiveContractTemplateMutation,
  ContractTemplate,
  useGetContractTemplatesQuery,
} from 'src/services/api';
import StandardPage from 'src/legacy/components/UI/StandardPage';
import { LoadingWrapper } from 'src/legacy/components/Loading';
import AgGrid, { TextCellRenderer } from 'src/legacy/components/AgGrid';
import { BaseActionsMenu } from 'src/legacy/components/Dropdowns';
import { TrashIcon } from 'src/legacy/components/Icons';
import { RootState } from 'src/store';
import history from 'src/history';
import { CONTRACTS_PAGE, CONTRACT_DETAILS_PAGE } from 'src/constants';
import { ContractDetailsPageView } from './ContractDetailsPage';
import { ShareContractModal } from './ShareContractModal';
import { sortContractTemplatesByCreatedDate } from './contract_helpers';
import { LocaleDateFormatter } from '../Formatters';
import { NonDesktopActionTooltip } from 'src/legacy/components/Tooltip';
import { CreateButton } from 'src/legacy/components/UI';
import { useModuleIcon } from 'src/hooks/useModuleIcon';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    marginTop: theme.spacing(1.5),
    paddingLeft: theme.spacing(4.5),
    height: '100%',
    [theme.breakpoints.down('sm')]: {
      padding: 0,
      marginTop: 0,
    },
  },
}));

export const templateListcolumns: ColDef[] = [
  {
    headerName: 'Name',
    field: 'name',
    minWidth: 300,
    sortable: true,
  },
  {
    headerName: 'Waiting for submission',
    field: 'requestsCount',
    minWidth: 200,
    sortable: true,
    cellClass: 'rightAlignedColumn',
    headerClass: 'rightAlignedHeader',
    cellRenderer: 'textCellRenderer',
  },
  {
    headerName: 'Submissions',
    field: 'submissionsCount',
    minWidth: 200,
    sortable: true,
    cellClass: 'rightAlignedColumn',
    headerClass: 'rightAlignedHeader',
    cellRenderer: 'textCellRenderer',
  },
  {
    headerName: 'Latest submission',
    field: 'latestSubmissionDate',
    minWidth: 200,
    sortable: true,
    cellClass: 'rightAlignedColumn',
    headerClass: 'rightAlignedHeader',
    cellRenderer: 'textCellRenderer',
    getQuickFilterText(filterParams) {
      return new Date(filterParams.data.sentOn).toLocaleDateString();
    },
    valueFormatter: LocaleDateFormatter,
  },
];

interface TemplateActionMenuProps {
  data: ContractTemplate;
  handleShareModalOpen: (data: ContractTemplate) => void;
}

/**
 * Component to render the table row action for contract templates
 */
const TemplateActionMenu = ({
  data,
  handleShareModalOpen,
}: TemplateActionMenuProps) => {
  const [archiveTemplate] = useArchiveContractTemplateMutation();

  return (
    <BaseActionsMenu
      actions={[
        {
          name: 'Share',
          icon: <SendIcon />,
          onClick: () => handleShareModalOpen(data),
        },
        {
          name: 'Archive',
          icon: <TrashIcon />,
          onClick: () => archiveTemplate({ contractTemplateId: data.id }),
          isDelete: true,
        },
      ]}
    />
  );
};

export const TemplateListTable = () => {
  const theme = useTheme();
  const isSm = useMediaQuery(theme.breakpoints.down('md'));
  const [shareModalInfo, setShareModalInfo] = useState<{
    isOpen: boolean;
    selectedTemplate: ContractTemplate | undefined;
  }>({
    isOpen: false,
    selectedTemplate: undefined,
  });

  const classes = useStyles();
  const isDesktopScreen = useMediaQuery(theme.breakpoints.up('md'));
  const { data, isLoading: areTemplatesLoading } =
    useGetContractTemplatesQuery();

  const Icon = useModuleIcon('contracts');

  const templates = sortContractTemplatesByCreatedDate(data);

  /**
   * Opens the contract share modal.
   * If the selected template has variable inputs, the modal will render those inputs.
   * @param rowData - Selected template information
   */
  const handleShareModalOpen = (rowData: ContractTemplate) => {
    if (!rowData) return;

    setShareModalInfo({
      isOpen: true,
      selectedTemplate: rowData,
    });
  };

  const handleShareModalClose = () => {
    setShareModalInfo({
      isOpen: false,
      selectedTemplate: undefined,
    });
  };

  const handleRowClick = (rowData: ContractTemplate) => {
    const { id } = rowData;
    history.push(
      `${CONTRACT_DETAILS_PAGE.path}?templateId=${id}&view=${ContractDetailsPageView.SubmissionRequests}`,
    );
  };

  const handleClickNewContract = () => {
    history.push(`${CONTRACTS_PAGE.path}/edit`);
  };

  const searchKey = useSelector((state: RootState) => state.ui.searchValue);

  const shouldShowStandardPage = !templates || templates.length === 0;

  return (
    <LoadingWrapper isLoading={areTemplatesLoading} hideContentWhileLoading>
      {shouldShowStandardPage ? (
        <StandardPage
          topIcon={<Icon style={{ fontSize: 24 }} />}
          noClientTitle="Before sharing contracts, create a client"
          noClientDesc="You can share contracts from your Copilot Dashboard, but you need to first create a client. Do so by clicking the button below."
          title="No contracts have been created yet"
          desc="This is where you can see contracts. To send a contract to a client, create your first one below."
          actionButton={
            <NonDesktopActionTooltip>
              <CreateButton
                htmlId="btn-create-contracts-template"
                onClick={handleClickNewContract}
                disabled={!isDesktopScreen}
              >
                New contract
              </CreateButton>
            </NonDesktopActionTooltip>
          }
        />
      ) : (
        <div className={classes.root}>
          <AgGrid
            className="agGridRowHover"
            rowData={templates}
            columnDefs={[
              ...templateListcolumns,
              {
                colId: 'actions',
                width: 150,
                headerName: 'Actions',
                suppressSizeToFit: true,
                pinned: isSm ? 'right' : null,
                cellRenderer: 'templateActionRenderer',
                cellRendererParams: {
                  handleShareModalOpen,
                },
              },
            ]}
            searchKey={searchKey}
            frameworkComponents={{
              templateActionRenderer: TemplateActionMenu,
              textCellRenderer: TextCellRenderer,
            }}
            onRowClicked={handleRowClick}
          />
          <ShareContractModal
            open={shareModalInfo.isOpen}
            selectedTemplate={shareModalInfo.selectedTemplate}
            onClose={handleShareModalClose}
          />
        </div>
      )}
    </LoadingWrapper>
  );
};
