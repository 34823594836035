import React, { useContext, useEffect } from 'react';
import { PortalConfigContext } from 'src/context';
import PortalLogo from 'src/legacy/components/Layout/PortalLogo';
import { AuthScreenPortalBadge } from 'src/legacy/components/Auth/AuthScreenPortalBadge';
import { getLoginImageURL } from 'src/utils/ImageUtils';
import PaymentsClient from 'src/clients/PaymentsClient';
import { canDisablePortalBadge } from 'src/utils/PlansUtils';
import PortalFullLogo from 'src/legacy/components/Layout/PortalFullLogo';
import { useMediaQuery } from 'src/hooks/useMediaQuery';
import BaseTypography from 'src/legacy/components/Text/BaseTypography';

const GOOGLE_AUTH_CARD_HEIGHT = 580;
const AUTH_CARD_HEIGHT = 500;

interface AuthContainerProps {
  title?: string;
  description?: string;
  children: React.ReactNode;
}

export const ClientAuthContainer: React.FC<AuthContainerProps> = ({
  title,
  description,
  children,
}) => {
  const portalConfig = useContext(PortalConfigContext);
  const [shouldShowPortalBadge, setShouldShowPortalBadge] =
    React.useState(false);
  const googleAuthEnabled = !portalConfig.features.disableGoogleSignIn;
  const isMobileScreen = useMediaQuery('sm', 'max');
  const hasBannerImage = useMediaQuery('xl', 'min');

  /**
   * Checks portal plan status to determine if the portal badge should be shown.
   * For pro plans, the badge visibility is controlled by portal config.
   * For non-pro plans, the badge is always shown.
   * This helps maintain branding requirements based on subscription level.
   */
  const checkPortalStatus = async () => {
    const portalPlanStatusData = await PaymentsClient.getPortalPlan();
    const { isPro, isLegacyPaidPlan, planType } = portalPlanStatusData;
    const canDisableBadge = canDisablePortalBadge({
      isPro,
      isLegacyPaidPlan,
      planType,
    });
    setShouldShowPortalBadge(
      canDisableBadge ? !portalConfig.MarketingSite.disableBadge : true,
    );
  };

  React.useEffect(() => {
    checkPortalStatus();
  }, []);

  // The image height and width depends on whether google auth is enabled or not.
  // For google auth enabled: 580px height to accommodate the additional button
  // For standard auth: 500px height is sufficient
  const coverImageSize = portalConfig.features.disableGoogleSignIn
    ? AUTH_CARD_HEIGHT
    : GOOGLE_AUTH_CARD_HEIGHT;

  /**
   * Generates the auth image URL with proper sizing based on device pixel ratio.
   * Uses a multiplier capped at 2x to prevent loading unnecessarily large images
   * while still supporting high DPI displays.
   * @returns {string} Optimized auth image URL with sizing parameters
   */
  const getAuthImageSrc = () => {
    const devicePixelRatio =
      typeof window === 'undefined' ? 1 : window.devicePixelRatio;
    const pxRatioMultiplier = Math.min(devicePixelRatio, 2);
    return getLoginImageURL(portalConfig.assets.authImage.signInUrl || '', {
      resize: {
        width: coverImageSize * pxRatioMultiplier,
        height: coverImageSize * pxRatioMultiplier,
      },
    });
  };

  // Track image loading state to handle fade-in animation
  const [isImageLoaded, setIsImageLoaded] = React.useState(false);
  const imageSrc = getAuthImageSrc();
  useEffect(() => {
    if (!hasBannerImage) return;

    // Preload image to ensure smooth transition
    const image = new Image();
    image.src = imageSrc;

    const handleImageLoaded = () => {
      setIsImageLoaded(true);
    };
    image.addEventListener('load', handleImageLoaded);

    return () => {
      image.removeEventListener('load', handleImageLoaded);
    };
  }, [getAuthImageSrc]);

  // Only show the full logo if it's configured in portal settings
  // and not using a dummy image placeholder
  const showFullLogo =
    Boolean(portalConfig.assets.logo.logoImageUrl) &&
    !portalConfig.assets.logo.logoImageUrl.includes('dummyimage');

  return (
    <div
      className={[
        'h-full',
        'flex',
        'items-center',
        'justify-center',
        'flex-col',
        'm-0',
        'm-auto',
        'sm:max-w-lg',
        'xl:max-w-6xl',
      ].join(' ')}
    >
      <div
        className={[
          'drop-shadow-sm',
          'rounded-lg',
          'bg-white',
          'flex',
          'relative',
          'overflow-hidden',
          'h-full',
          'w-full',
          'min-w-96',
          googleAuthEnabled ? 'xl:w-[1120px]' : 'xl:w-[1000px]',
          googleAuthEnabled ? 'xl:h-[580px]' : 'xl:h-[500px]',
        ].join(' ')}
      >
        <div
          className={[
            'flex',
            'w-full',
            'flex-col',
            'h-full',
            'xl:w-1/2',
            'pt-16',
            'px-16',
            'pb-12',
            'box-border',
            '[&>form]:grow-0',
          ].join(' ')}
        >
          <div
            className={[
              'flex',
              'justify-center',
              'items-center',
              'w-full',
              'mx-auto',
              title ? 'mb-0' : 'mb-10',
            ].join(' ')}
          >
            {showFullLogo ? <PortalFullLogo logoHeight={60} /> : <PortalLogo />}
          </div>
          {title && (
            <div
              className={[
                'w-full',
                'flex',
                'items-center',
                'flex-col',
                'mt-4',
                'mx-0',
                'mb-10',
                'gap-2',
              ].join(' ')}
            >
              <BaseTypography fontType="18Medium">{title}</BaseTypography>
              {description && (
                <BaseTypography className="text-slate-500" fontType="13Regular">
                  {description}
                </BaseTypography>
              )}
            </div>
          )}

          {children}
          {isMobileScreen && shouldShowPortalBadge ? (
            <AuthScreenPortalBadge />
          ) : null}
        </div>
        <div
          className={[
            'hidden',
            'xl:flex',
            'xl:w-1/2',
            'bg-cover',
            'bg-center',
            'text-white',
            'flex-col',
            'justify-end',
            'transition-opacity',
            'border-r-8',
            isImageLoaded ? 'opacity-100' : 'opacity-0',
          ].join(' ')}
          style={{
            backgroundImage: `url(${imageSrc})`,
          }}
        />
      </div>
      <div className="min-h-20">
        {!isMobileScreen && shouldShowPortalBadge ? (
          <AuthScreenPortalBadge />
        ) : null}
      </div>
    </div>
  );
};
