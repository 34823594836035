import { sortBy } from 'lodash';
import ClientsClient from 'src/clients/ClientsClient';
import { AppThunkAction } from 'src/store/reduxTypes';

import {
  Client,
  Company,
  LOAD_CLIENTS_SUCCESS,
  LOAD_COMPANIES_SUCCESS,
  ADD_CLIENT_REQUEST,
  ADD_CLIENT_SUCCESS,
  ADD_CLIENT_FAILURE,
  ADD_COMPANY_REQUEST,
  INVITE_CLIENT_REQUEST,
  INVITE_CLIENT_SUCCESS,
  INVITE_CLIENT_FAILURE,
  UPDATE_CLIENT_REQUEST,
  UPDATE_CLIENTS_SUCCESS,
  UPDATE_CLIENT_FAILURE,
  DELETE_CLIENTS_REQUEST,
  DELETE_CLIENTS_SUCCESS,
  DELETE_CLIENTS_FAILED,
  UPDATE_ASSIGNED_CLIENTS,
  UPDATE_COMPANY_REQUEST,
  UPDATE_COMPANY_SUCCESS,
  UPDATE_COMPANIES_SUCCESS,
  UPDATE_COMPANY_FAILURE,
  DELETE_COMPANY_REQUEST,
  DELETE_COMPANIES_SUCCESS,
  DELETE_COMPANY_FAILED,
  CLEAR_CLIENTS,
  ClientFormData,
  CompanyFormData,
  ADD_COMPANY_SUCCESS,
  ADD_COMPANY_FAILURE,
  ClientsActionTypes,
  SET_CLIENT_CUSTOM_FIELDS,
  CustomFieldEntity,
  LOAD_CLIENT_CUSTOM_FIELDS_REQUEST,
  UPDATE_CLIENT_CUSTOM_FIELDS_REQUEST,
  CustomFieldOption,
} from 'src/store/clients/types';
import * as ClientsStoreTypes from 'src/store/clients/types';
import UsersClient from 'src/clients/UsersClient';
import ApiUtils from 'src/utils/ApiUtils';
import { getCompanyId, isActive } from 'src/utils/UserUtils';
import { CallApiWithNotification } from 'src/clients/ApiService';
import * as UserStoreTypes from 'src/store/user/types';
import * as UsersStoreTypes from 'src/store/users/types';
import { ColumnSettingsType } from 'src/constants/componentTypes/tableTypes';
import { GetCurrentUser } from 'src/store/users/reducers';
import { Dispatch } from 'redux';
import { ensureApiError, ensureError } from 'src/utils/Errors';
import { analyticsApi } from 'src/services/api/analyticsApi';
import { ApiTags } from 'src/services/api';
import { inboxApi } from 'src/services/api/inboxApi';

export const setClientsAction =
  ({
    clients,
    userId,
    isClient: isCurrentUserClient,
  }: {
    clients: Client[];
    userId?: string;
    isClient?: boolean;
  }): AppThunkAction =>
  async (dispatch, getState) => {
    const currentUserId = userId || getState().user.id;
    const isClient = isCurrentUserClient ?? getState().user.isClient;
    const usersState = getState().users;
    const currentUser = GetCurrentUser({ usersState, currentUserId, isClient });
    const filterAssigned = currentUser?.isClientAccessLimited;
    if (!currentUser) {
      console.info('No current user found for state user id', currentUserId);
    }
    const assignedCompaniesIDs = Object.keys({
      ...(currentUser?.additionalFields?.assignedToCompanies || {}),
      ...(currentUser?.additionalFields?.leadOfCompanies || {}),
    }).map((key) => key);

    const activeClients = clients.filter((c) => isActive(c));
    const assignedClients = filterAssigned
      ? activeClients.filter((client) =>
          assignedCompaniesIDs.includes(client.fields?.companyId || ''),
        )
      : activeClients;

    dispatch({
      type: LOAD_CLIENTS_SUCCESS,
      clients,
      activeClients,
      assignedClients,
    });
  };

export const setCompaniesAction = (companies: Company[]) => ({
  type: LOAD_COMPANIES_SUCCESS,
  payload: companies,
});

export const handleInsertedCompanies = (
  companies: Company[],
): ClientsActionTypes => ({
  type: ADD_COMPANY_SUCCESS,
  payload: companies,
});

export const addClient =
  (
    clientData: ClientFormData,
    options?: UpdateActionOptions,
  ): AppThunkAction<Promise<Client | null>> =>
  async (dispatch) => {
    const { showSuccessMessage } = options || {};
    function request() {
      return { type: ADD_CLIENT_REQUEST };
    }

    function success(payload: Client) {
      const clients: Client[] = [payload];
      return { type: ADD_CLIENT_SUCCESS, payload: clients };
    }

    function failure(error: string) {
      return { type: ADD_CLIENT_FAILURE, error };
    }

    dispatch(request());

    const result = await CallApiWithNotification<{
      createdItems: [Client, Company] | [Client];
    }>({
      executeFunction: ClientsClient.addClient,
      checkFunction: ApiUtils.IsBatchCreateResultSuccessful,
      params: clientData,
      successMessage: showSuccessMessage
        ? `${clientData && clientData.cognitoFirstName} has been created.`
        : '',
      errorMessage: `${
        clientData && clientData.cognitoFirstName
      } could not be created.`,
      dispatch,
    });

    if (result.status) {
      const client = result.data.createdItems.at(0);
      if (client) {
        dispatch(success(client as Client));
        const company = result.data.createdItems.at(1);
        if (company) {
          // the second item is a company and we need to pass array to insert company action
          dispatch(handleInsertedCompanies([company as Company]));
        }
        dispatch(analyticsApi.util.invalidateTags([ApiTags.analyticsClients]));
        return client as Client;
      }
    }
    const error = ensureApiError(result.data);
    dispatch(failure(error.message));
    return null;
  };

export const inviteClient =
  (
    userId: string,
    userFirstName: string,
    options?: Omit<UpdateActionOptions, 'isOptimisticChange'>,
  ) =>
  async (dispatch: Dispatch) => {
    const { showSuccessMessage } = options || {};

    function request() {
      return { type: INVITE_CLIENT_REQUEST };
    }

    function success(invitedUserId: string, invitedByUserId: string) {
      return { type: INVITE_CLIENT_SUCCESS, invitedUserId, invitedByUserId };
    }

    function failure(error: string) {
      return { type: INVITE_CLIENT_FAILURE, error };
    }

    dispatch(request());

    const result = await CallApiWithNotification({
      executeFunction: ClientsClient.inviteClient,
      params: userId,
      successMessage: showSuccessMessage
        ? `${userFirstName} was sent an email to activate their account.`
        : '',
      errorMessage: `We could not invite ${userFirstName}.`,
      dispatch,
    });

    if (result.status) {
      dispatch(success(userId, result.data.fields.invitedBy));
      return result.data;
    }
    dispatch(failure(result.data));
    return null;
  };

export const clearClientsAction = () => ({
  type: CLEAR_CLIENTS,
});

export const handleInsertedClients =
  (clients: Client[], isWsEvent?: boolean): AppThunkAction =>
  async (dispatch, getState) => {
    const {
      user: { isClient },
    } = getState();

    if (isClient && clients && clients.length > 0) {
      dispatch({
        type: UsersStoreTypes.ADD_USER_SUCCESS,
        payload: {
          user: clients.at(0),
          isClient,
        },
      });
    }

    dispatch({
      type: ADD_CLIENT_SUCCESS,
      payload: clients,
      isWsEvent,
    });
  };

export const handleModifiedClients =
  (clients: Client[], isWsEvent: boolean): AppThunkAction =>
  async (dispatch, getState) => {
    const { companies } = getState().clients;
    const authenticatedUserID = getState().user.data?.userId || '';

    const authedUserInModifiedUsers = clients.find(
      (c) => c.id === authenticatedUserID,
    );
    if (authedUserInModifiedUsers) {
      dispatch({
        type: UserStoreTypes.UPDATED_USER_ATTRIBUTE,
        attributes: authedUserInModifiedUsers.fields,
      });
    }
    dispatch({
      type: UPDATE_ASSIGNED_CLIENTS,
      payload: {
        assignedCompanies: companies.map((c) => c.id),
        clients,
      },
    });

    dispatch({
      type: UPDATE_CLIENTS_SUCCESS,
      payload: clients,
      isWsEvent,
    });
  };

export const handleModifiedCompanies = (companies: Company[]) => ({
  type: UPDATE_COMPANIES_SUCCESS,
  payload: companies,
});

export const handleDeletedClients =
  (clients: Client[], isWsEvent?: boolean): AppThunkAction =>
  (dispatch) => {
    const deletedClientIds = clients.map((c) => c.id);
    // filter out any notifications from the inbox that are related to the deleted user
    dispatch(
      inboxApi.util.updateQueryData(
        'getInboxNotifications',
        undefined,
        (draft) => {
          return draft.filter((notification) => {
            return !deletedClientIds.includes(notification.senderId);
          });
        },
      ),
    );

    dispatch({
      type: DELETE_CLIENTS_SUCCESS,
      payload: deletedClientIds,
      isWsEvent,
    });
  };

export const handleDeletedCompanies = (companies: Company[]) => ({
  type: DELETE_COMPANIES_SUCCESS,
  payload: companies.map((c) => c.id),
});

export const deleteClient =
  (user: Client): AppThunkAction =>
  async (dispatch) => {
    function request() {
      return { type: DELETE_CLIENTS_REQUEST };
    }

    function success(payload: string[]) {
      return { type: DELETE_CLIENTS_SUCCESS, payload };
    }

    function failure(error: string) {
      return { type: DELETE_CLIENTS_FAILED, error };
    }
    dispatch(request());
    // optimistically delete client
    dispatch(success([user.id]));

    const result = await CallApiWithNotification({
      executeFunction: ClientsClient.deleteUser,
      checkFunction: ApiUtils.IsBatchUpdateResultSuccessful,
      params: user,
      successMessage: `${
        user ? `${user.fields.givenName} ${user.fields.familyName}` : ''
      } has been deleted.`,
      errorMessage: `${
        user && user.owner && user.fields.givenName
      } could not be deleted.`,
      dispatch,
    });

    if (result.status) {
      const userIDs: string[] = [user.id];
      dispatch(success(userIDs));
      dispatch(analyticsApi.util.invalidateTags([ApiTags.analyticsClients]));
    } else {
      dispatch(failure(result.data));
    }
  };
export const setLastVisitedCrmTab = (tab: string) => ({
  type: ClientsStoreTypes.SET_CRM_LAST_VISITED_TAB,
  payload: tab,
});

export const deleteCompany =
  (company: Company): AppThunkAction =>
  async (dispatch) => {
    function request() {
      return { type: DELETE_COMPANY_REQUEST };
    }

    function success(payload: string) {
      return { type: DELETE_COMPANIES_SUCCESS, payload: [payload] };
    }

    function failure(error: string) {
      return { type: DELETE_COMPANY_FAILED, error };
    }

    dispatch(request());
    // optimistically delete company
    dispatch(success(company.id));

    const result = await CallApiWithNotification({
      executeFunction: ClientsClient.deleteCompany,
      checkFunction: ApiUtils.IsBatchUpdateResultSuccessful,
      params: company,
      successMessage: `${
        company && company.fields && company.fields.name
      } has been deleted.`,
      errorMessage: `${
        company && company.fields && company.fields.name
      } could not be deleted.`,
      dispatch,
    });

    if (result.status) {
      dispatch(success(company.id));
    } else {
      dispatch(failure(result.data));
    }
  };

/**
 * handle udpating company with calling api and snack bar notification
 * @param clientData udpated company data
 * @param successMessage success message for snackbar notification
 * @param errorMessage error message for snackbar notification
 * @param isOptimisticStateChange - optimistically change state before waiting response
 */

export const updateCompanyAction =
  (
    clientData: CompanyFormData,
    successMessage?: string,
    errorMessage?: string,
    isOptimisticStateChange?: boolean,
  ): AppThunkAction =>
  async (dispatch) => {
    function request() {
      return { type: UPDATE_COMPANY_REQUEST };
    }

    function success(payload: CompanyFormData) {
      return { type: UPDATE_COMPANY_SUCCESS, payload };
    }

    function failure(error: string) {
      return { type: UPDATE_COMPANY_FAILURE, error };
    }

    dispatch(request());

    if (isOptimisticStateChange) {
      dispatch(success(clientData));
    }
    const result = await CallApiWithNotification({
      executeFunction: ClientsClient.updateCompany,
      checkFunction: ApiUtils.IsBatchUpdateResultSuccessful,
      params: clientData,
      successMessage: successMessage || '',
      errorMessage: errorMessage || '',
      dispatch,
    });

    if (result.status) {
      if (!isOptimisticStateChange) {
        dispatch(success(clientData));
      }
    } else {
      dispatch(failure(result.data));
    }
  };

interface UpdateActionOptions {
  showSuccessMessage?: boolean;
  isOptimisticChange?: boolean;
}

/**
 * call updateCompanyAction with udpated company data and snackbar notification messages
 * @param clientData
 * @param options - Object type UpdateCompanyOptions. UpdateCompanyOptions.showSuccessMessage - show success snackbar or no
 * UpdateCompanyOptions.isOptimisticChange - optimistically change state before waiting response
 */
export const updateCompany =
  (
    clientData: CompanyFormData,
    options?: UpdateActionOptions,
  ): AppThunkAction =>
  async (dispatch) => {
    const { showSuccessMessage = true, isOptimisticChange } = options || {};
    const successMessage = showSuccessMessage
      ? 'Your changes have been saved.'
      : '';
    await dispatch(
      updateCompanyAction(
        clientData,
        successMessage,
        'We were unable to save the changes.',
        isOptimisticChange,
      ),
    );
  };

/**
 * updates the stripe customer id of the client company
 * calls updateCompanyAction without snackbar notifications because
 * this is triggered during adding payment method action
 * @param customerId stripe customer id
 */

export const updateCompanyCustomer =
  (userId: string, customerId: string): AppThunkAction =>
  async (dispatch, getState) => {
    const {
      user: { isClient },
      clients,
      users: { users },
    } = getState();
    const { companies, activeClients } = clients;

    let clientCompany = null;
    // if the current user is a client then we should access the companyID
    // from the current user and use that to update company customer
    if (isClient) {
      const companyId = getCompanyId(userId, users);
      if (companyId) {
        clientCompany = companies.find((company) => company.id === companyId);
      }
    } else {
      const fetchedClient = activeClients.find((c) => c.id === userId);
      if (fetchedClient) {
        clientCompany = companies.find(
          (comp) => comp.id === fetchedClient.fields.companyId,
        );
      }
    }

    if (clientCompany) {
      const clientData = {
        companyName: clientCompany.fields.name,
        companyId: clientCompany.id,
        isPlaceholder: clientCompany.fields.isPlaceholder,
        customerId,
      };
      await dispatch(updateCompanyAction(clientData));
    }
  };

export const updateClientUser =
  (clientData: ClientFormData, options?: UpdateActionOptions): AppThunkAction =>
  async (dispatch, getState) => {
    const { companies } = getState().clients;

    const { showSuccessMessage, isOptimisticChange } = options || {};
    function request() {
      return {
        type: UPDATE_CLIENT_REQUEST,
        updatingClientId: clientData.userId,
      };
    }

    function success(
      client: Partial<Client>,
      createdCompany: Partial<Company> | undefined,
    ) {
      const clients = [client];
      dispatch({
        type: UPDATE_CLIENTS_SUCCESS,
        payload: clients,
      });
      const allAssignedCompanyIDs: string[] = companies.map((c) => c.id);
      if (createdCompany && createdCompany.id) {
        allAssignedCompanyIDs.push(createdCompany.id);
      }
      return {
        type: UPDATE_ASSIGNED_CLIENTS,
        payload: {
          assignedCompanies: allAssignedCompanyIDs,
          clients,
        },
      };
    }

    function failure(error: string) {
      return {
        type: UPDATE_CLIENT_FAILURE,
        error,
        // should pass the failed updating client id
        updatingClientId: clientData.userId,
      };
    }
    dispatch(request());

    const updatingClient: Partial<Client> = {
      id: clientData.userId,
      updatedDate: new Date().toISOString(),
      fields: {
        companyId: clientData.companyId,
        companyName: clientData.companyName,
        customFields: clientData.customFields,
      } as any,
      additionalFields: clientData.additionalFields,
    };

    if (isOptimisticChange) {
      // in the event that we are clearing the companyID from the client we cannot know the placeholder company's id yet
      dispatch(success(updatingClient, undefined));
    }

    const result = await CallApiWithNotification({
      executeFunction: UsersClient.updateClientUser,
      checkFunction: ApiUtils.IsBatchUpdateResultSuccessful,
      params: clientData,
      successMessage: showSuccessMessage
        ? `${clientData && clientData.cognitoFirstName} was updated.`
        : '',
      errorMessage: `${
        clientData && clientData.cognitoFirstName
      } could not be updated.`,
      dispatch,
    });
    if (isOptimisticChange) {
      return;
    }
    if (result.status) {
      const [client, company] = result.data.createdItems;
      dispatch(success(client, company));
      if (company) {
        dispatch(handleInsertedCompanies([company]));
      }
    } else {
      dispatch(failure(result.data));
    }
  };

export const addCompany =
  (clientData: ClientFormData): AppThunkAction =>
  async (dispatch) => {
    function request() {
      return { type: ADD_COMPANY_REQUEST };
    }

    function failure(error: string) {
      return { type: ADD_COMPANY_FAILURE, error };
    }

    dispatch(request());

    const result = await CallApiWithNotification({
      executeFunction: ClientsClient.createCompany,
      checkFunction: ApiUtils.IsBatchCreateResultSuccessful,
      params: clientData,
      successMessage: `${
        clientData && clientData.companyName
      } has been created.`,
      errorMessage: `${
        clientData && clientData.companyName
      } could not be created.`,
      dispatch,
    });

    if (result.status) {
      dispatch(handleInsertedCompanies(result.data.createdItems));
    } else {
      dispatch(failure(result.data));
    }
  };

export const startLoadCustomFieldsAction = () => ({
  type: LOAD_CLIENT_CUSTOM_FIELDS_REQUEST,
});

export const setCustomFieldsAction = (
  payload: CustomFieldEntity | null,
  error: string,
  isRemove?: boolean,
): ClientsActionTypes => ({
  type: SET_CLIENT_CUSTOM_FIELDS,
  isRemove: Boolean(isRemove),
  payload,
  error,
});

export const loadClientCustomFields =
  (): AppThunkAction => async (dispatch) => {
    dispatch(startLoadCustomFieldsAction());

    try {
      const result = await ClientsClient.loadClientCustomFields();
      if (result) {
        dispatch(setCustomFieldsAction(result, ''));
      }
    } catch (error) {
      const { message } = ensureError(error);
      dispatch(setCustomFieldsAction(null, message));
    }
  };

export const startUpdateCustomFieldsAction = () => ({
  type: UPDATE_CLIENT_CUSTOM_FIELDS_REQUEST,
});

export const updateClientCustomFields =
  (
    additionalFieldsData: Record<string, CustomFieldOption>,
    isRemove?: boolean,
  ): AppThunkAction =>
  async (dispatch, getState) => {
    const portalCustomFields = getState().clients.clientCustomFields;
    if (!portalCustomFields) return;

    // this variable will hold the updated custom fields additional fields
    let updatedAdditionalFields = {};

    // removing custom field
    if (isRemove) {
      // ids of custom field that needs to be deleted
      const deletedFieldsId = Object.keys(additionalFieldsData);

      // updated additional fields after removing the ones which needs to be deleted
      updatedAdditionalFields = sortBy(
        Object.values(portalCustomFields?.additionalFields),
        'order',
      )
        .filter((field) => !deletedFieldsId.includes(field.id))
        .reduce(
          (accumulator, currentField, idx) => ({
            ...accumulator,
            [currentField.id]: {
              ...currentField,
              order: idx,
            },
          }),
          {},
        );
    } else {
      // updating or adding a new custom field
      updatedAdditionalFields = {
        ...(portalCustomFields?.additionalFields || {}),
        ...additionalFieldsData,
      };
    }

    const updatedPortalCustomFieldsData = {
      ...(portalCustomFields || {}),
      additionalFields: {
        ...updatedAdditionalFields,
      },
    };

    dispatch(startUpdateCustomFieldsAction());
    const result = await CallApiWithNotification({
      executeFunction: ClientsClient.updateClientCustomFields,
      params: updatedPortalCustomFieldsData,
      successMessage: 'Custom Fields have been updated',
      errorMessage: 'Custom Fields could not be updated',
      dispatch,
    });
    if (result && result.status) {
      dispatch(setCustomFieldsAction(result.data, '', isRemove));
    } else {
      dispatch(setCustomFieldsAction(null, result.errorMessage));
    }
  };

export const updateCrmTableColumnSetting = (
  columnSettings: Record<string, ColumnSettingsType>,
) => ({
  type: ClientsStoreTypes.UPDATE_CRM_TABLE_COLUMN_SETTINGS,
  columnSettings,
});
