import React from 'react';
import { useSnackbar } from 'notistack';
import { makeStyles, Theme, createStyles, IconButton } from '@material-ui/core';
import { PostOrPage } from '@tryghost/content-api';
import { typography15MediumStyle } from 'src/legacy/components/Text';
import BaseTypography, {
  typography15RegularStyle,
} from 'src/legacy/components/Text/BaseTypography';
import { BlackHeadings, GraySmall } from 'src/theme/colors';
import Button from 'src/legacy/components/Button';
import { PORTAL_UPDATES_URL } from 'src/constants/externalUrls';
import { RegularCardBox } from 'src/legacy/components/Cards/RegularCardBox';
import { CloseIcon } from 'src/legacy/components/Icons';
import { useUpdateLatestReleaseDismissalMutation } from 'src/services/api/LatestReleaseApi';
import { AlertSnackbar } from 'src/legacy/components/AlertSnackbar/AlertSnackbar';
import { AlertVariant } from 'src/store/ui/types';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(2.5, 3),
      '&:hover': {
        // show dismiss card button when card is hovered
        '& .dismissBtn': {
          display: 'block',
          [theme.breakpoints.down('xs')]: {
            display: 'none',
          },
        },
      },
    },
    contentWrapper: {
      '& h3': {
        color: BlackHeadings,
        ...typography15MediumStyle,
      },
      '& li': {
        marginLeft: theme.spacing(2),
      },
      '& ul': {
        padding: 0,
      },
      '& figure': {
        margin: theme.spacing(2, 0),
        float: 'left',
        width: '-webkit-fill-available',
      },
      '& img': {
        width: '-webkit-fill-available',
        height: 'auto',
      },
      '& iframe': {
        width: '80%',
        height: 400,
        [theme.breakpoints.down('xs')]: {
          width: '-webkit-fill-available',
          height: 200,
        },
      },
      '& p, ul': {
        ...typography15RegularStyle,
        color: GraySmall,
        marginTop: theme.spacing(0.25),
        marginBottom: 0,
      },
    },
    title: {
      color: BlackHeadings,
    },
    description: {
      color: GraySmall,
      marginTop: theme.spacing(1.5),
    },
    allReleasesButton: {
      marginTop: theme.spacing(4),
    },
    readMoreButton: {
      marginTop: '2px',
      cursor: 'pointer',
      color: GraySmall,
      '&:hover': {
        color: BlackHeadings,
      },
    },
    dismissButton: {
      display: 'none',
      position: 'absolute',
      top: theme.spacing(1),
      right: theme.spacing(1),
      height: 20,
      width: 20,
    },
  }),
);

interface LatestReleaseCardProps {
  latestReleasePost: PostOrPage | null;
}
export const LatestReleaseCard: React.FC<LatestReleaseCardProps> = ({
  latestReleasePost,
}) => {
  const classes = useStyles();
  const [updateLatestReleaseDismissal] =
    useUpdateLatestReleaseDismissalMutation();
  const [expandedContent, setExpandedContent] = React.useState(false);
  const [showCollapseButton, setShowCollapseButton] = React.useState(true);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  /**
   * This function truncate the post content to only show
   * the first paragraph of the post.
   * @returns
   */
  const getTruncatedHtmlContent = () => {
    const contentHTML = latestReleasePost?.html ?? '';
    const el = document.createElement('div');
    el.innerHTML = contentHTML;
    // post title element
    const postTitleElement = el.getElementsByTagName('h3')[0]?.outerHTML;
    // get only first and second paragraphs
    const firstPostParagraph = el.getElementsByTagName('p')[0]?.outerHTML;
    if (postTitleElement && firstPostParagraph)
      return ` ${postTitleElement}  ${firstPostParagraph}`;
    // when content does not have neither title or paragraph hide collapse button & return it as it is

    setShowCollapseButton(false);
    return contentHTML;
  };

  const postContent = React.useMemo(
    () =>
      expandedContent
        ? latestReleasePost?.html ?? ''
        : getTruncatedHtmlContent(),
    [latestReleasePost, expandedContent],
  );

  const handleUndoDismiss = () => {
    updateLatestReleaseDismissal({
      structFields: { latest_release_dismissed: '' },
    });
  };

  const successDismissSnackbar = React.useCallback(
    (snackbarKey: string) => (
      <div key={snackbarKey}>
        <AlertSnackbar
          variant={AlertVariant.SUCCESS}
          onClose={() => closeSnackbar(snackbarKey)}
          alertMessage="Card dismissed"
          onAction={handleUndoDismiss}
          actionLabel="Undo"
        />
      </div>
    ),
    [closeSnackbar],
  );

  const handleDismissLatestRelease = async () => {
    if (!latestReleasePost) return;
    const result = await updateLatestReleaseDismissal({
      structFields: {
        latest_release_dismissed: latestReleasePost.id,
      },
    });

    if ('data' in result) {
      enqueueSnackbar('Resource has been dismissed', {
        content: (snackbarKey: string) => successDismissSnackbar(snackbarKey),
      });
    }
  };

  return (
    <RegularCardBox className={classes.root} data-testid="latest-release-card">
      <IconButton
        size="small"
        edge="end"
        onClick={handleDismissLatestRelease}
        color="secondary"
        className={`${classes.dismissButton} dismissBtn`}
      >
        <CloseIcon style={{ fontSize: 10 }} />
      </IconButton>
      <div
        className={classes.contentWrapper}
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{
          __html: postContent,
        }}
      />
      {showCollapseButton && (
        <BaseTypography
          fontType="13Medium"
          className={classes.readMoreButton}
          onClick={() => setExpandedContent(!expandedContent)}
        >
          Read {expandedContent ? 'less' : 'more'}
        </BaseTypography>
      )}

      <Button
        className={classes.allReleasesButton}
        htmlId="see-all-releases"
        variant="contained"
        color="secondary"
        size="small"
        onClick={() => window.open(PORTAL_UPDATES_URL, '_blank')}
      >
        See all releases
      </Button>
    </RegularCardBox>
  );
};
