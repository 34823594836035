import React, { useContext } from 'react';
import { makeStyles, Theme, createStyles, Box, Link } from '@material-ui/core';
import { BlackHeadings, GraySmall } from 'src/theme/colors';
import BaseTypography from 'src/legacy/components/Text/BaseTypography';
import { PORTAL_URL } from 'src/constants/pathConsts';
import { CopilotBrandingIcon } from 'src/legacy/components/Icons';
import { FlagsContext } from 'src/context';
import { Body } from 'copilot-design-system';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      fontSize: 17,
      marginRight: theme.spacing(1),
      [theme.breakpoints.up('sm')]: {
        zIndex: 1, // wihtout this text cannot be hoverable
        cursor: 'pointer',
      },
    },
    logoBox: {
      padding: theme.spacing(1.4, 2),
      zIndex: 1,
      position: 'relative',
      borderRadius: '4px',
      marginTop: theme.spacing(2.5),
      color: GraySmall,
      '& svg': {
        color: GraySmall,
      },
      '&:hover *': {
        color: BlackHeadings,
      },
    },
    badgeLink: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      color: GraySmall,
      '&.MuiLink-underlineHover': {
        '&:hover': {
          textDecoration: 'none',
        },
      },
    },
  }),
);
export const AuthScreenPortalBadge = () => {
  const classes = useStyles();
  const { EnablePaymentIntentsRedesign } = useContext(FlagsContext);

  if (EnablePaymentIntentsRedesign) {
    return (
      <div className="mt-5 pt-3 pr-4">
        <AuthScreenPortalBadgeV2 />
      </div>
    );
  }

  return (
    <Box className={classes.logoBox}>
      <Link
        href={`${PORTAL_URL}/?utm_medium=powered_by&utm_source=${encodeURIComponent(
          window.location.href,
        )}`}
        target="_blank"
        className={classes.badgeLink}
      >
        <BaseTypography className={classes.root}>Powered by</BaseTypography>
        <CopilotBrandingIcon style={{ height: 'unset', width: 'unset' }} />
      </Link>
    </Box>
  );
};

export const AuthScreenPortalBadgeV2 = () => {
  return (
    <Link
      href={`${PORTAL_URL}/?utm_medium=powered_by&utm_source=${encodeURIComponent(
        window.location.href,
      )}`}
      target="_blank"
    >
      <div className="flex flex-row items-center">
        <Body className="text-[12px] text-gray-small">Powered by</Body>
        <span className="ml-2">
          <CopilotBrandingIcon
            style={{ height: '12px', width: '55px', color: '#6B6F76' }}
          />
        </span>
      </div>
    </Link>
  );
};
