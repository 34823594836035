import IFlagsmith from 'flagsmith';
import { Flags } from 'flagsmith-nodejs';
import React from 'react';

// sort flags alphabetically to match flag-smith order
export const DefaultFlagsContext = {
  // These two are permanent flags, we use these to rollout flags
  // to our dogfood and launchpad segments.
  EnableAlphaApps: false,
  EnableBetaApps: false,

  EnableAppsApiMutations: false,
  EnableAutomations: false,
  EnableAutomationsM3: false,
  EnableAutomationsM5: false,
  EnableAutomationsM5_ActionRecipient: false,
  EnableBillingImprovements: false,
  EnableBusinessAddress: false,
  EnableInvoiceDetailsPage: false,
  EnableInvoicePaidWebhook: false,
  EnableInvoiceReceipts: false,
  EnableMobileFriendlyBillingForms: false,
  EnableOneOffContract: false,
  EnablePlatformPricing: false,
  EnableSubscriptionPrices: false,
  EnableRemoveAdvancedSettings: false,
  EnableSubscriptionRedesign: false,
  GoogleLoginForClients: false,
  GoogleLoginForInternalUser: false,
  OnboardingDisabledReason: '',
  EnableNonUSDSubscriptions: false,
  EnableCompaniesCrm: false,
  EnableNewIUHomeWelcomeBlurb: false,
  EnablePaymentIntents: false,
  EnablePaymentIntentsRedesign: false,
  EnableCompanyDetailsMarketplaceApps: false,
  EnableFormOtherOption: false,
  EnableAddProductPriceInBillingFlow: false,
  EnableFilePreview: false,
  EnableMFAEnforcement: false,
  EnableMassFileShare: false,
  EnableGlobalEmbedQueryParams: false,
  EnableNewFormQuestions: false,
  EnableMassMessageShare: false,
  EnableAutomationsInvoicePaidV2: false,
  EnableWhatsNewModalView: false,
};

export type FlagContextType = typeof DefaultFlagsContext;

export const getServerFlagsContextValue = (flags: Flags) => ({
  EnableAutomations: flags.isFeatureEnabled('automations'),
  EnableAutomationsM5: flags.isFeatureEnabled('automations_m5'),
  EnableAutomationsM5_ActionRecipient: flags.isFeatureEnabled(
    'automations_m5_action_recipient',
  ),
  EnableBusinessAddress: flags.isFeatureEnabled('business_address'),
  EnableInvoiceDetailsPage: flags.isFeatureEnabled('invoice_details_page'),
  EnableInvoicePaidWebhook: flags.isFeatureEnabled('invoice_paid_webhook'),
  EnableInvoiceReceipts: flags.isFeatureEnabled('enable_invoice_receipts'),
  GoogleLoginForInternalUser: flags.isFeatureEnabled(
    'google_login_for_internal_user',
  ),
  GoogleLoginForClients: flags.isFeatureEnabled('google_login_for_clients'),
  EnableAlphaApps: flags.isFeatureEnabled('enable_alpha_apps'),
  EnableAutomationsM3: flags.isFeatureEnabled('automations_m3'),
  EnableBetaApps: flags.isFeatureEnabled('enable_beta_apps'),
  EnableBillingImprovements: flags.isFeatureEnabled('billing_improvement_m1'),
  EnableSubscriptionPrices: flags.isFeatureEnabled('subscription_prices'),
  EnableOneOffContract: flags.isFeatureEnabled('one-off-contract'),
  OnboardingDisabledReason: flags.getFeatureValue('onboarding_disabled'),
  EnableAppsApiMutations: flags.isFeatureEnabled('enable_apps_api_mutations'),
  EnableMobileFriendlyBillingForms: flags.isFeatureEnabled(
    'mobile_friendly_billing_forms',
  ),

  EnableNonUSDSubscriptions: flags.isFeatureEnabled('non_usd_subscriptions'),
  EnablePlatformPricing: flags.isFeatureEnabled('platform_pricing'),
  EnableRemoveAdvancedSettings: flags.isFeatureEnabled(
    'remove_advanced_settings',
  ),
  EnableSubscriptionRedesign: flags.isFeatureEnabled('subscription_redesign'),
  EnableNewIUHomeSubscriptionAnalytics: flags.isFeatureEnabled(
    'enable_new_iu_home_subscription_analytics',
  ),
  EnableCompaniesCrm: flags.isFeatureEnabled('enable_companies_crm'),
  EnablePaymentIntents: flags.isFeatureEnabled('payment_intents'),
  EnablePaymentIntentsRedesign: flags.isFeatureEnabled(
    'payment_intents_redesign',
  ),
  EnableCompanyDetailsMarketplaceApps: flags.isFeatureEnabled(
    'enable_company_details_marketplace_apps',
  ),
  EnableFormOtherOption: flags.isFeatureEnabled('bash_form_other_option'),
  EnableAddProductPriceInBillingFlow: flags.isFeatureEnabled(
    'add_product_price_in_billing_flow',
  ),
  EnableFilePreview: flags.isFeatureEnabled('enable_file_preview'),
  EnableMFAEnforcement: flags.isFeatureEnabled('enable_mfa_enforcement'),
  EnableMassFileShare: flags.isFeatureEnabled('mass_file_share'),
  EnableGlobalEmbedQueryParams: flags.isFeatureEnabled(
    'enable_global_embed_query_params',
  ),
  EnableNewFormQuestions: flags.isFeatureEnabled('new_form_questions'),
  EnableMassMessageShare: flags.isFeatureEnabled('mass_message_share'),
  EnableAutomationsInvoicePaidV2: flags.isFeatureEnabled(
    'automations_invoice_paid_v2',
  ),
  EnableWhatsNewModalView: flags.isFeatureEnabled('whats_new_modal_view'),
});

export const getClientFlagsContextValue = (flags: typeof IFlagsmith) => ({
  EnableAutomations: flags.hasFeature('automations'),
  EnableAutomationsM5: flags.hasFeature('automations_m5'),
  EnableAutomationsM5_ActionRecipient: flags.hasFeature(
    'automations_m5_action_recipient',
  ),
  EnableBusinessAddress: flags.hasFeature('business_address'),
  EnableInvoiceDetailsPage: flags.hasFeature('invoice_details_page'),
  EnableInvoicePaidWebhook: flags.hasFeature('invoice_paid_webhook'),
  EnableInvoiceReceipts: flags.hasFeature('enable_invoice_receipts'),
  GoogleLoginForInternalUser: flags.hasFeature(
    'google_login_for_internal_user',
  ),
  GoogleLoginForClients: flags.hasFeature('google_login_for_clients'),
  EnableAlphaApps: flags.hasFeature('enable_alpha_apps'),
  EnableAutomationsM3: flags.hasFeature('automations_m3'),
  EnableBetaApps: flags.hasFeature('enable_beta_apps'),
  EnableBillingImprovements: flags.hasFeature('billing_improvement_m1'),
  EnableSubscriptionPrices: flags.hasFeature('subscription_prices'),
  EnableOneOffContract: flags.hasFeature('one-off-contract'),
  OnboardingDisabledReason: flags.getValue('onboarding_disabled'),
  EnableAppsApiMutations: flags.hasFeature('enable_apps_api_mutations'),
  EnableNewIUHomeWelcomeBlurb: flags.hasFeature(
    'enable_new_iu_home_welcome_blurb',
  ),
  EnableMobileFriendlyBillingForms: flags.hasFeature(
    'mobile_friendly_billing_forms',
  ),

  EnableNonUSDSubscriptions: flags.hasFeature('non_usd_subscriptions'),
  EnablePlatformPricing: flags.hasFeature('platform_pricing'),
  EnableRemoveAdvancedSettings: flags.hasFeature('remove_advanced_settings'),
  EnableSubscriptionRedesign: flags.hasFeature('subscription_redesign'),
  EnableNewIUHomeSubscriptionAnalytics: flags.hasFeature(
    'enable_new_iu_home_subscription_analytics',
  ),
  EnableCompaniesCrm: flags.hasFeature('enable_companies_crm'),
  EnablePaymentIntents: flags.hasFeature('payment_intents'),
  EnablePaymentIntentsRedesign: flags.hasFeature('payment_intents_redesign'),
  EnableCompanyDetailsMarketplaceApps: flags.hasFeature(
    'enable_company_details_marketplace_apps',
  ),
  EnableFormOtherOption: flags.hasFeature('bash_form_other_option'),
  EnableAddProductPriceInBillingFlow: flags.hasFeature(
    'add_product_price_in_billing_flow',
  ),
  EnableFilePreview: flags.hasFeature('enable_file_preview'),

  EnableMFAEnforcement: flags.hasFeature('enable_mfa_enforcement'),
  EnableMassFileShare: flags.hasFeature('mass_file_share'),
  EnableGlobalEmbedQueryParams: flags.hasFeature(
    'enable_global_embed_query_params',
  ),
  EnableNewFormQuestions: flags.hasFeature('new_form_questions'),
  EnableMassMessageShare: flags.hasFeature('mass_message_share'),
  EnableAutomationsInvoicePaidV2: flags.hasFeature(
    'automations_invoice_paid_v2',
  ),
  EnableWhatsNewModalView: flags.hasFeature('whats_new_modal_view'),
});

export const FlagsContext = React.createContext(DefaultFlagsContext);
